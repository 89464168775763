import React, { useEffect, useState } from 'react'
import { addFinanceCategory, addSupportCategory, allFinanceCategory, allRoles, allSideMenus, allSupportCategory, createRole, deleteSupportCategory, editFinanceCategory, editSupportCategory, getBusiness, getByBusinessData, getProfile, getSideBar } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const FinanceCategory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const business = useSelector(getBusiness)
    const [categoryName, setcategoryName] = useState("");
    const [editData, seteditData] = useState(null);
    const [listOfCategory, setlistOfCategory] = useState([]);
    const profile = useSelector(getProfile)
    const [load, setload] = useState(false);




    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getAllCategory = async () => {
        try {
            setload(true);
            const response = await dispatch(allFinanceCategory(business ? business?._id : profile?.businessId)).unwrap();
            setlistOfCategory(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const deleteCategory = async (data) => {
        try {

            const response = await dispatch(deleteSupportCategory(data._id)).unwrap();
            setlistOfCategory(response?.data)
            getAllCategory()

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const openToggleForEdit = async (data) => {
        try {
            seteditData(data)
            setcategoryName(data?.name)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const editCategory = async () => {
        try {
            let payload = {
                id: editData._id,
                name: categoryName
            }
            const response = await dispatch(editFinanceCategory(payload)).unwrap();
            setlistOfCategory(response?.data)
            getAllCategory()
            setcategoryName("")
            seteditData(null)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness();
        }
        return () => {
            mount = false;
        };
    }, [])


    useEffect(() => {
        let mount = true;
        if (mount) {
            getAllCategory();

        }
        return () => {
            mount = false;
        };
    }, [business, profile])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            let payload = {
                businessId: profile?.businessId ? profile?.businessId : business._id,
                name: categoryName
            }

            await dispatch(addFinanceCategory(payload)).unwrap();
            setcategoryName("")
            getAllCategory()

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    return (
        <>
            <div className="sp-bookings-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('Finance')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('Finance Category')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                    <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                        <img height="32" src="./assets/images/it-icon-sp.svg" className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('Finance Category')}</h2>
                    </div>
                    <div className="col-md-6 order-md-2 order-3">

                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                        <button class="btn sp-button sp-blue-button" data-bs-toggle="modal" data-bs-target="#add-technical-category">{t('add')} {t('category')}</button>
                    </div>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg">
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">{t('name')}</th>
                                    <th scope="col">{t('createdOn')}</th>
                                    <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={9}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : listOfCategory?.map((data, i) => (
                                    <tr>
                                        <td>{data?.name}</td>
                                        <td>{moment(data?.createdAt).format("MMMM Do YYYY")}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div data-bs-toggle="modal" data-bs-target="#edit-technical-category" onClick={() => openToggleForEdit(data)} className={`sp-action-icons`}><Pencil width="18" /></div>
                                                <div onClick={() => deleteCategory(data)} className={`sp-action-icons`}><X width="18" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="add-technical-category" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" style={{ "--bs-modal-width": "525px" }}>
                    <div class="modal-content sp-modal-content">
                        <div class="modal-header sp-shadow">
                            <h1 class="font-size-26 titilium-font blue-color fw-bold mb-0" id="staticBackdropLabel">{t('add')} {t('category')}</h1>
                            <button type="button" class="btn-close blue-color" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-floating sp-input">
                                <input type="text" class="form-control" id="cat-name" value={categoryName} onChange={(e) => setcategoryName(e.target.value)} placeholder="Category Name" />
                                <label for="cat-name">{t('name')}</label>
                            </div>
                        </div>
                        <div class="modal-footer sp-shadow">
                            <button type="button" class="btn sp-blue-button sp-button" data-bs-dismiss="modal" onClick={handleSubmit}>{t('add')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="edit-technical-category" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" style={{ "--bs-modal-width": "525px" }}>
                    <div class="modal-content sp-modal-content">
                        <div class="modal-header sp-shadow">
                            <h1 class="font-size-26 titilium-font blue-color fw-bold mb-0" id="staticBackdropLabel">{t('edit')} {t('category')}</h1>
                            <button type="button" class="btn-close blue-color" data-bs-dismiss="modal" aria-label="Close" onClick={() => setcategoryName("")}></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-floating sp-input">
                                <input type="text" class="form-control" id="cat-name-2" value={categoryName} maxLength={40} onChange={(e) => setcategoryName(e.target.value)} placeholder="Category Name" />
                                <label for="cat-name-2">{t('name')}</label>
                            </div>
                        </div>
                        <div class="modal-footer sp-shadow">
                            <button type="button" class="btn sp-blue-btn sp-btn py-2" data-bs-dismiss="modal" onClick={editCategory}>{t('edit')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FinanceCategory