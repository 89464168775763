import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpacesForDropDown, addDesk, getBusiness, getByBusinessData, getPlans, getProfile, getSpacesForDropDownPrivate, addSupportCategory, getAllTechnicalCategory, addTicket, getEmployeeDependsOnSpace } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";




const AddTicket = () => {

    const { t } = useTranslation();

    const [image, setimage] = useState("");
    const [name, setname] = useState("");
    const [description, setdescription] = useState("");
    const [category, setcategory] = useState("");
    const [priority, setpriority] = useState("");
    const [getAllSpaces, setgetAllSpaces] = useState([]);
    const [listOfCategory, setlistOfCategory] = useState([]);
    const [spaceId, setspaceId] = useState("");
    const [assignedUser, setassignedUser] = useState("");
    const [allUsers, setallUsers] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const [load, setload] = useState(false);


    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const handleChangeForLocation = (event) => {
        setspaceId(event.target.value);

    };


    const getTechnicalCategory = async () => {
        try {
            let payload = {
                businessId: profile?.businessId ? profile?.businessId : business._id,

            }

            const response = await dispatch(getAllTechnicalCategory(payload)).unwrap();
            setlistOfCategory(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getSpaces = async () => {
        try {
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
            setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getEmployee = async () => {
        try {
            let payload = {
                businessId: profile?.businessId ? profile?.businessId : business._id,
                spaceId: spaceId

            }

            const response = await dispatch(getEmployeeDependsOnSpace(payload)).unwrap();
            setallUsers(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness();

        }
        return () => {
            mount = false;
        };
    }, [])

    useEffect(() => {
        let mount = true;
        if (mount) {
            getEmployee()

        }
        return () => {
            mount = false;
        };
    }, [spaceId])


    useEffect(() => {
        let mount = true;
        if (mount) {

            getTechnicalCategory();
            getSpaces()
        }
        return () => {
            mount = false;
        };
    }, [business, profile])




    const handleSubmitForTicket = async (e) => {
        e.preventDefault();
        try {

            setload(true);
            if (!spaceId) {
                toast.error('Location is required');
                setload(false);
                return;
            }
            if (!category) {
                toast.error('Category is required');
                setload(false);
                return;
            }
            if (!priority) {
                toast.error('Priority is required');
                setload(false);
                return;
            }
            if (!assignedUser) {
                toast.error('IT Person is required');
                setload(false);
                return;
            }
            if (!image) {
                toast.error('Ticket Proof is required');
                setload(false);
                return;
            }
            const form = new FormData();

            form.append("ticketImage", image);
            form.append("description", description);
            form.append("priority", priority);
            form.append("category", category);
            form.append("spaceId", spaceId);
            form.append("assignedUser", assignedUser);
            form.append("businessId", profile?.businessId ? profile?.businessId : business._id);


            await dispatch(addTicket(form)).unwrap();
            setname("")
            setdescription("")
            setpriority("")
            setcategory("")
            setassignedUser("")
            setspaceId("")
            setimage("")
            setload(false);
            navigate("/support-tickets")


        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const priorityLevel = [
        {
            id: 1,
            name: "Low"
        },
        {
            id: 2,
            name: "Medium"
        },
        {
            id: 3,
            name: "High"
        },
    ]



    return (
        <>
            {load ? <Spinner /> : <></>}
            <div className="sp-add-location-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/support-tickets")}>{t('supportTickets')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('add')} {t('ticket')}</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/it-icon-sp.svg`} className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('add')} {t('ticket')}</h2>
                </div>
                <div className="row mb-4">
                    <div className="col-md-6">
                        <div class="mb-3">
                            <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('location')}</label>
                            <div className="d-flex gap-3 flex-wrap">
                                {getAllSpaces?.map((data, i) => (
                                    <>
                                        <input class="btn-check" name="location" type="radio" value={spaceId} id={data?._id} key={i} onChange={() => setspaceId(data._id)} />
                                        <label class="btn sp-check-select" for={data?._id}>{data.name}</label>
                                    </>
                                ))}
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('category')}</label>
                            <div className="d-flex gap-3 flex-wrap">
                                {listOfCategory?.map((data, i) => (
                                    <>
                                        <input class="btn-check" name="category" type="radio" id={data?._id} key={i} onChange={() => setcategory(data._id)} />
                                        <label class="btn sp-check-select" for={data?._id}>{data.name}</label>
                                    </>
                                ))}
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('priority')}</label>
                            <div className="d-flex gap-3 flex-wrap">
                                {priorityLevel?.map((data, i) => (
                                    <>
                                        <input class="btn-check" name="priority" type="radio" id={data?.name} key={i} onChange={() => setpriority(data.name)} />
                                        <label class="btn sp-check-select" for={data?.name}>{data.name}</label>
                                    </>
                                ))}
                            </div>
                        </div>


                    </div>
                    <div className="col-md-6">
                        <label class="blue-color fw-semibold">{t('ticket')} {t('detail')}</label>
                        <div className="l-grey-bg p-4 sp-radius-20">
                            <div class="dropdown sp-select-dropdown mb-3">
                                <a class="dropdown-toggle text-capitalize" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {assignedUser ? allUsers.find(data => data._id === assignedUser)?.name : <>{t('ITPerson')}</>}
                                </a>
                                <ul class="dropdown-menu">
                                    {allUsers?.map((data, i) => (
                                        <li key={i} onClick={() => setassignedUser(data?._id)}><a class="dropdown-item" >{data?.name} - {data?.roleName ? data?.roleName?.roleName : "Business Owner"}</a></li>
                                    ))}
                                </ul>
                            </div>
                            <div class="form-floating sp-textarea mb-3">
                                <textarea class="form-control" placeholder="Leave a comment here" maxLength={220} id="Description" style={{ height: '200px' }} value={description} onChange={(e) => setdescription(e.target.value)}></textarea>
                                <label for="Description">{t('description')}</label>
                            </div>
                            <div class="form-floating sp-input">
                                <input type="file" class="form-control" id="Ticket-Proof" placeholder="image" onChange={(e) => setimage(e.target.files[0])} accept=".png, .jpg, .jpeg" />
                                <label for="Ticket-Proof">{t('ticketProof')}</label>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="text-center">
                    <button class="btn sp-button sp-blue-button" onClick={handleSubmitForTicket}>{t('save')}</button>
                </div>
            </div>

        </>
    )
}


export default AddTicket