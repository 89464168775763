import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSpace, editSpace, getBusiness, getByBusinessData, getCountry, getEmployeeDependsOnSpace, getEmployeeForEditSpace, getProfile, getUserRight } from "../../store/slices/userSlice";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import { Camera } from "lucide-react";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const EditSpace = () => {
  const { t } = useTranslation();
    const dispatch = useDispatch();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const userRights = useSelector(getUserRight)
    const location = useLocation()
    const navigate = useNavigate()

    const [id, setid] = useState("");
    const [spaceDetail, setspaceDetail] = useState(location?.state);
    const [images, setImages] = useState([]);

    const [name, setname] = useState("");
    const [line1, setline1] = useState("");
    const [line2, setline2] = useState("");
    const [city, setcity] = useState("");
    const [country, setcountry] = useState("");
    const [address, setaddress] = useState("");
    const [spaceManagerName, setspaceManagerName] = useState("");
    const [spaceEmail, setspaceEmail] = useState("");
    const [spacePhone, setspacePhone] = useState("");
    const [postCode, setpostCode] = useState("");
    const [spaceMobile, setspaceMobile] = useState("");
    const [spaceStartTime, setspaceStartTime] = useState("");
    const [spaceEndTime, setspaceEndTime] = useState("");
    const [operationDays, setoperationDays] = useState([]);
    const [listOfEmployee, setlistOfEmployee] = useState([]);
    const [is24Hours, setis24Hours] = useState(0);
    const [listOfCountry, setlistOfCountry] = useState(null);
    const [prev_images, setprev_images] = useState(spaceDetail?.image);
    const [userId, setuserId] = useState('');
    const [load, setload] = useState(false);

    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    // const getEmployee = async () => {
    //     try {
    //         const response = await dispatch(getEmployeeForEditSpace(spaceDetail._id)).unwrap();
    //         setlistOfEmployee(response?.data)
    //     } catch (rejectedValueOrSerializedError) {
    //         console.log(rejectedValueOrSerializedError);
    //     }
    // };


    const getEmployee = async () => {
        try {
            let payload = {
                businessId: profile?.businessId ? profile?.businessId : business._id,
                spaceId: spaceDetail._id
            }
            const response = await dispatch(getEmployeeDependsOnSpace(payload)).unwrap();
            setlistOfEmployee(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    useEffect(() => {
        setoperationDays(spaceDetail?.days)
        setuserId(spaceDetail?.spaceSupport)
    }, [spaceDetail])

    const allCountry = async () => {
        try {

            const response = await dispatch(getCountry()).unwrap();
            setlistOfCountry(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness();
            allCountry()
            getEmployee()
        }
        return () => {
            mount = false;
        };
    }, [])
 
 

    const handleCheckboxChangeForLocation = (event) => {
        const { value, checked } = event.target;
        setoperationDays((prevInterestedServices) => {
            if (checked) {

                return [...prevInterestedServices, value];
            } else {

                return prevInterestedServices.filter((item) => item !== value);
            }
        });
    };


 

    const handleImageUpload = (event) => {
        const selectedImages = event.target.files;
        const imagesArray = [];


        for (let i = 0; i < selectedImages.length; i++) {
            imagesArray.push(selectedImages[i]);
        }

        setImages(imagesArray);
    };

    const handleImageRemovePrev = (e, index) => {
        e.preventDefault();
        const updatedImages = [...prev_images];
        updatedImages.splice(index, 1);
        setprev_images(updatedImages);
    };

    const handleImageRemove = (e, index) => {
        e.preventDefault();
        const updatedImages = [...images];
        updatedImages.splice(index, 1);

        setImages(updatedImages);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          setload(true);
          const imagesArray = []
            const existingImagesAsFiles = prev_images?.map((image) => (
                typeof image === "string" ? new File([], image) : image
            ));
            for (let i = 0; i < existingImagesAsFiles.length; i++) {
                imagesArray.push(existingImagesAsFiles[i]);
            }


            const newArray = [...imagesArray, ...images];

          if (spaceEmail) {
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(spaceEmail);
            if (!isValidEmail) {
              toast.error('Please enter a valid email address!');
            }
            setload(false);
            return;
          }
          if (!name) {
            toast.error('Space Name is required');
            setload(false);
            return;
          }
          if (!userId) {
            toast.error('Support User is required');
            setload(false);
            return;
          }
          if (!spaceStartTime) {
            toast.error('Space Start Time is required');
            setload(false);
            return;
          }
          if (!spaceEndTime) {
            toast.error('Space End Time is required');
            setload(false);
            return;
          }
          if (!operationDays.length) {
            toast.error('Operation Days is required');
            setload(false);
            return;
          }
          if (!newArray.length == 1) {
            toast.error('Images are required');
            setload(false);
            return;
          }
          if (!address) {
            toast.error('Address is required');
            setload(false);
            return;
          }
          if (!city) {
            toast.error('City is required');
            setload(false);
            return;
          }
          if (!country) {
            toast.error('Country is required');
            setload(false);
            return;
          }

            const form = new FormData();
            form.append("spaceId", spaceDetail._id);
            form.append("name", name ? name : spaceDetail?.name);
            form.append("spaceSupport", userId ? userId : spaceDetail?.spaceSupport);
            form.append("spaceStartTime", spaceStartTime ? spaceStartTime : spaceDetail?.spaceStartTime);
            form.append("spaceEndTime", spaceEndTime ? spaceEndTime : spaceDetail?.spaceEndTime);
            form.append("days", operationDays ? JSON.stringify(operationDays) : JSON.stringify(spaceDetail?.days));
            form.append("spaceManagerName", spaceManagerName ? spaceManagerName : spaceDetail?.spaceManagerName);
            form.append("spaceEmail", spaceEmail ? spaceEmail : spaceDetail?.spaceEmail);
            form.append("spacePhone", spacePhone ? spacePhone : spaceDetail?.spacePhone);
            form.append("spaceMobile", spaceMobile ? spaceMobile : spaceDetail?.spaceMobile);
            newArray?.forEach((item) => {
                form.append("spaceImage", item);
            });
            form.append("lines1", line1 ? line1 : spaceDetail?.location?.lines1);
            form.append("lines2", line2 ? line2 : spaceDetail?.location?.lines2);
            form.append("city", city ? city : spaceDetail?.location?.city);
            form.append("country", country ? country : spaceDetail?.location?.country);
            form.append("address", address ? address : spaceDetail?.location?.address);
            form.append("postCode", postCode ? postCode : spaceDetail?.location?.postCode);


            // let payload = {
            //     spaceId: spaceDetail._id,
            //     name: name ? name : spaceDetail?.name,
            //     spaceStartTime: spaceStartTime ? spaceStartTime : spaceDetail?.spaceStartTime,
            //     spaceEndTime: spaceEndTime ? spaceEndTime : spaceDetail?.spaceEndTime,
            //     days: operationDays ? operationDays : spaceDetail?.days,
            //     spaceManagerName: spaceManagerName ? spaceManagerName : spaceDetail?.spaceManagerName,
            //     spaceEmail: spaceEmail ? spaceEmail : spaceDetail?.spaceEmail,
            //     spacePhone: spacePhone ? spacePhone : spaceDetail?.spacePhone,
            //     spaceMobile: spaceMobile ? spaceMobile : spaceDetail?.spaceMobile,
            //     lines1: line1 ? line1 : spaceDetail?.location?.lines1,
            //     lines2: line2 ? line2 : spaceDetail?.location?.lines2,
            //     city: city ? city : spaceDetail?.location?.city,
            //     country: country?.country ? country?.country : spaceDetail?.location?.country,
            //     address: address ? address : spaceDetail?.location?.address,
            //     postCode: postCode ? postCode : spaceDetail?.location?.postCode,
            // }



            await dispatch(editSpace(form)).unwrap();
            setoperationDays([])
            setImages([])
            setspaceEndTime("")
            setspaceStartTime("")
            setspaceMobile("")
            setpostCode("")
            setspacePhone("")
            setspaceEmail("")
            setspaceManagerName("")
            setaddress("")
            setcountry(null)
            setcity(null)
            setline2("")
            setline1("")
            setname("")

            navigate("/locations")
            setload(false);
        } catch (rejectedValueOrSerializedError) {
          setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    console.log(spaceDetail,"hjhjjkjkjk")


    const OperationDays = [
      {
        id: 1,
        text: <>{t('mon')}</>,
        value: 'Mon',
      },
      {
        id: 2,
        text: <>{t('tue')}</>,
        value: 'Tue',
      },
      {
        id: 3,
        text: <>{t('wed')}</>,
        value: 'Wed',
      },
      {
        id: 4,
        text: <>{t('thu')}</>,
        value: 'Thu',
      },
      {
        id: 5,
        text: <>{t('fri')}</>,
        value: 'Fri',
      },
      {
        id: 6,
        text: <>{t('sat')}</>,
        value: 'Sat',
      },
      {
        id: 7,
        text: <>{t('sun')}</>,
        value: 'Sun',
      },
    ];

    useEffect(() => {
        if (spaceDetail) {
            setcountry(spaceDetail?.location?.country);
            setSearchValue(spaceDetail?.location?.country);
            setname(spaceDetail?.name);
            setspaceStartTime(spaceDetail?.spaceStartTime);
            setspaceEndTime(spaceDetail?.spaceEndTime);
            setspaceMobile(spaceDetail?.spaceMobile);
            setspacePhone(spaceDetail?.spacePhone);
            setaddress(spaceDetail?.location?.address);
            setcity(spaceDetail?.location?.city);

        }
    }, [spaceDetail]);


    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    
    const handleInputChange = (e) => {
      const value = e.target.value;
      setSearchValue(value);
      if (value.length > 0) {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    };
    const handleOptionClick = (data) => {
      setSearchValue(data?.name); 
      setcountry(data?.name); 
      setShowDropdown(false); 
    };

    const filteredCountries = listOfCountry?.filter((data) =>
    data.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
          // Move to the next item in the list
          setHighlightedIndex((prevIndex) =>
            prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
          );
        } else if (e.key === 'ArrowUp') {
          // Move to the previous item in the list
          setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (e.key === 'Enter') {
          // Select the highlighted item when Enter key is pressed
          if (highlightedIndex !== -1) {
            handleOptionClick(filteredCountries[highlightedIndex]);
          }
        }
      };


    console.log(country, "country");

    const handlePhoneChange = (e) => {
      let input = e.target.value;
  
      if (!input) {
        setspacePhone('');
        return;
      }
      input = input.replace(/[^0-9]/g, '');
      const formattedPhone = input.length > 0 ? `+${input}` : '+';
      setspacePhone(formattedPhone);
    };
    const handleMobileChange = (e) => {
      let input = e.target.value;
  
      if (!input) {
        setspaceMobile('');
        return;
      }
      input = input.replace(/[^0-9]/g, '');
      const formattedPhone = input.length > 0 ? `+${input}` : '+';
      setspaceMobile(formattedPhone);
    };

    return (
        <>
        {load ? <Spinner /> : <></>}
            <div className="sp-add-location-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb sp-breadcrumb">
                  <li class="breadcrumb-item"><a>Menu</a></li>
                  <li class="breadcrumb-item"><a>Spaces</a></li>
                  <li class="breadcrumb-item"><a onClick={() => navigate("/locations")}>Locations</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Edit Location</li>
                </ol>
            </nav>
            <div className="d-flex align-items-center gap-2 mb-4">
            <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/spaces-icon-sp.svg`} className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">Edit Location</h2>
          </div>
          <div className="col-md-6 mb-3">
            <div class="form-floating sp-input">
              <input type="text" class="form-control" id="space-name" onChange={(e) => setname(e.target.value)} defaultValue={name} placeholder="Space Name"/>
              <label for="space-name">Space Name</label>
            </div>
          </div>
          <div className="mb-4">
          
          <div class="mb-3">
            
            <div class="row">
            <div class="col-md-6">
            <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">Operation Days</label>
            <div class="d-flex flex-wrap gap-3 align-self-center">
              {OperationDays.map((data, i) => (
                <>
                <input name="operating-days" type="checkbox" class="btn-check" onChange={handleCheckboxChangeForLocation} value={data.value} checked={operationDays?.includes(data.value)} id={data.value} autocomplete="off"/>
                <label className="btn sp-check-select" for={data.value}>{data.text}</label>
                </>
              ))}
            </div>
          </div>
              <div className="col-md-6 d-flex flex-wrap">
              <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold w-100">Operation Hours</label>
              <div className="d-flex gap-4 w-75 flex-shrink-1">
              <div class="form-floating sp-input w-100">
              <input type="time" class="form-control"id="spaceStartTime" defaultValue={spaceStartTime} onChange={() => setspaceStartTime()} disabled={is24Hours == 1 ? true : false} placeholder="Space Name"/>
              <label for="spaceStartTime">Space Start Time</label>
            </div>
            <div class="form-floating sp-input w-100">
              <input type="time" class="form-control" id="spaceStartTime" disabled={is24Hours == 1 ? true : false} onChange={() => setspaceEndTime()} defaultValue={spaceEndTime} placeholder="Space Name"/>
              <label for="spaceStartTime">Space Start Time</label>
            </div>
            </div>
            {/* <div className="d-flex align-self-center">
              <input type="checkbox" class="btn-check" onChange={check24Hours} id="24-hrs"/>
              <label className="btn sp-check-select" for="24-hrs">24 HRS</label>
            </div> */}
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">{t('supportuser')}</label>
            <div class=" d-flex flex-wrap gap-3 align-self-center">
              {listOfEmployee.map((data, i) => (
                <>
                  <input name="support-user" type="radio" class="btn-check" defaultValue={userId} checked={userId?.includes(data?._id)} onChange={() => setuserId(data?._id)} id={data._id}  />
                  <label className="btn sp-check-select" for={data._id}>{data?.name} - {data?.roleName ? data?.roleName?.roleName : "Business Owner"}</label>
                </>
              ))}
            </div>
          </div>
          
          <div class="mb-3">
            <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">Address</label>
            <div class="row gy-3">
              <div className="col-md-12">
              <div class="form-floating sp-input">
              <input type="text" class="form-control" id="autocomplete" maxLength={220} defaultValue={address} onChange={(e) => setaddress(e.target.value)} placeholder="Address"/>
              <label for="autocomplete">Address</label>
            </div>
              </div>
              <div className="col-md-5">
              <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="country" maxLength={120} placeholder="country" onKeyDown={handleKeyDown} onChange={(e) => handleInputChange(e)} value={searchValue} />
                  <label for="country">{t('country')}</label>
                </div>
                {showDropdown && (
                    <div className="dropdown sp-select-dropdown">
                    <ul className="dropdown-menu show" style={{ width: '100%' }}>
                        {listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                            <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                        ))}
                        {listOfCountry?.length > 0 && listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                        )}
                    </ul>
                    </div>
                )}
              </div>
              <div className="col-md-4">
              <div class="form-floating sp-input">
              <input type="text" class="form-control" id="City" maxLength={20} defaultValue={city} onChange={(e) => setcity(e.target.value)} placeholder="City"/>
              <label for="City">City</label>
            </div>
              </div>
              <div className="col-md-3">
              <div class="form-floating sp-input">
              <input type="text" class="form-control" id="Postcode" maxLength={9} defaultValue={postCode} onChange={(e) => setpostCode(e.target.value)} placeholder="Postcode"/>
              <label for="Postcode">Postcode</label>
            </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">Space Details</label>
            <div class="row gy-3">
              <div className="col-md-6">
              <div class="form-floating sp-input">
              <input type="text" class="form-control" id="manager" maxLength={40} defaultValue={spaceDetail?.spaceManagerName} onChange={(e) => setspaceManagerName(e.target.value)} placeholder="Space Manager"/>
              <label for="manager">Space Name</label>
            </div>
              </div>
              <div className="col-md-6">
              <div class="form-floating sp-input">
              <input type="email" class="form-control" maxLength={320} id="email" defaultValue={spaceDetail?.spaceEmail} onChange={(e) => setspaceEmail(e.target.value)} placeholder="Email"/>
              <label for="email">Email</label>
            </div>
              </div>
              <div className="col-md-6">
              <div class="form-floating sp-input">
              <input type="tel" class="form-control" id="phone" maxLength={13} value={spacePhone} onChange={handlePhoneChange} placeholder="Phone"/>
              <label for="phone">Phone</label>
            </div>
              </div>
              <div className="col-md-6">
              <div class="form-floating sp-input">
              <input type="text" class="form-control" id="mobile" maxLength={13} value={spaceMobile} onChange={handleMobileChange} placeholder="Mobile"/>
              <label for="mobile">Mobile</label>
            </div>
              </div>
            
            </div>
          </div>
          <div class="">
            <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">Upload Image</label>
            <div class="col-sm-11">
            <div className="upload__img-wrap">
            {prev_images?.map((image, index) => (
                <div className="upload__img-box" key={index}>
                    <div className="img-bg">
                        <img src={`${process.env.REACT_APP_IMGURL}/${image}`} alt={`Store Image`} className="img-bg-size" />
                        <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                    </div>
                </div>
            ))}

            {images?.map((image, index) => (

                <div className="upload__img-box" key={index}>
                    <div className="img-bg">
                        {
                            typeof (image) == "string" ? <>
                                <img src={` ${process.env.REACT_APP_IMGURL}/${image}`} alt={`Store Image`} className="img-bg-size" />
                            </> : <>
                                <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                            </>
                        }
                        <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                    </div>
                </div>
            ))}
              <input type="file" class="btn-check" id="btn-check" multiple data-max_length="10" onChange={handleImageUpload} accept=".png, .jpg, .jpeg" />
              <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> Add Photos</label>
            </div>
            
            </div>
          </div>
          </div>
          <div className="text-center">
            <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>Save</button>
          </div>
        </div>
        </>
    )
}

export default EditSpace