import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { changePassword, setupPassword } from "../../store/slices/userSlice";

const SetUpPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.pathname)

    const parts = location.pathname.split('/');

    // The user ID is the second part after the first '/'
    const userId = parts[2];

    console.log(userId);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSecureEntry, setisSecureEntry] = useState(true)
    const [isSecureEntry1, setisSecureEntry1] = useState(true)


    const handleClick = async (e) => {
        e.preventDefault();
        try {
            let payload = {
                id: userId,
                password: password,
                confirmPassword: confirmPassword,
            }

            console.log(payload)

            await dispatch(setupPassword(payload)).unwrap();
            navigate("/")
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    return (
        <>
            <section class="authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-2-strong border-0 rounded">
                                <div class="card-body p-5 text-center">

                                <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}/assets/images/logo-new.svg`} />
                                    <h3 className="font-size-24 titilium-font fw-bold light-blue-color mb-1">Setup New Password</h3>
                                    <p className="color-black font-size-14 mb-3">Enter New Password</p>

                                    {/* <div class="form-floating sp-input mb-3">
                                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                                            onChange={(e) => setEmail(e.target.value)} autocomplete="email" required />
                                        <label for="floatingInput">Your Email Address</label>
                                    </div> */}
                                    <div class="form-floating sp-input mb-3">
                                        <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                                            type={isSecureEntry ? "password" : "text"} autocomplete="password" required />
                                        <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                                        <label for="floatingPassword">Password</label>
                                    </div>
                                    <div class="form-floating sp-input mb-5">
                                        <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setConfirmPassword(e.target.value)}
                                            type={isSecureEntry1 ? "password" : "text"} autocomplete="password" required />
                                        <i onClick={() => { setisSecureEntry1((prev) => !prev) }} className={`fa-solid ${isSecureEntry1 ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                                        <label for="floatingPassword">Confirm Password</label>
                                    </div>
                                    <div class="row justify-content-between mt-4">

                                        <div className="col d-grid">
                                            <button class="btn sp-blue-btn sp-btn btn-lg" onClick={handleClick} >Save</button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
};

export default SetUpPassword;
