import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByBusinessData, getSpacesForDropDown, getBusiness, addLead, getCountry, getProfile, userDetailData, editLead } from "../../store/slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Camera } from "lucide-react";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";

const EditLead = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const business = useSelector(getBusiness)
  const location = useLocation()
  const navigate = useNavigate()
  const profile = useSelector(getProfile)

  const [age, setAge] = useState('');
  const [getAllSpaces, setgetAllSpaces] = useState([]);

  const [name, setname] = useState('');
  const [address, setaddress] = useState('');
  const [email, setemail] = useState('');
  const [city, setcity] = useState('');
  const [phone, setphone] = useState('');
  const [country, setcountry] = useState(null);
  const [userDetail, setuserDetail] = useState();
  const [linkedIn, setlinkedIn] = useState('');
  const [postCode, setpostCode] = useState('');
  const [isCompany, setisCompany] = useState(false);
  const [companyName, setcompanyName] = useState('');
  const [companyAddress, setcompanyAddress] = useState('');
  const [companyEmail, setcompanyEmail] = useState('');
  const [companyCity, setcompanyCity] = useState("");
  const [companyPhone, setcompanyPhone] = useState('');
  const [companyCountry, setcompanyCountry] = useState(null);
  const [image, setImage] = useState(null);
  const [companyWebsite, setcompanyWebsite] = useState('');
  const [companyLinkedIn, setcompanyLinkedIn] = useState('');
  const [companyPostCode, setcompanyPostCode] = useState('');
  const [interestedServices, setinterestedServices] = useState([]);
  const [interestedSpaces, setinterestedSpaces] = useState([]);
  const [listOfCountry, setlistOfCountry] = useState(null);
  const [abc, setabc] = useState(false)

  const [isPackagesAndMail, setisPackagesAndMail] = useState(0);

  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);
  const [isPostCodeValid, setIsPostCodeValid] = useState(true);
  const [isPostCodeValid1, setIsPostCodeValid1] = useState(true);

  const Services = [
    {
      id: 1,
      text: 'Virtual Office',
    },
    {
      id: 2,
      text: 'Desks Space',
    },
    {
      id: 3,
      text: 'Private Room',
    },
    {
      id: 4,
      text: 'Meeting Room',
    },
    {
      id: 5,
      text: 'Event',
    },
  ];

  const getUserDetail = async () => {
    try {
      const response = await dispatch(userDetailData(location?.state)).unwrap();
      setuserDetail(response?.data[0])
      console.log(response?.data[0])

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
 


  const getMyBusiness = async () => {
    try {
      await dispatch(getByBusinessData()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getSpaces = async () => {
    try {
      const response = await dispatch(getSpacesForDropDown(profile?.businessId ? profile?.businessId : business._id)).unwrap();
      setgetAllSpaces(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const allCountry = async () => {
    try {

      const response = await dispatch(getCountry()).unwrap();
      setlistOfCountry(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  useEffect(() => {
    let mount = true;
    if (mount) {
      // getSpaces();
      getMyBusiness()
      allCountry()
      getUserDetail()
    }
    return () => {
      mount = false;
    };
  }, [])

  useEffect(() => {
    let mount = true;
    if (mount) {
      getSpaces();
    }
    return () => {
      mount = false;
    };
  }, [business])


  useEffect(() => {
    if (userDetail) {
      setinterestedServices(userDetail?.interestedServices || []);
      setinterestedSpaces(userDetail?.interestedSpaces || []);
      setisPackagesAndMail(userDetail?.isPackagesAndMail || 0);
      setSearchValue(userDetail?.location?.country);
      setcountry(userDetail?.location?.country);
      setphone(userDetail?.phone);
      setname(userDetail?.name);
      if (userDetail?.companyData) {
        setisCompany(true)

        setSearchValue1(userDetail?.companyData?.country);
        setcompanyCountry(userDetail?.companyData?.country);


        setcompanyName(userDetail?.companyData?.name);
        setcompanyEmail(userDetail?.companyData?.email);
        setcompanyPhone(userDetail?.companyData?.phone);
        setcompanyLinkedIn(userDetail?.companyData?.linkedIn);

        setcompanyAddress(userDetail?.companyData?.address);
        setcompanyCity(userDetail?.companyData?.city);
        setcompanyPostCode(userDetail?.companyData?.postCode);


      }
    }
  }, [userDetail]);

  console.log('userDetail', userDetail)

  const handleCheck = (event) => {
    const { id, checked } = event.target;

    setinterestedServices((prevInterestedServices) => {
      if (checked) {
        // Add the selected service to the array
        return [...prevInterestedServices, id];
      } else {
        // Remove the unselected service from the array
        return prevInterestedServices?.filter((item) => item !== id);
      }
    });
  };


  const handleCheckboxChangeForLocation = (locationId) => {
    setinterestedSpaces((prevInterestedSpaces) => {
      if (prevInterestedSpaces.includes(locationId)) {
        // Remove the unselected location from the array
        return prevInterestedSpaces.filter((item) => item !== locationId);
      } else {
        // Add the selected location to the array
        return [...prevInterestedSpaces, locationId];
      }
    });
  };


  const handleRadioChange = () => {
    setisPackagesAndMail(prevValue => (prevValue === 1 ? 0 : 1));
  };

  console.log("companyPostcode", companyPostCode)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {


      setload(true);
      if (email) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (!isValidEmail) {
          toast.error('Please enter a valid email address!');
          setload(false);
          return;
        }
      }
      if (companyEmail) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(companyEmail);
        if (!isValidEmail) {
          toast.error('Please enter a valid email address!');
          setload(false);
          return;
        }
      }
      else if (!name) {
        toast.error('Name is required');
        setload(false);
        return;
      }
      else if (!phone) {
        toast.error('Phone is required');
        setload(false);
        return;
      }

      if (isCompany && !companyName) {
        toast.error('Company name is required');
        setload(false);
        return;
      } else if (isCompany && !companyEmail) {
        toast.error('Company email is required');
        setload(false);
        return;
      } else if (isCompany && !companyPhone) {
        toast.error('Company phone is required');
        setload(false);
        return;
      } else if (isCompany && !companyLinkedIn) {
        toast.error('Company linkedIn is required');
        setload(false);
        return;
      } else if (isCompany && !companyAddress) {
        toast.error('Company address is required');
        setload(false);
        return;
      } else if (isCompany && !companyCountry) {
        toast.error('Company country is required');
        setload(false);
        return;
      } else if (isCompany && !companyCity) {
        toast.error('Company city is required');
        setload(false);
        return;
      } else if (isCompany && !companyPostCode) {
        toast.error('Company post code is required');
        setload(false);
        return;
      }

      console.log("chekc")

      const form = new FormData();
      form.append("userId", userDetail._id);
      form.append("name", name ? name : userDetail?.name);
      form.append("address", address ? address : userDetail?.location?.address);
      form.append("email", email ? email : userDetail?.email);
      form.append("imageName", image);
      form.append("city", city ? city : userDetail?.location.city);
      form.append("phone", phone ? phone : userDetail?.phone);
      form.append("isPackagesAndMail", isPackagesAndMail);
      form.append("country", country ? country : userDetail?.location?.country);
      form.append("linkedIn", linkedIn ? linkedIn : userDetail?.linkedIn);
      form.append("postCode", postCode ? postCode : userDetail?.location?.postCode);
      form.append("interestedServices", interestedServices ? JSON.stringify(interestedServices) : JSON.stringify(userDetail?.interestedServices));
      form.append("interestedSpaces", interestedSpaces ? JSON.stringify(interestedSpaces) : JSON.stringify(userDetail?.interestedSpaces));


      form.append("isCompany", isCompany);

      form.append("companyName", companyName ? companyName : userDetail?.companyData?.name);
      form.append("companyEmail", companyEmail ? companyEmail : userDetail?.companyData?.email);
      form.append("companyAddress", companyAddress ? companyAddress : userDetail?.companyData?.address);
      form.append("companyCity", companyCity ? companyCity : userDetail?.companyData?.city);
      form.append("companyPhone", companyPhone ? companyPhone : userDetail?.companyData?.phone);
      form.append("companyCountry", companyCountry ? companyCountry : userDetail?.companyData?.country);
      form.append("companyLinkedIn", companyLinkedIn ? companyLinkedIn : userDetail?.companyData?.linkedIn);
      form.append("companyPostCode", companyPostCode ? companyPostCode : userDetail?.companyData?.postCode);

      await dispatch(editLead(form)).unwrap();
      setname("")
      setaddress("")
      setemail("")
      setcity("")
      setphone("")
      setcountry(null)
      setlinkedIn("")
      setpostCode("")
      setisCompany(false)
      setcompanyName("")
      setcompanyEmail("")
      setcompanyAddress("")
      setcompanyCity("")
      setcompanyCountry("")
      setcompanyLinkedIn("")
      setcompanyPostCode("")
      setcompanyPhone("")
      setcompanyWebsite("")
      setinterestedServices([])
      setinterestedSpaces([])
      navigate("/customers")

      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [highlightedIndex1, setHighlightedIndex1] = useState(-1);

  const [searchValue, setSearchValue] = useState('');
  const [searchValue1, setSearchValue1] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setHighlightedIndex(-1);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  const handleOptionClick = (data) => {
    setSearchValue(data?.name);
    setcountry(data?.name);
    setShowDropdown(false);
  };
  const handleCompanyInputChange = (e) => {
    const value = e.target.value;
    setSearchValue1(value);
    setHighlightedIndex1(-1);
    if (value.length > 0) {
      setShowDropdown1(true);
    } else {
      setShowDropdown1(false);
    }
  };
  const handleOptionClickCompany = (data) => {
    setSearchValue1(data?.name);
    setcompanyCountry(data?.name);
    setShowDropdown1(false);
  };

  const filteredCountries = listOfCountry?.filter((data) =>
    data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
  );

  const filteredCountries1 = listOfCountry?.filter((data) =>
    data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };
  const handleKeyDown1 = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex1((prevIndex) =>
        prevIndex < filteredCountries1.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex1((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex1 !== -1) {
        handleOptionClickCompany(filteredCountries1[highlightedIndex1]);
      }
    }
  };

  console.log(country)


  const handlePhoneChange = (e) => {
    let input = e.target.value;

    if (!input) {
      setphone('');
      return;
    }
    input = input.replace(/[^0-9]/g, '');
    const formattedPhone = input.length > 0 ? `+${input}` : '+';
    setphone(formattedPhone);
  };

  const handleCompanyPhoneChange = (e) => {
    let input = e.target.value;

    if (!input) {
      setcompanyPhone('');
      return;
    }
    input = input.replace(/[^0-9]/g, '');
    const formattedPhone = input.length > 0 ? `+${input}` : '+';
    setcompanyPhone(formattedPhone);
  };


  const validatePostCode = (postcode) => {
    const postcodeRegex = /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$/;
    return postcodeRegex.test(postcode);
  };


  return (
    <>
      {load ? <Spinner /> : <></>}
      <div className="sp-add-location-page pb-5 px-md-5 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
            <li class="breadcrumb-item"><a>{t('menu')}</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/customers")}>{t('menu')}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{t('edit')} {t('customer')}</li>
          </ol>
        </nav>
        <div className="d-flex align-items-center gap-2 mb-4">
        <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/customers-icon-sp.svg`} className="icon-text" />
          <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('edit')} {t('customer')}</h2>
        </div>
        <div className="mb-4">
          <div className="row">
            <div className="col-md-2">
              <div className="sp-profile-upload">
                <div class="avatar-upload">
                  <div class="avatar-edit">
                    <input onChange={(e) => { setImage(e.target.files[0]) }} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                    <label for="imageUpload"><Camera width={18} strokeWidth={1} /> {image ? t('upload') : (userDetail?.imageName ? t('change') : t('upload'))}</label>
                  </div>
                  <div class="avatar-preview">
                    <img id="output" src={image ? URL.createObjectURL(image) : (userDetail?.imageName ? `${process.env.REACT_APP_IMGURL}/${userDetail?.imageName}` : '')} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-10 row gy-3 mb-3">
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="name" maxLength={40} placeholder="Name" defaultValue={name} onChange={(e) => setname(e.target.value)} />
                  <label for="name">{t('name')}*</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="email" class="form-control" id="email" maxLength={320} placeholder="Email"
                    disabled={userDetail?.userstatuses?.status == "member" || userDetail?.userstatuses?.status == "contact" ? true : false}
                    defaultValue={userDetail?.email} onChange={(e) => setemail(e.target.value)} />
                  <label for="email">{t('email')}*</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="tel" class="form-control" id="phone" maxLength={13} placeholder="Phone" value={phone} onChange={handlePhoneChange} />
                  <label for="phone">{t('phone')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="LinkedIn" placeholder="LinkedIn" defaultValue={userDetail?.linkedIn} onChange={(e) => setlinkedIn(e.target.value)} />
                  <label for="LinkedIn">{t('linkedIn')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="address" placeholder="Address" maxLength={120} defaultValue={userDetail?.location?.address} onChange={(e) => setaddress(e.target.value)} />
                  <label for="address">{t('address')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="country" maxLength={120} placeholder="country" onKeyDown={handleKeyDown} onChange={(e) => handleInputChange(e)} value={searchValue} />
                  <label for="country">{t('country')}</label>
                </div>
                {showDropdown && (
                  <div className="dropdown sp-select-dropdown">
                    <ul className="dropdown-menu show" style={{ width: '100%' }}>
                      {listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                        <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                      ))}
                      {listOfCountry?.length > 0 && listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                      )}
                    </ul>
                  </div>
                )}
                {/* <div class="dropdown sp-select-dropdown">
                    <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {country ? country : "Country"}
                    </a>
                    <ul class="dropdown-menu">
                      {listOfCountry?.map((data, i) => (
                        <li key={i} onClick={() => setcountry(data?.country)}><a class="dropdown-item" >{data?.country}</a></li>
                      ))}
                    </ul>
                  </div> */}
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="city" placeholder="City" maxLength={20} defaultValue={userDetail?.location?.city} onChange={(e) => setcity(e.target.value)} />
                  <label for="city">{t('city')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="postcode" placeholder="Postcode" maxLength={9} defaultValue={userDetail?.location?.postCode} onChange={(e) => setpostCode(e.target.value)} />
                  <label for="postcode">{t('postcode')}</label>
                </div>
              </div>
            </div>

            <div>
              <div class="form-check sp-checkbox mb-2">
                <input class="form-check-input" value={isPackagesAndMail ? 1 : 0} checked={isPackagesAndMail} onChange={handleRadioChange} type="checkbox" id="Representing-company" />
                <label class="form-check-label" for="Representing-company">{t('mailAndPackages')}</label>
              </div>
              <div class="form-check sp-checkbox mb-3">
                <input class="form-check-input" checked={isCompany ? true : false} onChange={() => setisCompany(!isCompany)} type="checkbox" id="Representing-company" />
                <label class="form-check-label" for="Representing-company">{t('representingCompany')}</label>
              </div>
            </div>
            {
              isCompany ? <>
                <p className="font-size-16 blue-color fw-semibold mb-2">{t('Company')} {t('Details')}</p>
                <div className="row gy-3 mb-4">
                  <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="text" class="form-control" maxLength={40} id="name" placeholder="Name" defaultValue={userDetail?.companyData?.name} onChange={(e) => setcompanyName(e.target.value)} />
                      <label for="name">{t('name')}*</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="email" class="form-control" id="email" maxLength={320} placeholder="Email" defaultValue={userDetail?.companyData?.email} onChange={(e) => setcompanyEmail(e.target.value)} />
                      <label for="email">{t('email')}*</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="text" class="form-control" maxLength={13} id="phone" placeholder="Phone" value={companyPhone} onChange={handleCompanyPhoneChange} />
                      <label for="phone">{t('phone')}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="text" class="form-control" id="LinkedIn" placeholder="LinkedIn" defaultValue={userDetail?.companyData?.linkedIn} onChange={(e) => setcompanyLinkedIn(e.target.value)} />
                      <label for="LinkedIn">{t('linkedIn')}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="text" class="form-control" id="address" maxLength={120} placeholder="Address" defaultValue={userDetail?.companyData?.address} onChange={(e) => setcompanyAddress(e.target.value)} />
                      <label for="address">{t('address')}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="text" class="form-control" id="country" maxLength={120} placeholder="country" onKeyDown={handleKeyDown1} onChange={(e) => handleCompanyInputChange(e)} value={searchValue1} />
                      <label for="country">{t('country')}</label>
                    </div>
                    {showDropdown1 && (
                      <div className="dropdown sp-select-dropdown">
                        <ul className="dropdown-menu show" style={{ width: '100%' }}>
                          {listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.map((data, index) => (
                            <li key={index} onClick={() => handleOptionClickCompany(data)} className={highlightedIndex1 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                          ))}
                          {listOfCountry?.length > 0 && listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.length === 0 && (
                            <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="text" class="form-control" id="city" placeholder="City" maxLength={20} defaultValue={userDetail?.companyData?.city} onChange={(e) => setcompanyCity(e.target.value)} />
                      <label for="city">{t('city')}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="text" class="form-control" id="postcode" placeholder="Postcode" maxLength={9} defaultValue={userDetail?.companyData?.postCode} onChange={(e) => setcompanyPostCode(e.target.value)} />
                      <label for="postcode">{t('postcode')}</label>
                    </div>
                  </div>
                </div>
              </> : <></>
            }
            <div className="mb-3">
              <p className="font-size-16 blue-color fw-semibold mb-2">{t('services')}</p>
              <div className="d-flex flex-wrap gap-3">
                {Services.map((data, i) => (
                  <>
                    <input name="services" type="checkbox" class="btn-check" onChange={handleCheck} checked={interestedServices?.includes(data?.text)} id={data?.text} />
                    <label className="btn sp-check-select" for={data?.text}>{data.text}</label>
                  </>
                ))}
              </div>
            </div>
            <div className="mb-3">
              <p className="font-size-16 blue-color fw-semibold mb-2">{t('locations')}</p>
              <div className="d-flex flex-wrap gap-3">
                {getAllSpaces.map((data, i) => (
                  <>
                    <input name="locations" type="checkbox" class="btn-check" checked={interestedSpaces.includes(data._id)} onChange={() => handleCheckboxChangeForLocation(data._id)} id={data._id} />
                    <label className="btn sp-check-select" for={data._id}>{data.name}</label>
                  </>
                ))}
              </div>
            </div>
          </div>

          <div className="text-center">
            <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>{t('save')}</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditLead