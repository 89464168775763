import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
import { createActivity, getAllActivity, createReminder, getAllReminder, reminderDelete, activityDelete, reminderEdit, activityEdit, getUserRight, getBusiness, getProfile, userDetailData, userDetailView } from "../../store/slices/userSlice";
import moment from "moment";
import { Pencil, X } from "lucide-react";
import { useTranslation } from "react-i18next";

const UserDetails = () => {
    const { t } = useTranslation();

    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const userRights = useSelector(getUserRight)

    const [userDetail, setUserDetail] = useState(location?.state)
    const [id, setid] = useState(location?.state._id)
    const [listOfActivity, setlistOfActivity] = useState([])
    const [listOfReminder, setlistOfReminder] = useState([])
    const [activityDate, setactivityDate] = useState(null)
    const [reminderDate, setreminderDate] = useState(null)
    const [activityType, setactivityType] = useState("")
    const [reminderType, setreminderType] = useState("")
    const [activitydescription, setactivitydescription] = useState("")
    const [reminderdescription, setreminderdescription] = useState("")
    const [editingReminder, setEditingReminder] = useState(null);
    const [editingActivity, seteditingActivity] = useState(null);
    const [editReminderText, seteditReminderText] = useState(null);
    const [editReminderType, seteditReminderType] = useState(null);
    const [editActivityText, seteditActivityText] = useState(null);
    const [editActivityType, seteditActivityType] = useState(null);
    const [editActivityTime, seteditActivityTime] = useState(null);
    const [editReminderTime, seteditReminderTime] = useState(null);


    const getActivity = async () => {
        try {
            const response = await dispatch(getAllActivity(userDetail?._id)).unwrap();
            setlistOfActivity(response?.data)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getReminder = async () => {
        try {
            const response = await dispatch(getAllReminder(userDetail._id)).unwrap();
            setlistOfReminder(response?.data)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const addActivity = async () => {
        try {

            const paylaod = {
                type: activityType,
                date: moment(activityDate.toString()),
                text: activitydescription,
                userId: userDetail._id
            }
            await dispatch(createActivity(paylaod)).unwrap();
            setactivityDate(null)
            setactivityType("")
            setactivitydescription("")
            getActivity()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const deleteReminder = async (id) => {
        try {
            await dispatch(reminderDelete(id)).unwrap();

            getReminder()
            setlistOfReminder((prevReminders) => prevReminders.filter(reminder => reminder._id !== id));

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const saveEditReminder = async (id) => {
        try {
            let payload = {
                text: editReminderText,
                type: editReminderType,
                date: editReminderTime,
                id
            }
            await dispatch(reminderEdit(payload)).unwrap();
            getReminder()
            setEditingReminder(null)
            seteditReminderText("")
            seteditReminderType("")
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }

    };

    const saveEditActivity = async (id) => {
        try {
            let payload = {
                text: editActivityText,
                type: editActivityType,
                date: editActivityTime,
                id
            }
            await dispatch(activityEdit(payload)).unwrap();
            getActivity()
            seteditingActivity(null)
            seteditActivityText("")
            seteditActivityType("")
            seteditActivityTime("")
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const deleteActivity = async (id) => {
        try {

            await dispatch(activityDelete(id)).unwrap();
            getActivity()
            setlistOfActivity((prevReminders) => prevReminders.filter(reminder => reminder._id !== id));

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getUserDetail = async () => {
        try {
            const response = await dispatch(userDetailView(location?.state?.users?._id)).unwrap();
            setUserDetail(response?.data[0])
            setid(response?.data[0]?._id)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    useEffect(() => {
        getUserDetail()
    }, [location.state])


    const addReminder = async () => {
        try {
            // moment(reminderDate.toString()).format("DD-MM-YYYY HH:mm"),
            const paylaod = {
                type: reminderType,
                date: moment(reminderDate.toString()),
                text: reminderdescription,
                userId: userDetail._id,
                businessId: profile?.businessId ? profile?.businessId : business._id,
            }
            await dispatch(createReminder(paylaod)).unwrap();
            setreminderDate("")
            setreminderType("")
            setreminderdescription("")
            getReminder()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const editReminder = (id) => {
        const reminderToEdit = listOfReminder.find((reminder) => reminder._id === id);
        setEditingReminder(reminderToEdit);

    };
    const editActivity = (id) => {
        const reminderToEdit = listOfActivity.find((reminder) => reminder._id === id);
        seteditingActivity(reminderToEdit);

    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            getActivity()
            getReminder()
        }
        return () => {
            mount = false;
        };
    }, [])

    return (
        <>
            <div className="sp-customer-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/customers")}>{t('customers')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('customer')} {t('detail')}</li>
                    </ol>
                </nav>
                <div className="row mb-4">
                    <div className="col-md-6 d-flex align-items-center gap-2">
                        <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/customers-icon-sp.svg`} className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('customer')} {t('detail')}</h2>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end gap-2">
                        {userRights?.Customers?.edit == 1 || userRights == null ? < button class="btn sp-button sp-blue-button" onClick={() => navigate("/customers/edit-customer", { state: id })}>{t('edit')} {t('detail')}</button> : <></>}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-5">
                        <div className="white-bg sp-shadow p-4 sp-radius-20">
                            {userDetail?.imageName ? <img class="rounded-circle sp-user-profile-big" alt="avatar" src={`${process.env.REACT_APP_IMGURL}/${userDetail?.imageName}`} /> : <img class="rounded-circle sp-user-profile" src="./assets/images/dummy.png" />}
                            <h4 className="font-size-36 titilium-font fw-bold light-blue-color mb-4">{userDetail?.name}</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    {userDetail?.email && <div class="mb-3">
                                        <p className="font-size-16 black-color mb-0">{t('email')}:</p>
                                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.email}</p>
                                    </div>}
                                    {userDetail?.phone && <div class="mb-3">
                                        <p className="font-size-16 black-color mb-0">{t('phone')}:</p>
                                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.phone}</p>
                                    </div>}
                                    {userDetail?.linkedIn && <div class="mb-3">
                                        <p className="font-size-16 black-color mb-0">{t('linkedIn')}:</p>
                                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.linkedIn}</p>
                                    </div>}
                                    {userDetail?.interestedServices?.length > 0 && (<div class="mb-0">
                                        <p className="font-size-16 black-color mb-0">{t('interestedServices')}:</p>
                                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.interestedServices?.join(', ')}</p>
                                    </div>)}
                                </div>
                                <div className="col-md-6">
                                    {userDetail?.location?.address && <div class="mb-3">
                                        <p className="font-size-16 black-color mb-0">{t('address')}:</p>
                                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.location?.address}</p>
                                    </div>}
                                    {userDetail?.location?.country && <div class="mb-3">
                                        <p className="font-size-16 black-color mb-0">{t('country')}:</p>
                                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.location?.country}</p>
                                    </div>}
                                    {userDetail?.location?.city && <div class="mb-0">
                                        <p className="font-size-16 black-color mb-0">{t('city')}:</p>
                                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.location?.city}</p>
                                    </div>}
                                    {userDetail?.spaces?.length > 0 && (<div class="mb-0">
                                        <p className="font-size-16 black-color mb-0">{t('interestedLocation')}:</p>
                                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.spaces?.map((data, i) => (<>{i > 0 && ', '}{data?.name}</>))}</p>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    {userDetail?.companyData && <>
                        <div className="col-md-7">
                            <div className="l-grey-bg p-4 sp-radius-20 h-100 sp-shadow">
                                <h4 className="font-size-36 titilium-font fw-bold light-blue-color mb-4">{t('company')} {t('detail')}</h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        {userDetail?.companyData?.name && <div class="mb-3">
                                            <p className="font-size-16 black-color mb-0">{t('name')}:</p>
                                            <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.companyData?.name}</p>
                                        </div>}
                                        {userDetail?.companyData?.email && <div class="mb-3">
                                            <p className="font-size-16 black-color mb-0">{t('email')}:</p>
                                            <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.companyData?.email}</p>
                                        </div>}
                                        {userDetail?.companyData?.phone && <div class="mb-3">
                                            <p className="font-size-16 black-color mb-0">{t('phone')}:</p>
                                            <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.companyData?.phone}</p>
                                        </div>}
                                        {userDetail?.companyData?.linkedIn && <div class="mb-0">
                                            <p className="font-size-16 black-color mb-0">{t('linkedIn')}:</p>
                                            <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.companyData?.linkedIn}</p>
                                        </div>}
                                    </div>
                                    <div className="col-md-6">
                                        {userDetail?.companyData?.address && <div class="mb-3">
                                            <p className="font-size-16 black-color mb-0">{t('address')}:</p>
                                            <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.companyData?.address}</p>
                                        </div>}
                                        {userDetail?.companyData?.city && <div class="mb-3">
                                            <p className="font-size-16 black-color mb-0">{t('city')}:</p>
                                            <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.companyData?.city}</p>
                                        </div>}
                                        {userDetail?.companyData?.country && <div class="mb-3">
                                            <p className="font-size-16 black-color mb-0">{t('country')}:</p>
                                            <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.companyData?.country}</p>
                                        </div>}
                                        {userDetail?.companyData?.postCode && <div class="mb-0">
                                            <p className="font-size-16 black-color mb-0">{t('postcode')}:</p>
                                            <p className="font-size-18 light-blue-color fw-semibold mb-0">{userDetail?.companyData?.postCode}</p>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>

                <h3 className="font-size-20 titilium-font fw-bold blue-color mb-2">{t('activity')}</h3>
                <div className="row mb-4 gy-3">
                    <div className="col-md-6">
                        <div class="form-floating sp-input">
                            <input type="datetime-local" class="form-control" id="News-Title" value={activityDate} onChange={(e) => setactivityDate(e.target.value)} placeholder="Date Time" />
                            <label for="News-Title">{t('dateTime')}</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div class="dropdown sp-select-dropdown">
                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {activityType ? activityType : <>{t('type')}</>}
                            </a>
                            <ul class="dropdown-menu">
                                <li onClick={() => setactivityType("visit")}><a class="dropdown-item">{t('Visit')}</a></li>
                                <li onClick={() => setactivityType("phoneCall")}><a class="dropdown-item">{t('Phone Call')}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div class="form-floating sp-textarea">
                            <textarea class="form-control" placeholder="Leave a comment here" id="Description" style={{ height: '100px' }} value={activitydescription} onChange={(e) => setactivitydescription(e.target.value)}></textarea>
                            <label for="Description">{t('description')}</label>
                        </div>
                    </div>
                    <div className="col-md-2 text-end align-self-end">
                        <button class="btn sp-button sp-blue-button" onClick={() => addActivity()}>{t('add')}</button>
                    </div>
                </div>


                <div className="sp-activity-reminder-list mb-4">
                    {listOfActivity?.map((data, i) => (
                        <div className="sp-activity-reminder-single d-flex align-items-center gap-2" key={i}>
                            <div className="flex-shrink-1 w-100">
                                <p className="sp-date-box">{moment(data.date).format("MMMM Do YYYY, h:mm:ss a")}</p>
                                <p className="font-size-14 black-color mb-2">{data.text}</p>
                            </div>

                            <div style={{ width: '170px' }} className="d-flex gap-2 align-items-center align-self-center justify-content-end">
                                <p style={{ marginRight: '10px' }} className="font-size-16 light-blue-color fw-medium mb-0"><u>{data.type}</u></p>
                                <span class="black-color cursor-pointer" onClick={() => editActivity(data._id)}><Pencil size={18} /></span>
                                <span class="red-color cursor-pointer" onClick={() => deleteActivity(data._id)}><X size={18} /></span>
                            </div>
                        </div>
                    ))}
                </div>

                <h3 className="font-size-20 titilium-font fw-bold blue-color mb-2">{t('reminders')}</h3>

                <div className="row mb-4 gy-3">
                    <div className="col-md-6">
                        <div class="form-floating sp-input">
                            <input type="datetime-local" class="form-control" id="News-Title" value={reminderDate} onChange={(e) => setreminderDate(e.target.value)} placeholder="Date Time" />
                            <label for="News-Title">{t('dateTime')}</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div class="dropdown sp-select-dropdown">
                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {reminderType ? reminderType : <>{t('type')}</>}
                            </a>
                            <ul class="dropdown-menu">
                                <li onClick={() => setreminderType("Visit")}><a class="dropdown-item">{t('visit')}</a></li>
                                <li onClick={() => setreminderType("Phone Call")}><a class="dropdown-item">{t('phoneCall')}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div class="form-floating sp-textarea">
                            <textarea class="form-control" placeholder="Leave a comment here" id="Description" style={{ height: '100px' }} value={reminderdescription} onChange={(e) => setreminderdescription(e.target.value)}></textarea>
                            <label for="Description">{t('description')}</label>
                        </div>
                    </div>
                    <div className="col-md-2 text-end align-self-end">
                        <button class="btn sp-button sp-blue-button" onClick={() => addReminder()}>{t('add')}</button>
                    </div>
                </div>
                {listOfReminder?.map((data, i) => (
                    <>
                        {data._id == editingReminder?._id ?
                            <>
                                <div className="row mb-4 gy-3">
                                    <div className="col-md-6">
                                        <div class="form-floating sp-input">
                                            <input type="datetime-local" class="form-control" id="News-Title" defaultValue={data?.date} disabled placeholder="Date Time" />
                                            <label for="News-Title">{t('dateTime')}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div class="dropdown sp-select-dropdown">
                                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {reminderType ? reminderType : <>{editingReminder.type}</>}
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li onClick={() => setreminderType("Visit")}><a class="dropdown-item">{t('visit')}</a></li>
                                                <li onClick={() => setreminderType("Phone Call")}><a class="dropdown-item">{t('phoneCall')}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div class="form-floating sp-textarea">
                                            <textarea class="form-control" placeholder="Leave a comment here" id="Description" style={{ height: '100px' }} defaultValue={editingReminder?.text} onChange={(e) => setreminderdescription(e.target.value)}></textarea>
                                            <label for="Description">{t('description')}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-2 text-end align-self-end">
                                        <button class="btn sp-button sp-blue-button" onClick={() => saveEditReminder(data._id)}>{t('update')}</button>
                                        <button class="btn sp-button sp-blue-button" onClick={() => setEditingReminder(null)}>{t('close')}</button>
                                    </div>
                                </div>
                            </> : null}
                    </>
                ))}


                <div className="sp-activity-reminder-list">
                    {listOfReminder?.map((data, i) => (
                        <div className="sp-activity-reminder-single d-flex align-items-center gap-2" key={i}>
                            <div className="flex-shrink-1 w-100">
                                <p className="sp-date-box">{moment(data.date).format("MMMM Do YYYY, h:mm:ss a")}</p>
                                <p className="font-size-14 black-color mb-2">{data.text}</p>
                            </div>

                            <div style={{ width: '170px' }} className="d-flex gap-2 align-items-center align-self-center justify-content-end">
                                <p style={{ marginRight: '10px' }} className="font-size-16 light-blue-color fw-medium mb-0"><u>{data.type}</u></p>
                                <span class="black-color cursor-pointer" onClick={() => editReminder(data?._id)}><Pencil size={18} /></span>
                                <span class="red-color cursor-pointer" onClick={() => deleteReminder(data?._id)}><X size={18} /></span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default UserDetails