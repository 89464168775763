import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import Store from "./store"
import { Toaster } from 'react-hot-toast';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={Store}>
    <I18nextProvider i18n={i18n}>
      <App />
      <Toaster
        position='top-center'
        reverseOrder={false}
        toastOptions={{
          duration: 1500
        }}

      />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);


reportWebVitals();
