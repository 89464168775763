import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';



const Map = ({ lat, long }) => {
    const mapStyles = {
        height: '400px',
        width: '100%',
    };

    const defaultProps = {
        center: {
            lat: lat || 0,
            lng: long || 0
        },
        zoom: 15
    };

    const center = {
        lat: lat || 0,
        lng: long || 0,
    };

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyBaf3chLDL6AlDi2LPM8GebwNdZyYgyqqk"
        >
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={center}
            >
            </GoogleMap>
        </LoadScript >
    );
};


const PlacesSearch = () => {
    const [value, setValue] = useState(null);
    const [lat, setlat] = useState()
    const [long, setlong] = useState()

    useEffect(() => {
        if (value) {
            geocodeByPlaceId(value?.value?.place_id)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    setlat(lat);
                    setlong(lng);
                })
                .catch(error => console.error(error));
        }
    }, [value]);

    return (
        <div>

            <GooglePlacesAutocomplete

                apiKey={"AIzaSyBaf3chLDL6AlDi2LPM8GebwNdZyYgyqqk"}
                selectProps={{
                    value,
                    onChange: setValue,
                }}
            />
            <Map lat={lat} long={long} />
        </div>
    );
};

export default PlacesSearch;
