import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBookingDetail, getBusiness, getInvoices, getProfile } from '../../store/slices/userSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const BookingDetails = () => {
    const { t } = useTranslation();
    const profile = useSelector(getProfile)

    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()


    const [bookingDetail, setbookingDetail] = useState(location?.state);
    const [listOfInvoices, setlistOfInvoices] = useState([]);


    const allInvoices = async () => {
        try {
            if (location?.state) {
                const response = await dispatch(getInvoices(bookingDetail._id)).unwrap();
                setlistOfInvoices(response?.data);
                console.log(response?.data, "invoices");
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const bookingDetails = async () => {
        try {
            if (location?.state) {
                const response = await dispatch(getBookingDetail(bookingDetail._id)).unwrap();
                setbookingDetail(response?.data)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        bookingDetails()
        allInvoices()
    }, [location?.state])




    return (
        <>
            <div className="sp-booking-detail-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/bookings")}>{t('bookings')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('booking')} {t('detail')}</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/bookings-icon-sp.svg`} className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('booking')} {t('detail')}</h2>
                </div>
                <div className="sp-shadow sp-radius-20 white-bg p-4 mb-4">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <p className="font-size-18 blue-color mb-2 fw-semibold">{t('detail')}:</p>
                            <p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('member')} {t('name')}:</span> {bookingDetail?.user?.name}</p>
                            <p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('company')}:</span> {bookingDetail?.company ? bookingDetail?.company?.name : "N/A"}</p>
                            <p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('location')}:</span> {bookingDetail?.space?.name}</p>

                        </div>
                        <div className="col-md-6 text-end">
                            {
                                bookingDetail?.carts[0]?.roomId?.type == "Meeting Room" || bookingDetail?.carts[0]?.roomId?.type == "Podcast Room" || bookingDetail?.carts[0]?.roomId?.type == "Huddle Room" || bookingDetail?.carts[0]?.roomId?.type == "Event Space" ?

                                    <>
                                        < p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('Date')}:</span> {moment(bookingDetail?.startDate).format("MMMM Do YYYY")}</p>
                                        <p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('Time Slot')}:</span> {moment(bookingDetail?.startTime, "H:mm").format("h:mm a")} to {moment(bookingDetail?.endTime, "H:mm").format("h:mm a")}</p>
                                    </>

                                    :

                                    <>
                                        <p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('startDate')}:</span> {moment(bookingDetail?.startDate).format("MMMM Do YYYY")}</p>
                                        <p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('endDate')}:</span> {bookingDetail?.endDate ? moment(bookingDetail?.endDate).format("MMMM Do YYYY") : "Not provided"}</p>
                                    </>
                            }
                            <div className="d-flex align-items-center gap-3 mt-2 justify-content-end">
                                <p className="font-size-16 blue-color mb-0 fw-semibold">{t('status')}</p>
                                <span className={`btn sp-button py-2 ${bookingDetail?.status == "Confirmed" ? "sp-green-button" : "sp-red-button"}`} >{bookingDetail?.status}</span>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">{t('title')}</th>
                                    <th scope="col">{t('plan')}</th>
                                    <th scope="col">{t('quantity')}</th>
                                    <th scope="col">{t('price')}</th>
                                    <th style={{ width: '100px' }} scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookingDetail?.carts?.map((data, i) => (
                                    <tr>
                                        <td style={{ 'verticalAlign': 'middle' }}>{`${data?.roomId ? data?.roomId?.name : data?.virtualOfficeId ? data?.virtualOfficeId?.name : data?.deskCategoryId?.name}`}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.planData?.name} ({data?.pricing?.type})</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.deskCategoryId ? data?.count : 1}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{profile?.currency} {data?.pricing?.price}</td>
                                        <td></td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('subtotal')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {bookingDetail?.subtotal}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('discount')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} - {bookingDetail?.discount}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('Total')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {bookingDetail?.subtotal - bookingDetail?.discount}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('tax')} {bookingDetail?.tax * 100} %</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {Math.round((bookingDetail?.subtotal - bookingDetail?.discount) * bookingDetail?.tax)}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('Total Amount')} </span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {bookingDetail?.totalAmount}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('deposit')} {t('amount')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {bookingDetail?.depositAmount}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('amount')} {t('due')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {bookingDetail?.totalAmount + (bookingDetail?.depositAmount ? bookingDetail?.depositAmount : 0)}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="white-bg mb-3 sp-shadow sp-radius-20">
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">#{t('invoice')}</th>
                                    <th scope="col">{t('items')}</th>
                                    <th scope="col">{t('status')}</th>
                                    <th scope="col">{t('amount')}</th>
                                    <th scope="col">{t('createdon')}</th>
                                    <th scope="col">{t('due')} {t('date')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listOfInvoices?.map((data, i) => (
                                    <tr>
                                        <td style={{ 'verticalAlign': 'middle' }}><u className="cursor-pointer" onClick={() => navigate("/invoices/invoice-detail", { state: data })}>#{data?.invoiceNumber}</u></td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.booking?.map((item, i) => (
                                            <>{item?.carts?.roomData ? item?.carts?.roomData?.name : item?.carts?.virtualOfficeData ? item?.carts?.virtualOfficeData.name : item?.carts?.deskData.name}</>))}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}><span className={`btn sp-button py-2 ${data.status == "Paid" ? "sp-green-button" : "sp-red-button"}`} >{data?.status}</span></td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{profile?.currency} {data?.totalAmount + data?.depositAmount}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{moment(data?.createdAt).format("MMMM Do YYYY")}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{moment(data?.dueDate).format("MMMM Do YYYY")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        </>
    )
}

export default BookingDetails