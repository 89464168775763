import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { changePassword } from "../../store/slices/userSlice";

const ChangePassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();


    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSecureEntry, setisSecureEntry] = useState(true)
    const [isSecureEntry1, setisSecureEntry1] = useState(true)


    const handleClick = async (e) => {
        e.preventDefault();
        try {
            let payload = {
                id: location.state._id,
                newPassword: password,
                confirmNewPassword: confirmPassword,
            }
            const response = await dispatch(changePassword(payload)).unwrap();
            navigate("/")
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    return (
        <>
            <section class="vh-100 authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-2-strong border-0 rounded">
                                <div class="card-body p-5 text-center">

                                <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}assets/images/logo-new.svg`} />
                                    <h3 className="font-size-24 titilium-font fw-bold light-blue-color mb-0">Setup New Password</h3>
                                    <p className="color-black font-size-14 mb-3">Enter New Password</p>

                                    {/* <div class="form-floating sp-input mb-3">
                                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                                            onChange={(e) => setEmail(e.target.value)} autocomplete="email" required />
                                        <label for="floatingInput">Your Email Address</label>
                                    </div> */}
                                    <div class="form-floating sp-input mb-3">
                                        <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                                            type={isSecureEntry ? "password" : "text"} autocomplete="password" required />
                                        <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                                        <label for="floatingPassword">Password</label>
                                    </div>
                                    <div class="form-floating sp-input mb-5">
                                        <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setConfirmPassword(e.target.value)}
                                            type={isSecureEntry1 ? "password" : "text"} autocomplete="password" required />
                                        <i onClick={() => { setisSecureEntry1((prev) => !prev) }} className={`fa-solid ${isSecureEntry1 ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                                        <label for="floatingPassword">Confirm Password</label>
                                    </div>
                                    <button class="btn sp-button sp-blue-button w-100" onClick={handleClick}>Update Password</button>

                                </div>
                                <div class="card-footer sp-auth-card-footer">
                                    <div className="text-center">
                                        <a
                                            className=""
                                            onClick={() => navigate("/login")}
                                        >
                                            <b className="light-blue-color">Back to Log in</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
};

export default ChangePassword;
