import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getBusiness, getByBusinessData, getAllRooms, getProfile, getUserRight, getAllVirtualOffice, getAllTickets } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Eye, Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const TechnicalSupport = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const userRights = useSelector(getUserRight)
    const [load, setload] = useState(false);
    const [toggle, settoggle] = useState(false);

    const [listOfTickets, setlistOfTickets] = useState([])
    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const allTickets = async () => {
        try {
            setload(true);
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }
            const response = await dispatch(getAllTickets(payload)).unwrap();
            
            setlistOfTickets(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };
    
    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness();
        }
        return () => {
            mount = false;
        };
    }, [])

    useEffect(() => {
        let mount = true;
        if (mount) {
            allTickets();

        }
        return () => {
            mount = false;
        };
    }, [business, profile])


    return (
        <>
        <div className="sp-technical-support-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('supportTickets')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                    <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                        <img height="32" src="./assets/images/it-icon-sp.svg" className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('supportTickets')}</h2>
                    </div>
                    <div className="col-md-6 order-md-2 order-3">
                        <div class="dropdown sp-search-dropdown">
                            <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
                                <span>{t('filterAndSearch')}</span>
                                <span>
                                {toggle ? <X /> : <Search />}
                                </span>
                            </a>

                            {/* <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputname" class="col-sm-2 col-form-label">Name</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={e => setNameFilter(e.target.value)} value={nameFilter} placeholder="Filter by Name" class="form-control" id="inputname" />
                                    </div>
                                </div>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputemail" class="col-sm-2 col-form-label">Email</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={e => setEmailFilter(e.target.value)} value={emailFilter} placeholder="Filter by Email" class="form-control" id="inputemail" />
                                    </div>
                                </div>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputemail" class="col-sm-2 col-form-label">Manager</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={e => setmanager(e.target.value)} value={manager} placeholder="Filter by Manager" class="form-control" id="inputemailcc" />
                                    </div>
                                </div>


                                <div className="d-flex gap-2 justify-content-end">
                                    <button class="btn sp-button sp-blue-button" onClick={applyFilter}>Search</button>
                                    <button class="btn sp-button sp-blue-button" onClick={resetFilter}>Reset</button>
                                </div>
                            </ul> */}
                        </div>
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                        {userRights?.['Technical Support']?.create == 1 || userRights == null ? <button class="btn sp-button sp-blue-button" onClick={() => navigate("/support-tickets/add-ticket")}>{t('addTicket')}</button> : <></>}
                    </div>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg">
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">{t('ticketNo')}</th>
                                    <th scope="col">{t('location')}</th>
                                    <th scope="col">{t('priority')}</th>
                                    <th scope="col">{t('status')}</th>
                                    <th scope="col">{t('category')}</th>
                                    <th scope="col">{t('assignedTo')}</th>
                                    <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : listOfTickets?.map((data, i) => (
                                    <tr>
                                        <td>{data?.ticketNumber}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.space?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}><span className={`btn sp-button py-2 ${data?.priority == "High" ? "sp-red-button" : data.priority == "Medium" ? "sp-grey-button" : "sp-green-button"}`}>{data?.priority}</span></td>
                                        <td style={{ 'verticalAlign': 'middle' }}><span className={`btn sp-button py-2 ${data?.status == "Open" ? "sp-red-button" : "sp-green-button"}`}>{data?.status}</span></td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.category?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.assignedUser?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                               <div onClick={() => navigate("/support-tickets/ticket-detail", { state: data })} className={`sp-action-icons`}><Eye width="18" /></div>
                                               <div className={`sp-action-icons`}><X width="18" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TechnicalSupport