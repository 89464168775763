import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getSpaceDetail, getSpaceDetail2 } from '../../store/slices/userSlice';
import { useTranslation } from 'react-i18next';

const SpaceDetail = () => {
    const { t } = useTranslation();
    const profile = useSelector(getProfile)
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const [setRoomType, setsetRoomType] = useState("All")
    const [deskType, setdeskType] = useState("Last 30 Days")
    const [deskUtilizationData, setDeskUtilizationData] = useState(null)

    const [space, setspace] = useState(location?.state)
    const [transformedData, settransformedData] = useState(null)
    const [monthsArray, setmonthsArray] = useState(null)



    const getDeskDetail = async () => {
        try {

            const response = await dispatch(getSpaceDetail({ id: location?.state?._id, roomType: setRoomType })).unwrap();

            setspace(response?.data[0])
            settransformedData(response?.transformedData)



        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const getDeskDetail2 = async () => {
        try {

            const response = await dispatch(getSpaceDetail2({ id: location?.state?._id, deskType: deskType })).unwrap();
            console.log(response)
            setDeskUtilizationData(response?.deskUtilizationData)
            setmonthsArray(response?.monthsArray)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    useEffect(() => {
        let mount = true;
        if (mount) {

            getDeskDetail();
        }
        return () => {
            mount = false;
        };
    }, [setRoomType])

    useEffect(() => {
        let mount = true;
        if (mount) {

            getDeskDetail2();
        }
        return () => {
            mount = false;
        };
    }, [deskType])

    console.log(space)

    const RoomUtilizationData = [
        {
            data: transformedData ? transformedData : []
        }
    ];

    const DeskUtilizationData = deskUtilizationData ? deskUtilizationData : deskUtilizationData
 
    
    const options = {
        chart: {
            height: 350,
            type: 'rangeBar'
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                dataLabels: {
                    hideOverflowingLabels: false
                },
                borderRadius: 10,
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (val, opts) => {
                const label = opts.w.globals.labels[opts.dataPointIndex];
                const a = moment(val[0]);
                const b = moment(val[1]);
                const diff = b.diff(a, 'days');
                return diff + (diff > 1 ? ' days' : ' day');
            },
        },
        xaxis: {
            type: 'datetime'
        },
        yaxis: {
            show: true, // Show the y-axis
            labels: {
                show: true,
                style: {
                    colors: '#4584E8',
                    fontWeight: '700' // Change y-axis label color to red
                },
            },
        },
    };

    const BarOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        stroke: {
            width: 1,
            colors: ['#fff'],
        },
        dataLabels: {
            formatter: (val) => {
                return val;
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10, // Add border radius to bars
            },
        },
        xaxis: {
            categories: monthsArray ? monthsArray : monthsArray,
        },
        fill: {
            opacity: 1,
        },
        colors: ['#4584E8', '#4584E8', '#4584E8', '#4584E8'],
        yaxis: {
            labels: {
                formatter: (val) => {
                    return val
                },
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
    };

    return (
        <>
            <div className="sp-location-detail-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('spaces')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/locations")}>{t('locations')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('location')} {t('detail')}</li>
                    </ol>
                </nav>
                <div className="row mb-2">
                    <div className="col-md-6">
                        <h2 className="font-size-24 titilium-font fw-bold color-black mb-0">{space?.name}</h2>
                    </div>
                    <div className="col-md-6 d-flex flex-row align-items-center gap-3 justify-content-end">
                        <button class="btn sp-button sp-blue-button" onClick={() => navigate("/locations/edit-location", { state: space })}>{t('edit')} {t('location')}</button>
                    </div>
                </div>
                <div className="row mb-4 gy-md-0 gy-4">
                    <div className="col-md-3 sp-details-slider">
                        <Swiper
                            modules={[Navigation, Pagination, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                        >
                            {space?.image?.map((imageUrl, index) => (
                                <SwiperSlide key={index}>
                                    <img src={`${process.env.REACT_APP_IMGURL}/${imageUrl}`} className="sp-details-image-slider" alt={`Space Image ${index + 1}`} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="col-md-9 align-self-center row gy-3">
                        <div className="col-md-4">
                            <div className="d-flex gap-2">
                                <img width={32} src="../assets/images/open-days-iconsp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('openDays')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{space?.days?.length > 0 && `${space.days[0]} - ${space.days[space.days.length - 1]}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex gap-2 ">
                                <img width={32} src="../assets/images/open-timings-iconsp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('openTimings')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{space?.spaceStartTime} to {space?.spaceEndTime}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex gap-2 ">
                                <img width={32} src="../assets/images/support-user-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('supportuser')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{space?.supportUser?.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex gap-2 ">
                                <img width={32} src="../assets/images/address-iconsp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('address')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{space?.location?.address}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex gap-2">
                                <img width={32} src="../assets/images/country-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('country')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{space?.location?.country}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex gap-2">
                                <img width={32} src="../assets/images/spaces-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('city')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{space?.location?.city}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex gap-2">
                                <img width={32} src="../assets/images/address-iconsp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('postcode')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{space?.location?.postCode}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="font-size-18 titilium-font fw-bold light-blue-color mb-2">{t('space')} {t('detail')}</h3>
                <div className="row mb-4">
                    <div className="col-md-3">
                        <div className="sp-shadow sp-radius-20 l-blue-bg p-4">
                            <img height="38" src="../assets/images/name-icon-sp.svg" className="icon-text mb-1" />
                            <p className="font-size-18 color-black fw-semibold mb-0">{t('manager')} {t('name')}</p>
                            <p className="font-size-16 light-blue-color mb-0">{space?.spaceManagerName}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="sp-shadow sp-radius-20 l-blue-bg p-4">
                            <img height="38" src="../assets/images/email-iconsp.svg" className="icon-text mb-1" />
                            <p className="font-size-18 color-black fw-semibold mb-0">{t('email')}</p>
                            <p className="font-size-16 light-blue-color mb-0">{space?.spaceEmail}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="sp-shadow sp-radius-20 l-blue-bg p-4">
                            <img height="38" src="../assets/images/mobile-iconsp.svg" className="icon-text mb-1" />
                            <p className="font-size-18 color-black fw-semibold mb-0">{t('phone')}</p>
                            <p className="font-size-16 light-blue-color mb-0">{space?.spacePhone}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="sp-shadow sp-radius-20 l-blue-bg p-4">
                            <img height="38" src="../assets/images/mobile-iconsp.svg" className="icon-text mb-1" />
                            <p className="font-size-18 color-black fw-semibold mb-0">{t('mobile')}</p>
                            <p className="font-size-16 light-blue-color mb-0">{space?.spaceMobile}</p>
                        </div>
                    </div>
                </div>
                <h3 className="font-size-18 titilium-font fw-bold light-blue-color mb-2">{t('overview')}</h3>
                <div className="row mb-4">
                    <div className="col-md-2">
                        <div className="sp-shadow sp-radius-20 white-bg p-4">
                            <img height="38" src="../assets/images/total-members-icon-sp.svg" className="icon-text mb-1" />
                            <p className="font-size-26 light-blue-color fw-semibold mb-0">0</p>
                            <p className="font-size-14 grey-color mb-0">{t('totalMembers')}</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="sp-shadow sp-radius-20 white-bg p-4">
                            <img height="38" src="../assets/images/total-rooms-icon-sp.svg" className="icon-text mb-1" />
                            <p className="font-size-26 light-blue-color fw-semibold mb-0">{space?.totalRooms}</p>
                            <p className="font-size-14 grey-color mb-0">{t('totalRooms')}</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="sp-shadow sp-radius-20 white-bg p-4">
                            <img height="38" src="../assets/images/total-desks-icon-sp.svg" className="icon-text mb-1" />
                            <p className="font-size-26 light-blue-color fw-semibold mb-0">{space?.totaldesks}</p>
                            <p className="font-size-14 grey-color mb-0">{t('totalDesks')}</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="sp-shadow sp-radius-20 white-bg p-4">
                            <img height="38" src="../assets/images/bookings-icon-sp.svg" className="icon-text mb-1" />
                            <p className="font-size-26 light-blue-color fw-semibold mb-0">{space?.totalBookings}</p>
                            <p className="font-size-14 grey-color mb-0">{t('totalBookings')}</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="sp-shadow sp-radius-20 white-bg p-4">
                            <img height="38" src="../assets/images/total-events-icon-sp.svg" className="icon-text mb-1" />
                            <p className="font-size-26 light-blue-color fw-semibold mb-0">{space?.totalEvents}</p>
                            <p className="font-size-14 grey-color mb-0">{t('totalEvents')}</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="sp-shadow sp-radius-20 white-bg p-4">
                            <img height="38" src="../assets/images/occupancy-icon-sp.svg" className="icon-text mb-1" />
                            <p className="font-size-26 light-blue-color fw-semibold mb-0">0</p>
                            <p className="font-size-14 grey-color mb-0">{t('occupancy')}</p>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3 className="font-size-18 titilium-font fw-bold light-blue-color mb-0">{t('DesksUtilization')}</h3>
                    <select value={deskType} onChange={(e) => setdeskType(e.target.value)} style={{ border: '0', outline: '0', color: 'var(--color2)' }}>
                        <option value="" disabled>Select</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Last 30 Days">Last 30 days</option>
                    </select>
                </div>
                <div className="meeting-room-chart mb-4" id="chart">
                    {deskUtilizationData && monthsArray && (<ReactApexChart options={BarOptions} series={DeskUtilizationData} type="bar" height={350} />)}
                </div>

                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3 className="font-size-18 titilium-font fw-bold light-blue-color mb-0">{t('RoomsUtilization')}</h3>
                    <select value={setRoomType} onChange={(e) => setsetRoomType(e.target.value)} style={{ border: '0', outline: '0', color: 'var(--color2)' }}>
                        <option value="" disabled>Select</option>
                        <option value="All">All</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Last 30 Days">Last 30 days</option>
                        <option value="24 Months">24 Months</option>
                    </select>
                </div>
                <div className="rooms-utilization-chart p-2" id="chart">
                    {transformedData && (<ReactApexChart options={options} series={RoomUtilizationData} type="rangeBar" height={350} />)}
                </div>
            </div>
        </>
    )
}

export default SpaceDetail