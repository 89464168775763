import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, getSideBar } from '../../store/slices/userSlice';
import { AlignJustify, ChevronDown, ChevronUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const Sidebar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const sidebar = useSelector(getSideBar)
    const profile = useSelector(getProfile)
    const { toggleButton, SetToggleButton } = useContext(context);
    const [toggle, settoggle] = useState(false);
    const [toggle1, settoggle1] = useState(false);
    const [toggle2, settoggle2] = useState(false);
    const [toggle3, settoggle3] = useState(false);
    const [toggleStates, setToggleStates] = useState({});
    const [sideBarData, setsideBarData] = useState();
    useEffect(() => {
        const localSide = JSON.parse(localStorage.getItem('sidebar')) ? JSON.parse(localStorage.getItem('sidebar')) : null
        setsideBarData(localSide ? localSide : sidebar)
    }, [])

    const handleToggleClick = (menuItem) => {
        setToggleStates((prevToggleStates) => ({
            ...prevToggleStates,
            [menuItem._id]: !prevToggleStates[menuItem._id],
        }));
    };

    const mainMenuItems = sideBarData?.filter((item) => item.parent === null);
    return (
        <>
            <div className={toggleButton ? "sidebar close" : "sidebar"}>
                <div class="logo-details">
                    <AlignJustify onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color5)' strokeWidth={1} />
                </div>
                <ul class="nav-links">
                    {mainMenuItems?.map((menuItem) => {
                        const hasChildItems = sideBarData?.some((item) => item.parent === menuItem._id);
                        // const isActive = location?.pathname === `/${menuItem.slug}` ||
                        //     sideBarData?.some((item) => location?.pathname === `/${item.slug}` && item.parent === menuItem._id);
                        const isActive = location?.pathname.includes(`/${menuItem.slug}`) ||
                sideBarData?.some((item) => location?.pathname.includes(`/${item.slug}`) && item.parent === menuItem._id);


                        return (
                            <li
                                key={menuItem._id}
                                className={`${isActive ? 'active' : ''
                                    } ${hasChildItems ? (toggleStates[menuItem._id] ? 'showMenu' : '') : ''}`}
                            >
                                <div className="icon-link">
                                    <Link to={`/${menuItem.slug}`}>
                                        <img className="" alt="icon" src={`${process.env.REACT_APP_FRONTURL}assets/images/${menuItem.image}`} />
                                        <span className="link_name">{i18n.language === 'ar' ? menuItem.arabicName : menuItem.name}</span>
                                    </Link>
                                    {hasChildItems && (
                                        <span onClick={() => handleToggleClick(menuItem)} className="cursor-pointer">
                                            {toggleStates[menuItem._id] ? <ChevronUp /> : <ChevronDown />}
                                        </span>
                                    )}
                                </div>
                                {hasChildItems && (
                                    <ul className="sub-menu">
                                        <li><Link class="link_name" to={`/${menuItem.slug}`}>{i18n.language === 'ar' ? menuItem.arabicName : menuItem.name}</Link></li>
                                        {sideBarData
                                            .filter((item) => item.parent === menuItem._id && (item.slug !== 'user-role' || profile.role === 'Admin' || profile.role === 'SpaceOwner'))
                                            .map((subMenuItem) => (
                                                <li key={subMenuItem._id}>
                                                    <Link className={`${location?.pathname === `/${subMenuItem.slug}` ? "active" : ""}`} to={`/${subMenuItem.slug}`}>
                                                    {i18n.language === 'ar' ? subMenuItem.arabicName : subMenuItem.name}
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    )
}

export default Sidebar