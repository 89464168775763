import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { deskDetail, getProfile } from '../../store/slices/userSlice';
import { useTranslation } from 'react-i18next';

const DeskDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    const [desk, setDesk] = useState()
    const [totalCountArray, settotalCountArray] = useState()
    const [occupiedArray, setoccupiedArray] = useState()
    const [monthsArray, setmonthsArray] = useState()
    const [lastFreeDesk, setlastFreeDesk] = useState()
    const [setType, setsetType] = useState("Monthly")


    const getDeskDetail = async () => {
        try {
            const response = await dispatch(deskDetail({ id: location?.state?._id, type: setType })).unwrap();
            console.log(response)
            setDesk(response?.data[0])
            settotalCountArray(response?.totalCountArray)
            setoccupiedArray(response?.occupiedArray)
            setmonthsArray(response?.monthsArray)
            setlastFreeDesk(response?.lastElement)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    console.log(occupiedArray)




    useEffect(() => {
        let mount = true;
        if (mount) {

            getDeskDetail();
        }
        return () => {
            mount = false;
        };
    }, [setType])

    const DeskUtilizationData = [
        {
            name: 'Occupied',
            group: 'budget',
            data: occupiedArray ? occupiedArray : occupiedArray,

            color: '#007786',
        },
        {
            name: 'Free',
            group: 'budget',
            data: totalCountArray ? totalCountArray : totalCountArray,
            color: '#03045E',
        },
    ];


    const BarOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        stroke: {
            width: 1,
            colors: ['#fff'],
        },
        dataLabels: {
            formatter: (val) => {
                return val;
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10, // Add border radius to bars
            },
        },
        xaxis: {
            categories: monthsArray ? monthsArray : monthsArray,
        },
        fill: {
            opacity: 1,
        },
        colors: ['#80c7fd', '#008FFB', '#80f1cb', '#00E396'],
        yaxis: {
            labels: {
                formatter: (val) => {
                    return val;
                },
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
    };
    return (
        <>
            <div className="sp-customer-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('spaces')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/desks")}>{t('desks')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('desk')} {t('detail')}</li>
                    </ol>
                </nav>
                <div className="row mb-2">
                    <div className="col-md-6">
                        <h2 className="font-size-24 titilium-font fw-bold color-black mb-0">{desk?.name}</h2>
                    </div>
                    <div className="col-md-6 d-flex flex-row align-items-center gap-3 justify-content-end">
                        <button class="btn sp-button sp-blue-button" onClick={() => navigate("/desks/edit-desk", { state: desk })}>{t('edit')} {t('desk')}</button>
                    </div>
                </div>
                <div className="row mb-4 gy-4">
                    <div className="col-md-3 sp-details-slider">
                        <Swiper
                            modules={[Navigation, Pagination, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                        >
                            {desk?.image?.map((imageUrl, index) => (
                                <SwiperSlide key={index}>
                                    <img src={`${process.env.REACT_APP_IMGURL}/${imageUrl}`} className="sp-details-image-slider" alt={`Space Image ${index + 1}`} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <div className="d-flex gap-2 mb-3">
                            <img width={32} src="../assets/images/occupied-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{desk?.type == "Hot Desk" ? "Occupied / No of Booking" : "Occupied / Capacity"}</p>
                                <p className="font-size-16 light-blue-color mb-0">{lastFreeDesk} / {desk?.deskCount}</p>
                            </div>
                        </div>
                        {desk?.type == "Hot Desk" && <div className="d-flex gap-2 mb-3">
                            <img width={32} src="../assets/images/occupied-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>Present Desk</p>
                                <p className="font-size-16 light-blue-color mb-0">{lastFreeDesk} / {desk?.presentDesk}</p>
                            </div>
                        </div>}
                        <div className="d-flex gap-2">
                            <img width={32} src="../assets/images/size-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('size')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{desk?.deskSize} sq. ft.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 align-self-center">
                        <div className="d-flex gap-2 mb-3">
                            <img width={32} src="../assets/images/dr-type-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('type')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{desk?.type}</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <img width={32} src="../assets/images/address-iconsp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('location')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{desk?.spaceId?.name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex gap-2 mb-3">
                            <img width={32} src="../assets/images/to-do icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('description')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{desk?.description}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {desk?.planDetail?.map((data, i) => (<>
                    <h3 className="font-size-18 titilium-font fw-bold light-blue-color mb-0">Plan # {i + 1}</h3>
                    <div className="row mb-3">
                        <div className="col-md-6 row gy-3 mt-0">
                            <div className="col-md-4">
                                <div className="sp-shadow sp-radius-20 l-blue-bg p-4">
                                    <img height="38" src="../assets/images/name-icon-sp.svg" className="icon-text mb-1" />
                                    <p className="font-size-18 color-black fw-semibold mb-0">{t('name')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{data?.name}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="sp-shadow sp-radius-20 white-bg p-4">
                                    <img height="38" src="../assets/images/deposit-icon-sp.svg" className="icon-text mb-1" />
                                    <p className="font-size-18 color-black fw-semibold mb-0">{t('deposit')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{data?.deposit}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="sp-shadow sp-radius-20 white-bg p-4">
                                    <img height="38" src="../assets/images/size-icon-sp.svg" className="icon-text mb-1" />
                                    <p className="font-size-18 color-black fw-semibold mb-0">{t('multipleQuantity')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{data?.name}</p>
                                </div>
                            </div>
                            {data?.pricing.map((option, index) => (
                                <div className="col-md-4">
                                    <div className="sp-shadow sp-radius-20 white-bg p-4">
                                        <img height="34" src="../assets/images/price-icon-sp.svg" className="icon-text mb-1" />
                                        <p className="font-size-18 color-black fw-semibold mb-0"> {t(option?.type?.toLowerCase())} {t('price')}</p>
                                        <p className="font-size-16 light-blue-color mb-0">{option?.price}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-md-6">
                            <div className="sp-shadow sp-radius-20 white-bg p-4 h-100">
                                <p className="font-size-18 color-black fw-semibold mb-0">{t('description')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{data?.description}</p>
                            </div>
                        </div>
                    </div>
                </>))}

                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="font-size-18 titilium-font fw-bold light-blue-color mb-0">{t('DesksUtilization')}</h3>
                    <select value={setType} onChange={(e) => setsetType(e.target.value)} style={{ border: '0', outline: '0', color: 'var(--color2)' }}>
                        <option value="" disabled>Select</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Last 30 Days">Last 30 days</option>
                    </select>
                </div>
                <div className="meeting-room-chart" id="chart">
                    {totalCountArray && occupiedArray && monthsArray && (<ReactApexChart options={BarOptions} series={DeskUtilizationData} type="bar" height={350} />)}
                </div>
            </div>
        </>
    )
}

export default DeskDetails