import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSpace, getBusiness, getByBusinessData, getCountry, getEmployeeDependsOnSpace, getProfile, getUserRight } from "../../store/slices/userSlice";
import moment from "moment/moment";
import { Navigate, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { Camera } from "lucide-react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const AddSpace = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const business = useSelector(getBusiness)
  const profile = useSelector(getProfile)
  const [id, setid] = useState("");
  const [images, setImages] = useState([]);
  const [prev_images, setprev_images] = useState([]);
  const [name, setname] = useState("");
  const [line1, setline1] = useState("");
  const [line2, setline2] = useState("");
  const [city, setcity] = useState(null);
  const [country, setcountry] = useState(null);
  const [address, setaddress] = useState("");
  const [spaceManagerName, setspaceManagerName] = useState("");
  const [spaceEmail, setspaceEmail] = useState("");
  const [spacePhone, setspacePhone] = useState("");
  const [postCode, setpostCode] = useState(null);
  const [spaceMobile, setspaceMobile] = useState("");
  const [spaceStartTime, setspaceStartTime] = useState("");
  const [spaceEndTime, setspaceEndTime] = useState("");
  const [operationDays, setoperationDays] = useState([]);
  const [is24Hours, setis24Hours] = useState(0);
  const [listOfCountry, setlistOfCountry] = useState(null);
  const [userId, setuserId] = useState('');
  const [listOfEmployee, setlistOfEmployee] = useState([]);
  const [load, setload] = useState(false);

  const getMyBusiness = async () => {
    try {
      await dispatch(getByBusinessData()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const allCountry = async () => {
    try {

      const response = await dispatch(getCountry()).unwrap();
      setlistOfCountry(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getEmployee = async () => {
    try {
      let payload = {
        businessId: profile?.businessId ? profile?.businessId : business._id
      }
      const response = await dispatch(getEmployeeDependsOnSpace(payload)).unwrap();
      console.log("===>", payload, response.data);
      setlistOfEmployee(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  useEffect(() => {

    getMyBusiness();
    allCountry()
    getEmployee()

  }, [])

  const handleCheckboxChange = (text) => {
    const updatedCheckboxes = operationDays.includes(text)
      ? operationDays.filter((item) => item !== text)
      : [...operationDays, text];

    setoperationDays(updatedCheckboxes);
  };

  const Details = async () => {
    try {
      setprev_images([]);
      const response = await dispatch().unwrap();
      setprev_images(response?.data?.imageName.length > 0 ? response?.data?.imageName : [])
      setid(response?.data?.id ? response?.data?.id : "")
    } catch (rejectedValueOrSerializedError) {

    }
  };


  const check24Hours = (e) => {
    e.preventDefault()
    if (is24Hours == 0) {
      setspaceStartTime("00:00")
      setspaceEndTime("23:59")
      setis24Hours(1)
    } else {
      setis24Hours(0)
      setspaceStartTime("")
      setspaceEndTime("")
    }
  };


  const handleImageUpload = (event) => {

    const selectedImages = event.target.files;
    const imagesArray = [];

    for (let i = 0; i < selectedImages.length; i++) {
      imagesArray.push(selectedImages[i]);
    }
    setImages(images.concat(imagesArray));
  };


  const handleImageRemovePrev = (e, index) => {
    e.preventDefault();
    const updatedImages = [...prev_images];
    updatedImages.splice(index, 1);
    setprev_images(updatedImages);
  };

  const handleImageRemove = (e, index) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);

    setImages(updatedImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setload(true);
      if (spaceEmail) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(spaceEmail);
        if (!isValidEmail) {
          toast.error('Please enter a valid email address!');
          setload(false);
          return;
        }
      }
      if (!name) {
        toast.error('Space Name is required');
        setload(false);
        return;
      }
      if (!userId) {
        toast.error('Support User is required');
        setload(false);
        return;
      }
      if (!spaceStartTime) {
        toast.error('Space Start Time is required');
        setload(false);
        return;
      }
      if (!spaceEndTime) {
        toast.error('Space End Time is required');
        setload(false);
        return;
      }
      if (!operationDays.length == 1) {
        toast.error('Operation Days is required');
        setload(false);
        return;
      }
      if (!images.length == 1) {
        toast.error('Images are required');
        setload(false);
        return;
      }
      if (!address) {
        toast.error('Address is required');
        setload(false);
        return;
      }
      if (!city) {
        toast.error('City is required');
        setload(false);
        return;
      }
      if (!country) {
        toast.error('Country is required');
        setload(false);
        return;
      }
      const form = new FormData();
      form.append("name", name);
      form.append("spaceSupport", userId);
      form.append("businessId", profile?.businessId ? profile?.businessId : business._id);
      form.append("spaceStartTime", spaceStartTime);
      form.append("spaceEndTime", spaceEndTime);
      // form.append("spaceStartTime", moment(spaceStartTime.toString()).format("HH:mm"));
      // form.append("spaceEndTime", moment(spaceEndTime.toString()).format("HH:mm"));
      form.append("days", JSON.stringify(operationDays));
      form.append("spaceManagerName", spaceManagerName);
      form.append("spaceEmail", spaceEmail);
      form.append("spacePhone", spacePhone);
      form.append("spaceMobile", spaceMobile);
      images.forEach((item) => {
        form.append("spaceImage", item);
      });
      form.append("lines1", line1);
      form.append("lines2", line2);
      form.append("city", city);
      form.append("country", country);
      form.append("address", address);
      form.append("postCode", postCode);

      console.log('Form Data:', Object.fromEntries(form.entries()));


      await dispatch(addSpace(form)).unwrap();
      setoperationDays([])
      setImages([])
      setspaceEndTime("")
      setspaceStartTime("")
      setspaceMobile("")
      setpostCode("")
      setspacePhone("")
      setspaceEmail("")
      setspaceManagerName("")
      setaddress("")
      setcountry(null)
      setcity(null)
      setline2("")
      setline1("")
      setname("")

      setload(false);
      navigate("/locations")
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

 

  const OperationDays = [
    {
      id: 1,
      text: <>{t('mon')}</>,
      value: 'Mon',
    },
    {
      id: 2,
      text: <>{t('tue')}</>,
      value: 'Tue',
    },
    {
      id: 3,
      text: <>{t('wed')}</>,
      value: 'Wed',
    },
    {
      id: 4,
      text: <>{t('thu')}</>,
      value: 'Thu',
    },
    {
      id: 5,
      text: <>{t('fri')}</>,
      value: 'Fri',
    },
    {
      id: 6,
      text: <>{t('sat')}</>,
      value: 'Sat',
    },
    {
      id: 7,
      text: <>{t('sun')}</>,
      value: 'Sun',
    },
  ];

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  const handleOptionClick = (data) => {
    setSearchValue(data?.name);
    setcountry(data?.name);
    setShowDropdown(false);
  };

  const filteredCountries = listOfCountry?.filter((data) =>
    data.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };


  // useEffect(() => {
  //   const initAutocomplete = () => {
  //     const autocomplete = new window.google.maps.places.Autocomplete(
  //       document.getElementById('autocomplete'),
  //       { types: ['geocode'] }
  //     );

  //     autocomplete.addListener('place_changed', () => {
  //       const place = autocomplete.getPlace();

  //       const newCity = place.address_components.find(
  //         (component) => component.types.includes('locality') || component.types.includes('administrative_area_level_1')
  //       )?.long_name || '';

  //       const newCountry = place.address_components.find(
  //         (component) => component.types.includes('country')
  //       )?.long_name || null;

  //       const newPostCode = place.address_components.find(
  //         (component) => component.types.includes('postal_code')
  //       )?.long_name || '';

  //       setcity(newCity);
  //       const selectedCountry = listOfCountry?.find(data => data?.name === newCountry);
  //       console.log(selectedCountry, "selected country");
  //       setSearchValue(selectedCountry?.name || null);
  //       setcountry(selectedCountry?.name || null);
  //       setpostCode(newPostCode);
  //     });

  //     // Add a listener for input changes
  //     autocomplete.addListener('place_changed', () => {
  //       const input = document.getElementById('autocomplete');

  //       // Use AutocompleteService to get place predictions based on input
  //       const service = new window.google.maps.places.AutocompleteService();
  //       service.getPlacePredictions({ input: input.value }, (predictions, status) => {
  //         if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
  //           // Log the predictions to console (you can use them as suggestions or display them in a dropdown)
  //           console.log(predictions);
  //         }
  //       });
  //     });
  //   };

  //   initAutocomplete();
  // }, []);


  const handlePhoneChange = (e) => {
    let input = e.target.value;

    if (!input) {
      setspacePhone('');
      return;
    }
    input = input.replace(/[^0-9]/g, '');
    const formattedPhone = input.length > 0 ? `+${input}` : '+';
    setspacePhone(formattedPhone);
  };
  const handleMobileChange = (e) => {
    let input = e.target.value;

    if (!input) {
      setspaceMobile('');
      return;
    }
    input = input.replace(/[^0-9]/g, '');
    const formattedPhone = input.length > 0 ? `+${input}` : '+';
    setspaceMobile(formattedPhone);
  };

  const validatePostCode = (postcode) => {
    const postcodeRegex = /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$/;
    return postcodeRegex.test(postcode);
  };


  return (
    <>
      {load ? <Spinner /> : <></>}
      <div className="sp-add-location-page pb-5 px-md-5 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
            <li class="breadcrumb-item"><a>{t('menu')}</a></li>
            <li class="breadcrumb-item"><a>{t('spaces')}</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/locations")}>{t('locations')}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{t('addLocation')}</li>
          </ol>
        </nav>
        <div className="d-flex align-items-center gap-2 mb-4">
          <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/spaces-icon-sp.svg`} className="icon-text" />
          <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('addLocation')}</h2>
        </div>
        <div className="col-md-6 mb-3">
          <div class="form-floating sp-input">
            <input type="text" class="form-control" id="space-name" onChange={(e) => setname(e.target.value)} maxLength={40} value={name} placeholder="Space Name" />
            <label for="space-name">{t('spaceName')}</label>
          </div>
        </div>
        <div className="mb-4">
          <div class="row mb-3">
            <div class="col-md-6">
              <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">{t('operationdays')}</label>
              <div class=" d-flex flex-wrap gap-3 align-self-center">
                {OperationDays.map((data, i) => (
                  <>
                    <input name="operating-days" type="checkbox" class="btn-check" checked={operationDays.includes(data.value)} onChange={() => handleCheckboxChange(data.value)} id={data.value} autocomplete="off" />
                    <label className="btn sp-check-select" for={data.value}>{data.text}</label>
                  </>
                ))}
              </div>
            </div>
            <div className="col-md-6 d-flex gap-2 flex-wrap">
              <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold w-100">{t('operationhours')}</label>
              <div className="d-flex gap-4 w-75 flex-shrink-1">
                <div class="form-floating sp-input w-100">
                  <input type="time" class="form-control" id="spaceStartTime" value={spaceStartTime} onChange={(e) => setspaceStartTime(e.target.value)} disabled={is24Hours == 1 ? true : false} placeholder="Space Name" />
                  <label for="spaceStartTime">{t('space')} {t('startTime')}</label>
                </div>
                <div class="form-floating sp-input w-100">
                  <input type="time" class="form-control" id="spaceStartTime" disabled={is24Hours == 1 ? true : false} onChange={(e) => setspaceEndTime(e.target.value)} value={spaceEndTime} placeholder="Space Name" />
                  <label for="spaceStartTime">{t('space')} {t('endTime')}</label>
                </div>
              </div>
              <div className="d-flex align-self-center">
                <input type="checkbox" class="btn-check" onChange={check24Hours} id="24-hrs" />
                <label className="btn sp-check-select" for="24-hrs">{t('24hrs')}</label>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">{t('supportuser')}</label>
            <div class=" d-flex flex-wrap gap-3 align-self-center">
              {listOfEmployee.map((data, i) => (
                <>
                  <input name="support-user" type="radio" class="btn-check" onChange={() => setuserId(data?._id)} id={data._id} />
                  <label className="btn sp-check-select" for={data._id}>{data?.name} - {data?.roleName ? data?.roleName?.roleName : "Business Owner"}</label>
                </>
              ))}
            </div>
          </div>

          <div class="mb-3">
            <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">{t('address')}</label>
            <div class=" row gy-3">
              <div className="col-md-12">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="autocomplete" value={address} maxLength={100} onChange={(e) => setaddress(e.target.value)} placeholder="address" autoComplete="address" />
                  <label for="autocomplete">{t('address')}</label>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="line2" value={line2} maxLength={100} onChange={(e) => setline2(e.target.value)} placeholder="line2" />
                  <label for="line2">{t('line2')}</label>
                </div>
              </div> */}
              <div className="col-md-4">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="country" maxLength={120} placeholder="country" onKeyDown={handleKeyDown} onChange={(e) => handleInputChange(e)} value={searchValue} />
                  <label for="country">{t('country')}</label>
                </div>
                {showDropdown && (
                  <div className="dropdown sp-select-dropdown">
                    <ul className="dropdown-menu show" style={{ width: '100%' }}>
                      {listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                        <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                      ))}
                      {listOfCountry?.length > 0 && listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="City" maxLength={20} value={city} onChange={(e) => setcity(e.target.value)} placeholder="City" />
                  <label for="City">{t('city')}</label>
                </div>
              </div>
              <div className="col-md-4">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="Postcode" maxLength={9} value={postCode} onChange={(e) => setpostCode(e.target.value)} placeholder="Postcode" />
                  <label for="Postcode">{t('postcode')}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">{t('space')} {t('detail')}</label>
            <div class=" row gy-3">
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" maxLength={40} id="manager" value={spaceManagerName} onChange={(e) => setspaceManagerName(e.target.value)} placeholder="Space Manager" />
                  <label for="manager">{t('manager')} {t('name')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" maxLength={320} id="email" value={spaceEmail} onChange={(e) => setspaceEmail(e.target.value)} placeholder="Email" />
                  <label for="email">{t('email')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" maxLength={13} id="phone" value={spacePhone} onChange={handlePhoneChange} placeholder="Phone" />
                  <label for="phone">{t('phone')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="mobile" maxLength={13} value={spaceMobile} onChange={handleMobileChange} placeholder="Mobile" />
                  <label for="mobile">{t('mobile')}</label>
                </div>
              </div>

            </div>
          </div>
          <div class="mb-3">
            <label for="inputEmail3" class="mb-2 d-block blue-color fw-semibold">{t('uploadimages')}</label>
            <div class="">
              <div className="upload__img-wrap">
                {prev_images.map((image, index) => (
                  <div className="upload__img-box" key={index}>
                    <div className="img-bg">
                      <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt={`Store Image`} className="img-bg-size" />
                      <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                    </div>
                  </div>
                ))}

                {images.map((image, index) => (
                  <div className="upload__img-box" key={index}>
                    <div className="img-bg">
                      <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                      <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                    </div>
                  </div>
                ))}
                <input type="file" class="btn-check" id="btn-check" multiple data-max_length="10" onChange={handleImageUpload} accept=".png, .jpg, .jpeg" />
                <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> {t('addphotos')}</label>
              </div>

            </div>
          </div>
        </div>
        <div className="text-center">
          <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>{t('save')}</button>
        </div>
      </div>


    </>
  )
}

export default AddSpace