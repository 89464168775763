import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Camera } from "lucide-react";
import { completeBusiness, completeProfile, getAllPaymentMethods, getBusiness, getBusinessType, getByBusinessData, getCountry, getProfile, setPaymentMethodForBusiness, updatePassword } from '../../store/slices/userSlice';

const Settings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)

    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [oldPassword, setoldPassword] = useState("");
    const [password, setpassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [phone, setphone] = useState(null);
    const [image, setImage] = useState(null);

    const [listOfBusiness, setlistOfBusiness] = useState(null);
    const [listOfCountry, setlistOfCountry] = useState(null);
    const [country, setcountry] = useState("");

    const [type, setType] = useState("");
    const [roleList, setroleList] = useState(null);
    const [role, setrole] = useState("");
    const [businessname, setbusinessname] = useState("");
    const [load, setload] = useState(false);
    const [listOfMethods, setlistOfMethods] = useState(null)
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
    const [toggle, settoggle] = useState(false);

    useEffect(() => {
        setType(business?.type)
    }, [business])


    const allBusiness = async () => {
        try {
            setload(true);
            const response = await dispatch(getBusinessType()).unwrap();
            setlistOfBusiness(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const allCountry = async () => {
        try {

            const response = await dispatch(getCountry()).unwrap();
            setlistOfCountry(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const updateThePassword = async (e) => {
        try {
            e.preventDefault();

            let payload = {
                existingPassword: oldPassword,
                confirmNewPassword: password,
                newPassword: confirmPassword
            }
            await dispatch(updatePassword(payload)).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    console.log('business', business)

    useEffect(() => {
        let roles = [];
        if (type === 'Coworking Office') {
            roles = ['Founder', 'Owner'];
        } else if (type === 'Gym / Fitness Centers') {
            roles = ['Receptionist', 'Front Desk'];
        } else if (type === 'Healthcare Clinics') {
            roles = ['Operations Manager'];
        } else if (type === 'Training Rooms') {
            roles = ['Marketing'];
        } else if (type === 'Library / Learning Center') {
            roles = ['Accountant'];
        } else if (type === 'Other') {
            roles = ['Team Member'];
        }
        setroleList(roles);
    }, [type]);



    const handleBusinessTypeSelect = (businessType) => {
        setType(businessType);
        setrole("")
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            allBusiness();
            allCountry()
        }
        return () => {
            mount = false;
        };
    }, []);



    const handleCheckboxChange = (data) => {
        if (selectedPaymentMethods.includes(data?.name)) {
            setSelectedPaymentMethods((prevMethods) =>
                prevMethods.filter((method) => method !== data?.name)
            );
        } else {
            setSelectedPaymentMethods((prevMethods) => [...prevMethods, data?.name]);
        }
    };
    console.log(selectedPaymentMethods)



    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const allPaymentMethods = async () => {
        try {
            const response = await dispatch(getAllPaymentMethods()).unwrap();
            setlistOfMethods(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    console.log(role)
    console.log(type)

    const handleSubmit = async () => {
        try {
 


            let payload = {

                // country: country ? country : profile?.location?.country,
                businessName: businessname ? businessname : business.name,
                role: role ? role : business.role,
                type: type ? type : business.type,
                paymentMethods: selectedPaymentMethods ? selectedPaymentMethods : business?.paymentMethods
            }
            await dispatch(completeBusiness(payload)).unwrap();

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const handleProfileSubmit = async () => {
        try {

            const form = new FormData();
            form.append("name", name ? name : profile?.name);
            form.append("phone", phone ? phone : profile?.phone);
            form.append("imageName", image ? image : profile?.imageName);


            await dispatch(completeProfile(form)).unwrap();

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            allPaymentMethods();
            getMyBusiness()
        }
        return () => {
            mount = false;
        };
    }, [])

    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value.length > 0) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };
    const handleOptionClick = (data) => {
        setSearchValue(data?.name);
        setcountry(data);
        setShowDropdown(false);
    };





    useEffect(() => {
        if (business) {
            // const selectedCountry = listOfCountry?.find(data => data?.name === );
            setSearchValue(business?.country);
            setcountry(business?.country);
            setType(business?.type);
            setrole(business?.role);
            setSelectedPaymentMethods(business?.paymentMethods)
        }
    }, [business]);

    return (
        <>
            <div className="sp-add-user-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('settings')}</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src="./assets/images/users-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('settings')}</h2>
                </div>
                <div className="sp-shadow sp-radius-20 white-bg p-4 mb-4">
                    <div className="row mb-4">
                        <div className="col-md-2">
                            <div className="sp-profile-upload">
                                <div class="avatar-upload">
                                    <div class="avatar-edit">
                                        <input onChange={(e) => { setImage(e.target.files[0]) }} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                                        <label for="imageUpload"><Camera width={18} strokeWidth={1} /> {t('upload')}</label>
                                    </div>
                                    <div class="avatar-preview">
                                        <img id="output" src={image ? URL.createObjectURL(image) : (profile?.imageName ? `${process.env.REACT_APP_IMGURL}/${profile?.imageName}` : '')} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 row gy-3 mb-3">
                            <div className="col-md-4">
                                <div class="form-floating sp-input">
                                    <input type="text" class="form-control" id="floatingInput" maxLength={40} placeholder="Full Name"
                                        autocomplete="Full Name" defaultValue={profile?.name} onChange={(e) => setname(e.target.value)} />
                                    <label for="floatingInput">{t('full')} {t('name')}</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div class="form-floating sp-input">
                                    <input type="email" class="form-control" id="floatingInput" maxLength={320} placeholder="Email"
                                        autocomplete="Email" defaultValue={profile?.email} disabled onChange={(e) => setemail(e.target.value)} />
                                    <label for="floatingInput">{t('email')}</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div class="form-floating sp-input">
                                    <input type="tel" class="form-control" id="floatingInput" maxLength={11} placeholder="Contact Number"
                                        defaultValue={profile?.phone} autocomplete="Contact Number" onChange={(e) => setphone(e.target.value)} />
                                    <label for="floatingInput">{t('contactNumber')}</label>
                                </div>
                            </div>
                            {!toggle &&
                                <div className="col-md-12">
                                    <p className="font-size-16 light-blue-color fw-semibold cursor-pointer" onClick={() => settoggle(true)}><u>{t('changePassword')}</u></p>
                                </div>
                            }
                            {toggle && <>
                                <div className="col-md-4">
                                    <div class="form-floating  sp-input">
                                        <input type="password" class="form-control" id="floatingInput" maxLength={50} placeholder="password"
                                            defaultValue={oldPassword} onChange={(e) => setoldPassword(e.target.value)} />
                                        <label for="floatingInput">{t('oldPassword')}</label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="form-floating  sp-input">
                                        <input type="password" class="form-control" id="floatingInput" maxLength={50} placeholder="password"
                                            defaultValue={password} onChange={(e) => setpassword(e.target.value)} />
                                        <label for="floatingInput">{t('newPassword')}</label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="form-floating  sp-input">
                                        <input type="password" class="form-control" id="floatingInput" maxLength={50} placeholder="password"
                                            defaultValue={confirmPassword} onChange={(e) => setconfirmPassword(e.target.value)} />
                                        <label for="floatingInput">{t('confirmPassword')}</label>
                                    </div>
                                </div>
                                <div className="col-md-2 align-self-center"><button class="btn sp-button sp-blue-button" onClick={updateThePassword}>{t('changePassword')}</button></div>
                            </>}
                        </div>
                    </div>
                    <div className="text-center mb-4">
                        <button class="btn sp-button sp-blue-button" onClick={handleProfileSubmit}>{t('save')}</button>
                    </div>

                    {business && <div>
                        <h2 className="font-size-20 titilium-font fw-bold blue-color mb-2">{t('Business')} {t('settings')}</h2>

                        <div className="row">
                            <div className="col-md-6">
                                <div class="form-floating sp-input">
                                    <input type="text" class="form-control" id="floatingInput" placeholder="Event name"
                                        defaultValue={business?.name} autocomplete="buisness name" required onChange={(e) => setbusinessname(e.target.value)} />
                                    <label for="floatingInput">{t('Business')} {t('name')}</label>
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                            <div class="form-floating sp-input">
                                <input type="text" class="form-control" id="country" maxLength={120} placeholder="country" onChange={(e) => handleInputChange(e)} value={searchValue} />
                                <label for="country">Country</label>
                            </div>
                            {showDropdown && (
                                <div className="dropdown sp-select-dropdown">
                                    <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                        {listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                                            <li key={index} onClick={() => handleOptionClick(data)}><a class="dropdown-item" >{data?.name}</a></li>
                                        ))}
                                        {listOfCountry?.length > 0 && listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                                            <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div> */}
                        </div>

                        <div class="mb-3">
                            <label for="inputEmail3" class="col-form-label align-self-center light-blue-color fw-semibold">{t('Business')} {t('type')}</label>
                            <div className="d-flex gap-3 flex-wrap">
                                {load ? new Array(6).fill(null).map((_, index) => (<div style={{ height: '116px', width: '132px' }} className="shimmer rounded"></div>)) : listOfBusiness?.map((bi, i) => (
                                    <>
                                        <input class="btn-check" type="radio" name="type" id={bi?._id}
                                            defaultValue={business?.type}
                                            checked={type?.includes(bi?.name)}
                                            onChange={() => handleBusinessTypeSelect(bi?.name)} key={i} />
                                        <label style={{ width: '132px' }} class="btn sp-check-select d-flex flex-column gap-3 justify-content-center align-items-center" for={bi?._id}>
                                            <img src={`../assets/images/white-${bi.name.replace(/[\/\s]+/g, '-').toLowerCase()}-icon.svg`} alt={bi?.name} className="sp-white-icon" />
                                            <img src={`../assets/images/${bi.name.replace(/[\/\s]+/g, '-').toLowerCase()}-icon.svg`} alt={bi?.name} className="sp-color-icon" />
                                            {bi?.name}
                                        </label>
                                    </>
                                ))}
                            </div>
                        </div>

                        {roleList?.length > 0 &&
                            <div class="mb-3">
                                <label for="inputEmail3" class="col-form-label align-self-center light-blue-color fw-semibold">{t('Business')} {t('role')}</label>
                                <div className="d-flex gap-3 flex-wrap">
                                    {roleList?.map((rl, i) => (
                                        <>
                                            <input class="btn-check" type="radio" name="role" id={`role-${rl}`} checked={role?.includes(rl)} onChange={() => setrole(rl)} key={i} />
                                            <label style={{ width: '120px' }} class="btn sp-check-select d-flex flex-column gap-3 justify-content-center align-items-center" for={`role-${rl}`}>
                                                <img src={`../assets/images/white-${rl.replace(/[\/\s]+/g, '-').toLowerCase()}-icon.svg`} alt={rl} className="sp-white-icon" />
                                                <img src={`../assets/images/${rl.replace(/[\/\s]+/g, '-').toLowerCase()}-icon.svg`} alt={rl} className="sp-color-icon" />
                                                {rl}
                                            </label>
                                        </>
                                    ))}
                                </div>
                            </div>
                        }

                        <h2 className="font-size-16 light-blue-color fw-semibold mb-2">{t('paymentMethod')}</h2>
                        <div className="d-flex gap-3 flex-wrap mb-3">
                            {listOfMethods?.map((data) => (
                                <>
                                    <input class="btn-check" name="payment-check" type="checkbox" id={data?._id} checked={selectedPaymentMethods?.includes(data?.name)} onChange={() => handleCheckboxChange(data)} />
                                    <label className="btn sp-check-select" htmlFor={data?._id}>{data?.name}</label>
                                </>
                            ))
                            }
                        </div>



                        <div className="text-center">
                            <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>{t('save')}</button>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default Settings