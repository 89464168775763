import React, { useEffect, useState } from 'react'

import { allRoles, allSideMenus, createRole, editRole, getBusiness, getByBusinessData, getProfile, getSideBar, getUserRight } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const UserRoles = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sidebar = useSelector(getSideBar)
  const dispatch = useDispatch();
  const business = useSelector(getBusiness)
  const userRights = useSelector(getUserRight)

  const [sideBarData, setsideBarData] = useState();
  const [sideMenus, setsideMenus] = useState();
  const [toggle, settoggle] = useState(false);
  const [roleName, setroleName] = useState("");
  const [selectedActions, setSelectedActions] = useState([]);
  const [listOfRoles, setlistOfRoles] = useState([]);
  const profile = useSelector(getProfile)
  const [load, setload] = useState(false);
  // const [toggle, settoggle] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editRoleName, setEditRoleName] = useState('');
  const [editRoleId, seteditRoleId] = useState('');


  const getMyBusiness = async () => {
    try {
      await dispatch(getByBusinessData()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getAllRoles = async () => {

    try {
      setload(true);
      const response = await dispatch(allRoles(business ? business?._id : profile?.businessId)).unwrap();
      setlistOfRoles(response?.data)
      console.log(response)
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };
  const getAllSideMenus = async () => {
    try {
      const response = await dispatch(allSideMenus()).unwrap();
      setsideMenus(response?.data)
      console.log(response)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  useEffect(() => {
    let mount = true;
    if (mount) {
      getMyBusiness();
      getAllSideMenus()

    }
    return () => {
      mount = false;
    };
  }, [])

  useEffect(() => {
    let mount = true;
    if (mount) {
      getAllRoles();

    }
    return () => {
      mount = false;
    };
  }, [business])

  const mainMenuItems = sideMenus?.filter((item) => item.parent === null);




  const updateSelectedActions = (spaceId, action) => {
    setSelectedActions((prevSelectedActions) => {
      let updatedActions = [...prevSelectedActions];
      const spaceIndex = updatedActions.findIndex((item) => item.id === spaceId);

      if (action === 'all') {
        // If the 'All' button is clicked, set all actions to 1
        if (spaceIndex === -1) {
          updatedActions = [
            ...updatedActions,
            { id: spaceId, view: 1, create: 1, edit: 1, delete: 1 },
          ];
        } else {
          updatedActions[spaceIndex] = { id: spaceId, view: 1, create: 1, edit: 1, delete: 1 };
        }
      } else {
        // Handle individual actions as before
        if (spaceIndex === -1) {
          updatedActions = [
            ...updatedActions,
            { id: spaceId, view: 1, create: 0, edit: 0, delete: 0 },
          ];
          const newSpaceIndex = updatedActions.findIndex((item) => item.id === spaceId);
          updatedActions[newSpaceIndex][action] = 1;
        } else {
          const updatedSpace = { ...updatedActions[spaceIndex] };
          updatedSpace[action] = updatedSpace[action] === 0 ? 1 : 0;
          updatedActions[spaceIndex] = updatedSpace;

          if (
            updatedSpace.view === 0 &&
            updatedSpace.create === 0 &&
            updatedSpace.edit === 0 &&
            updatedSpace.delete === 0
          ) {
            updatedActions = updatedActions.filter((item) => item.id !== spaceId);
          }
        }
      }

      return updatedActions;
    });
  };

  const handleCheckboxChange = (spaceId, action) => {
    updateSelectedActions(spaceId, action);
  };


  console.log("selectedActions", selectedActions)

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let payload = {
        businessId: business ? business?._id : profile?.businessId,
        roles: selectedActions,
        roleName
      }

      await dispatch(createRole(payload)).unwrap();
      setroleName("")
      setSelectedActions([])
      getAllRoles()

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };




  const handleEditClick = (data) => {
    setEditRoleName(data?.roleName);
    setShowEditForm(true);
    setSelectedActions(data?.roles)
    seteditRoleId(data?._id)
  };


  const handleEditSubmit = async (e) => {
    setShowEditForm(false);
    e.preventDefault();
    try {
      let payload = {
        id: editRoleId,
        roles: selectedActions,
        roleName: editRoleName
      }

      await dispatch(editRole(payload)).unwrap();
      setroleName("")
      setSelectedActions([])
      getAllRoles()

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  return (
    <>
      <div className="sp-user-role-page pb-5 px-md-5 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
            <li class="breadcrumb-item"><a>{t('menu')}</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/teams")}>{t('teams')}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{t('teamRoles')}</li>
          </ol>
        </nav>

        <div className="row gy-3 mb-4 align-items-center">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <img height="32" src="./assets/images/users-icon-sp.svg" className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('teamRoles')}</h2>
          </div>
          <div className="col-md-6 order-md-2 order-3">
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            {userRights?.Teams?.create == 1 || userRights == null ? !showEditForm && (<button class="btn sp-button sp-blue-button" onClick={() => settoggle(true)} >{t('addteamRole')}</button>) : <></>}
          </div>
        </div>

        <div className="sp-shadow sp-radius-20 white-bg mb-4">
          <div class="table-responsive">
            <table class="table sp-table table-borderless">
              <thead>
                <tr>
                  <th scope="col">{t('name')}</th>
                  <th scope="col">{t('assignedTo')}</th>
                  <th scope="col">{t('createdOn')}</th>
                  <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '60px' }} className='w-100 shimmer'></div></td></tr>)) : listOfRoles?.map((data, i) => (
                  <tr>
                    <td style={{ 'verticalAlign': 'middle' }}>{data?.roleName}</td>
                    <td style={{ 'verticalAlign': 'middle' }}>{data?.assignRoles}</td>
                    <td style={{ 'verticalAlign': 'middle' }}>{moment(data?.createdAt).format("MMMM Do YYYY")}</td>
                    <td style={{ 'verticalAlign': 'middle' }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className={`sp-action-icons`}><Pencil width="18" onClick={() => handleEditClick(data)} /></div>
                        <div className={`sp-action-icons`}><X width="18" /></div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {toggle && !showEditForm &&
          <div className="sp-shadow sp-radius-20 white-bg">
            <div className="px-3 py-3">
              <h2 className="font-size-20 titilium-font fw-bold blue-color mb-3">{t('addUserRole')}</h2>
              <div class="form-floating mb-3 sp-input">
                <input type="text" class="form-control" id="floatingInput" maxLength={40} placeholder="Role Name" value={roleName} onChange={(e) => setroleName(e.target.value)}
                  autocomplete="Role Name" />
                <label for="floatingInput">{t('roleName')}</label>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table sp-table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">{t('accessto')}</th>
                    <th scope="col">{t('all')}</th>
                    <th scope="col">{t('view')}</th>
                    <th scope="col">{t('create')}</th>
                    <th scope="col">{t('edit')}</th>
                    <th scope="col">{t('delete')}</th>
                  </tr>
                </thead>
                <tbody>
                  {mainMenuItems?.map((menuItem, i) => {
                    const hasChildItems = sideBarData?.some((item) => item.parent === menuItem._id);
                    return (
                      <tr>
                        <td>{menuItem.name}
                          {hasChildItems && (
                            <ul className="mb-2">
                              {sideBarData
                                .filter((item) => item.parent === menuItem._id)
                                .map((subMenuItem) => (
                                  <li key={subMenuItem._id}>
                                    {subMenuItem.name}
                                  </li>
                                ))
                              }
                            </ul>
                          )}</td>
                        <td style={{ 'verticalAlign': 'middle' }}><u onClick={() => handleCheckboxChange(menuItem._id, 'all')}>All</u></td>
                        <td style={{ 'verticalAlign': 'middle' }}>
                          <div className="sp-login-checkbox">
                            <input style={{ "--bs-form-check-bg": "#e7e7e7" }} class="form-check-input" type="checkbox" id={`${menuItem._id}-view`}
                              checked={selectedActions.some((item) => item.id === menuItem._id && item.view === 1)}
                              onChange={() => handleCheckboxChange(menuItem._id, 'view')} />
                          </div>
                        </td>
                        <td style={{ 'verticalAlign': 'middle' }}>
                          <div className="sp-login-checkbox">
                            <input style={{ "--bs-form-check-bg": "#e7e7e7" }} class="form-check-input" type="checkbox" id={`${menuItem._id}-create`}
                              checked={selectedActions.some((item) => item.id === menuItem._id && item.create === 1)}
                              onChange={() => handleCheckboxChange(menuItem._id, 'create')} />
                          </div>
                        </td>
                        <td style={{ 'verticalAlign': 'middle' }}>
                          <div className="sp-login-checkbox">
                            <input style={{ "--bs-form-check-bg": "#e7e7e7" }} class="form-check-input" type="checkbox" id={`${menuItem._id}-edit`}
                              checked={selectedActions.some((item) => item.id === menuItem._id && item.edit === 1)}
                              onChange={() => handleCheckboxChange(menuItem._id, 'edit')} />
                          </div>
                        </td>
                        <td>
                          <div className="sp-login-checkbox">
                            <input style={{ "--bs-form-check-bg": "#e7e7e7" }} class="form-check-input" type="checkbox" id={`${menuItem._id}-delete`}
                              checked={selectedActions.some((item) => item.id === menuItem._id && item.delete === 1)}
                              onChange={() => handleCheckboxChange(menuItem._id, 'delete')} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-center gap-3 py-5">
              {/* <button class="btn sp-btn py-2 btn-danger">Delete</button> */}
              <button class="btn sp-button btn-danger" onClick={() => settoggle(false)}>{t('cancel')}</button>
              <button class="btn sp-button sp-blue-button" onClick={handleSubmit} >{t('save')}</button>
            </div>

          </div>}

        {showEditForm && (
          <div className="sp-shadow sp-radius-20 white-bg">
            <div className="px-3 py-3">
              <h2 className="font-size-20 titilium-font fw-bold blue-color mb-3">Edit User Role</h2>
              <div className="form-floating mb-3 sp-input">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  maxLength={40}
                  placeholder="Role Name"
                  value={editRoleName}
                  onChange={(e) => setEditRoleName(e.target.value)}
                  autoComplete="Role Name"
                />
                <label htmlFor="floatingInput">{t('roleName')}</label>
              </div>
              <div className="table-responsive">
                <table className="table sp-table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">{t('accessto')}</th>
                      <th scope="col">{t('all')}</th>
                      <th scope="col">{t('view')}</th>
                      <th scope="col">{t('create')}</th>
                      <th scope="col">{t('edit')}</th>
                      <th scope="col">{t('delete')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mainMenuItems?.map((menuItem, i) => {
                      const hasChildItems = sideBarData?.some((item) => item.parent === menuItem._id);
                      return (
                        <tr key={i}>
                          <td>{menuItem.name}
                            {hasChildItems && (
                              <ul className="mb-2">
                                {sideBarData
                                  .filter((item) => item.parent === menuItem._id)
                                  .map((subMenuItem) => (
                                    <li key={subMenuItem._id}>
                                      {subMenuItem.name}
                                    </li>
                                  ))
                                }
                              </ul>
                            )}</td>
                          <td style={{ 'verticalAlign': 'middle' }}><u onClick={() => handleCheckboxChange(menuItem._id, 'all')}>All</u></td>
                          <td style={{ 'verticalAlign': 'middle' }}>
                            <div className="sp-login-checkbox">
                              <input
                                style={{ "--bs-form-check-bg": "#e7e7e7" }}
                                className="form-check-input"
                                type="checkbox"
                                id={`${menuItem._id}-view`}
                                checked={selectedActions.some((item) => item.id === menuItem._id && item.view === 1)}
                                onChange={() => handleCheckboxChange(menuItem._id, 'view')}
                              />
                            </div>
                          </td>
                          <td style={{ 'verticalAlign': 'middle' }}>
                            <div className="sp-login-checkbox">
                              <input
                                style={{ "--bs-form-check-bg": "#e7e7e7" }}
                                className="form-check-input"
                                type="checkbox"
                                id={`${menuItem._id}-create`}
                                checked={selectedActions.some((item) => item.id === menuItem._id && item.create === 1)}
                                onChange={() => handleCheckboxChange(menuItem._id, 'create')}
                              />
                            </div>
                          </td>
                          <td style={{ 'verticalAlign': 'middle' }}>
                            <div className="sp-login-checkbox">
                              <input
                                style={{ "--bs-form-check-bg": "#e7e7e7" }}
                                className="form-check-input"
                                type="checkbox"
                                id={`${menuItem._id}-edit`}
                                checked={selectedActions.some((item) => item.id === menuItem._id && item.edit === 1)}
                                onChange={() => handleCheckboxChange(menuItem._id, 'edit')}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="sp-login-checkbox">
                              <input
                                style={{ "--bs-form-check-bg": "#e7e7e7" }}
                                className="form-check-input"
                                type="checkbox"
                                id={`${menuItem._id}-delete`}
                                checked={selectedActions.some((item) => item.id === menuItem._id && item.delete === 1)}
                                onChange={() => handleCheckboxChange(menuItem._id, 'delete')}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="d-flex justify-content-center gap-3 py-5">
              <button className="btn sp-button btn-danger" onClick={() => setShowEditForm(false)}>Cancel</button>
              <button className="btn sp-button sp-blue-button" onClick={handleEditSubmit}>Save</button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default UserRoles