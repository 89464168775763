import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpacesForDropDown, addDesk, getBusiness, getByBusinessData, getPlans, getProfile, getSpacesForDropDownPrivate, editDesk, getDeskDetailForEdit } from "../../store/slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Camera } from "lucide-react";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const EditDesk = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const location = useLocation()
    const navigate = useNavigate()

    const [images, setImages] = useState([]);
    const [deskDetail, setdeskDetail] = useState();
    const [name, setname] = useState("");
    const [description, setdescription] = useState("");
    const [deskCount, setdeskCount] = useState("");
    const [deskSize, setdeskSize] = useState("");
    const [presentDesk, setpresentDesk] = useState("");

    const [type, settype] = useState("");
    const [getAllSpaces, setgetAllSpaces] = useState([]);
    const [getAllPlans, setgetAllPlans] = useState([]);
    const [spaceId, setspaceId] = useState("");
    const [plans, setplans] = useState([]);
    const [load, setload] = useState(false);
    const [prev_images, setprev_images] = useState(deskDetail?.image);





    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    const handleCheckboxChangeForLocation = (event) => {
        const { value, checked } = event.target;
        setplans((prevInterestedServices) => {
            if (checked) {

                return [...prevInterestedServices, value];
            } else {

                return prevInterestedServices.filter((item) => item !== value);
            }
        });
    };


    const [allPlans, setallPlans] = useState([
        {
            name: '',
            description: '',
            autorenewal: 0,
            multiplequantity: 0,
            pricing: [
                { type: 'Weekly', price: 0 },
                { type: 'Monthly', price: 0 },
                { type: 'Yearly', price: 0 },
            ],
        },
    ]);


    const handleInputChange = (index, key, value) => {
        const newPlans = [...allPlans];
        newPlans[index][key] = value;
        setallPlans(newPlans);
    };

    const handlePricingInputChange = (index, pricingIndex, key, value) => {
        const newAllPlans = [...allPlans];
        newAllPlans[index].pricing[pricingIndex][key] = parseFloat(value); // Convert price to a number
        setallPlans(newAllPlans);
    };

    const handleCheckboxChange = (index, key, checked) => {
        const newAllPlans = [...allPlans];
        newAllPlans[index][key] = checked ? 1 : 0;
        setallPlans(newAllPlans);
    };

    const handleAddPlan = () => {
        setallPlans((prevPlans) => [
            ...prevPlans,
            {
                name: '',
                description: '',
                autorenewal: 0,
                multiplequantity: 0,
                deposit: 0,
                pricing: [
                    { type: 'Weekly', price: 0 },
                    { type: 'Monthly', price: 0 },
                    { type: 'Yearly', price: 0 },
                ],
            },
        ]);
    };
    const handleRemovePlan = (index) => {
        setallPlans((prevPlans) => {
            const newPlans = [...prevPlans];
            newPlans.splice(index, 1);
            return newPlans;
        });
    };

    const handleUpdatePlans = () => {
        if (deskDetail?.planDetail) {
            setallPlans(deskDetail.planDetail);
        }
    };




    const getSpaces = async () => {
        try {
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
            setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const deskDetailForEdit = async () => {
        try {

            const response = await dispatch(getDeskDetailForEdit(location?.state?._id)).unwrap();
            setdeskDetail(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {

        deskDetailForEdit()
        handleUpdatePlans();

    }, [location?.state]);

    useEffect(() => {
        setprev_images(deskDetail?.image)
        handleUpdatePlans();
    }, [deskDetail])


    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness();
            // handleUpdatePlans();
        }
        return () => {
            mount = false;
        };
    }, [])


    useEffect(() => {
        let mount = true;
        if (mount) {

            getSpaces();
        }
        return () => {
            mount = false;
        };
    }, [business, profile])






    const handleImageUpload = (event) => {
        const selectedImages = event.target.files;
        const imagesArray = [];


        for (let i = 0; i < selectedImages.length; i++) {
            imagesArray.push(selectedImages[i]);
        }

        setImages(imagesArray);
    };

    const handleImageRemovePrev = (e, index) => {
        e.preventDefault();
        const updatedImages = [...prev_images];
        updatedImages.splice(index, 1);
        setprev_images(updatedImages);
    };

    const handleImageRemove = (e, index) => {
        e.preventDefault();
        const updatedImages = [...images];
        updatedImages.splice(index, 1);

        setImages(updatedImages);
    };


    console.log(deskDetail)
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setload(true);
            console.log("xx", prev_images)

            const imagesArray = []
            const existingImagesAsFiles = prev_images?.map((image) => (
                typeof image === "string" ? new File([], image) : image
            ));
            for (let i = 0; i < existingImagesAsFiles.length; i++) {
                imagesArray.push(existingImagesAsFiles[i]);
            }
            console.log("imagesArray", imagesArray)


            console.log("hello2", images)

            const newArray = [...imagesArray, ...images];

            if (!name) {
                toast.error('Name is required');
                setload(false);
                return;
            }
            if (!newArray.length == 1) {
                toast.error('Images are required');
                setload(false);
                return;
            }

            const form = new FormData();
            form.append("name", name ? name : deskDetail.name);
            form.append("businessId", profile?.businessId ? profile?.businessId : business._id);
            form.append("deskId", deskDetail._id);
            form.append("presentDesk", presentDesk);
            form.append("description", description ? description : deskDetail.description);
            form.append("deskSize", deskSize ? deskSize : deskDetail.deskSize);
            form.append("plans", JSON.stringify(allPlans ? allPlans : deskDetail.planDetail));
            newArray?.forEach((item) => {
                form.append("deskImage", item);
            });

            // let payload = {
            //     name: name ? name : deskDetail.name,
            //     deskId: deskDetail._id,
            //     description: description ? description : deskDetail.description,
            //     deskSize: deskSize ? deskSize : deskDetail.deskSize,
            //     plans: plans ? plans : deskDetail.plans
            // }

            await dispatch(editDesk(form)).unwrap();
            setname("")
            setdescription("")
            setdeskCount("")
            setdeskSize("")
            setspaceId("")
            setImages([])
            setplans([])
            navigate("/desks")
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        if (deskDetail) {
            settype(deskDetail?.type || "");
            setspaceId(deskDetail?.spaceId || "");
            setname(deskDetail?.name || "");
            settype(deskDetail?.type || "");
            setdeskCount(deskDetail?.deskCount || "");
            setpresentDesk(deskDetail?.presentDesk || "");
            setdeskSize(deskDetail?.deskSize || "");
        }
    }, [deskDetail]);

    console.log("desk detail", deskDetail);

    // const types = ["Hot Desk", "Dedicated Desk", "Shared Desk"]
    const types = ["Hot Desk", "Dedicated Desk"]

    return (
        <>
            {load ? <Spinner /> : <></>}
            <div className="sp-add-location-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>Menu</a></li>
                        <li class="breadcrumb-item"><a>Spaces</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/desks")}>Desk</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Edit Desk</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/spaces-icon-sp.svg`} className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">Edit Desk</h2>
                </div>
                <div className="mb-4">
                    <div className="row gy-3 mb-4">
                        <div className="col-md-6">
                            <div class="form-floating sp-input mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Name" defaultValue={name} onChange={(e) => setname(e.target.value)} />
                                <label for="name">Name*</label>
                            </div>
                            <div class="form-floating sp-textarea mb-3">
                                <textarea class="form-control" placeholder="Leave a comment here" id="Description" maxLength={300} style={{ height: '100px' }} defaultValue={deskDetail?.description} onChange={(e) => setdescription(e.target.value)}></textarea>
                                <label for="Description">Description</label>
                            </div>
                            <div class="form-floating sp-input mb-3">
                                <input type="number" class="form-control" id="Count-Desks" placeholder="Count / Desks" value={parseFloat(deskCount)} onChange={(event) => {
                                    let newValue = parseFloat(event.target.value);
                                    if (newValue < 0 || isNaN(newValue)) {
                                        newValue = "";
                                    }
                                    setdeskCount(newValue);
                                }} />
                                <label for="Count-Desks">{type == "Hot Desk" ? "No Of Bookings" : "Count / Desks"}</label>
                            </div>

                            {type == "Hot Desk" && <div class="form-floating sp-input mb-3">
                                <input type="number" class="form-control" id="Count-Desks" maxLength={4} placeholder="Count / Desks" value={parseFloat(presentDesk)} onChange={(event) => {

                                    let newValue = parseFloat(event.target.value);
                                    if (newValue < 0 || isNaN(newValue)) {
                                        newValue = "";
                                    }
                                    setpresentDesk(newValue);
                                }} />
                                <label for="Count-Desks">Present Desk</label>
                            </div>}
                            <div class="form-floating sp-input mb-3">
                                <input type="number" class="form-control" id="Desks Size / Sq ft" placeholder="Desks-Size" value={parseFloat(deskSize)} onChange={(event) => {
                                    let newValue = parseFloat(event.target.value);
                                    if (newValue < 0 || isNaN(newValue)) {
                                        newValue = "";
                                    }
                                    setdeskSize(newValue);
                                }} />
                                <label for="Desks-Size">Desks Size / Sq ft</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="mb-3">
                                <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('location')}</label>
                                <div className="d-flex gap-3 flex-wrap">
                                    {getAllSpaces?.map((data, i) => (
                                        <>
                                            <input class="btn-check" disabled type="checkbox" defaultValue={spaceId} id={data?._id} key={i} checked={spaceId?.includes(data?._id)} onClick={() => setspaceId(data?._id)} />
                                            <label class="btn sp-check-select" for={data?._id}>{data.name}</label>
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('type')}</label>
                                <div className="d-flex gap-3 flex-wrap">
                                    {types.map((ty, i) => (
                                        <>
                                            <input class="btn-check" disabled type="radio" name="type" id={`type-${ty}`} defaultValue={type} checked={type?.includes(ty)} onChange={() => settype(ty)} key={i} />
                                            <label style={{ width: '120px' }} class="btn sp-check-select d-flex flex-column gap-3 justify-content-center align-items-center" for={`type-${ty}`}>
                                                <img src={`../assets/images/white-${ty.replace(/[\/\s]+/g, '-').toLowerCase()}-icon-sp.svg`} alt={ty} className="sp-white-icon" />
                                                <img src={`../assets/images/${ty.replace(/[\/\s]+/g, '-').toLowerCase()}-icon-sp.svg`} alt={ty} className="sp-color-icon" />
                                                {ty}
                                            </label>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="upload__img-wrap mb-4">
                        {prev_images?.map((image, index) => (
                            <div className="upload__img-box" key={index}>
                                <div className="img-bg">
                                    <img src={`${process.env.REACT_APP_IMGURL}/${image}`} alt={`Store Image`} className="img-bg-size" />
                                    <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                                </div>
                            </div>
                        ))}

                        {images?.map((image, index) => (
                            <div className="upload__img-box" key={index}>
                                <div className="img-bg">
                                    {typeof (image) == "string" ? <>
                                        <img src={` ${process.env.REACT_APP_IMGURL}/${image}`} alt={`Store Image`} className="img-bg-size" />
                                    </> : <>
                                        <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                                    </>}
                                    <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                                </div>
                            </div>
                        ))}
                        <input type="file" class="btn-check" id="btn-check" multiple data-max_length="10" onChange={handleImageUpload} accept=".png, .jpg, .jpeg" />
                        <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> Add Photos</label>
                    </div>
                    <p className="font-size-24 titilium-font fw-bold blue-color mb-2">Add Plan</p>
                    {allPlans?.map((plan, index) => (
                        <div key={index}>
                            <div className="d-flex justify-content-between">
                                <p className="font-size-16 titilium-font fw-bold blue-color mb-2">Plan # {index + 1}</p>
                                <p className="font-size-16 titilium-font fw-bold blue-color mb-2 cursor-pointer" onClick={handleRemovePlan}>Remove</p>
                            </div>
                            <div className="row gy-3">
                                <div className="col-md-6">
                                    <div class="form-floating sp-input">
                                        <input type="text" class="form-control" id="name" placeholder="Name" value={plan.name} onChange={(e) => handleInputChange(index, 'name', e.target.value)} />
                                        <label for="name">Name*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="form-floating sp-input">
                                        <input type="text" class="form-control" id="Deposit" placeholder="Deposit" value={plan.deposit} onChange={(e) => {
                                            const inputValue = parseInt(e.target.value, 10);
                                            if (!isNaN(inputValue) && inputValue >= 0) {
                                                handleInputChange(index, 'deposit', inputValue);
                                            }
                                            else {
                                                handleInputChange(index, 'deposit', null);
                                            }
                                        }} />
                                        <label for="Deposit">Deposit</label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div class="form-floating sp-textarea ">
                                        <textarea class="form-control" placeholder="Leave a comment here" id="Description" maxLength={300} style={{ height: '100px' }} value={plan.description} onChange={(e) => handleInputChange(index, 'description', e.target.value)}></textarea>
                                        <label for="Description">Description</label>
                                    </div>
                                </div>
                                <div className="col-md-12 d-flex flex-wrap gap-3">
                                    <input type="checkbox" class="btn-check" checked={plan.autorenewal === 1} onChange={(e) => handleCheckboxChange(index, 'autorenewal', e.target.checked)} id={`${index + 1}-Auto-Renewal`} />
                                    <label className="btn sp-check-select" for={`${index + 1}-Auto-Renewal`}>Auto Renewal</label>

                                    <input type="checkbox" class="btn-check" checked={plan.multiplequantity === 1} onChange={(e) => handleCheckboxChange(index, 'multiplequantity', e.target.checked)} id={`${index + 1}-Multiple-Quantity`} />
                                    <label className="btn sp-check-select" for={`${index + 1}-Multiple-Quantity`}>Multiple Quantity</label>
                                </div>
                                {plan.pricing.map((pricingItem, pricingIndex) => (
                                    <div key={pricingIndex} className="col-md-4">
                                        <div class="form-floating sp-input mb-3">
                                            <input type="number" class="form-control" id={pricingItem.type} placeholder={pricingItem.type} value={pricingItem.price.toString()} onChange={(e) => {
                                                const inputValue = parseInt(e.target.value, 10);
                                                if (!isNaN(inputValue) && inputValue >= 0) {
                                                    handlePricingInputChange(index, pricingIndex, 'price', inputValue)
                                                }
                                                else {
                                                    handlePricingInputChange(index, pricingIndex, 'price', 0)
                                                }

                                            }} />
                                            <label for={pricingItem.type}>{pricingItem.type}</label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <button class="btn sp-button sp-blue-button py-2" onClick={handleAddPlan}>Add More</button>
                </div>

                <div className="text-center">
                    <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </>
    )
}


export default EditDesk