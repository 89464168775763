import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { addCommentOnTicket, closeTicket, getBusiness, getByBusinessData, getEmployeeDependsOnSpace, getProfile, reopenTicket, resolveTicket, ticketDetail } from '../../store/slices/userSlice'
import moment from 'moment'
import { CalendarClock, Download, MapPin } from 'lucide-react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import toast from 'react-hot-toast'

const TicketDetail = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const dispatch = useDispatch();

    const [ticket, setticket] = useState(null);
    const [assignUserId, setassignUserId] = useState(null);
    const [allUsers, setallUsers] = useState([]);
    const [message, setmessage] = useState("");
    const [isOpen, setisOpen] = useState(false)


    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const getEmployee = async () => {
        try {
            let payload = {
                businessId: profile?.businessId ? profile?.businessId : business._id,
                spaceId: ticket?.spaceId

            }

            const response = await dispatch(getEmployeeDependsOnSpace(payload)).unwrap();
            setallUsers(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const resolveTheTicket = async () => {
        try {

            await dispatch(resolveTicket(ticket._id)).unwrap();
            getTicketDetail()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const reopenTheTicket = async () => {
        try {

            await dispatch(reopenTicket(ticket._id)).unwrap();
            getTicketDetail()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const closeTheTicket = async () => {
        try {

            await dispatch(closeTicket(ticket._id)).unwrap();
            getTicketDetail()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getTicketDetail = async () => {
        try {

            const response = await dispatch(ticketDetail(location.state._id)).unwrap();
            setticket(response?.data[0])

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const handleClick = async () => {
        try {
            let payload = {
                assignUserId,
                ticketId: ticket._id,
                message
            }


         

            if (assignUserId == null && message == "") {
                toast.error("First add comment")
            } else {

                await dispatch(addCommentOnTicket(payload)).unwrap();
                // setallUsers(response?.data)
                setassignUserId(null)
                setmessage("")
                getTicketDetail()
                getEmployee()

            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness();


        }
        return () => {
            mount = false;
        };
    }, [])

    useEffect(() => {
        let mount = true;
        if (mount) {
            getTicketDetail()
        }
        return () => {
            mount = false;
        };
    }, [location?.state])

    useEffect(() => {
        let mount = true;
        if (mount) {
            getEmployee()

        }
        return () => {
            mount = false;
        };
    }, [business, profile, ticket])


    console.log(ticket)


    return (

        <>
            <div className="sp-technical-support-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>Menu</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/support-tickets")}>Support Tickets</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Ticket Detail</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/it-icon-sp.svg`} className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">Ticket Detail</h2>
                </div>
                <div className="mb-4 sp-shadow rounded-3 p-5">
                    <div className="row mb-1">
                        <div className="col-md-6 d-flex align-items-center gap-3">
                            <p className="font-size-18 light-blue-color fw-semibold mb-0"><u>{ticket?.ticketNumber}</u></p>
                            <p className="font-size-18 light-blue-color fw-medium mb-0">{ticket?.category?.name}</p>
                        </div>
                        <div className="col-md-6 d-flex align-items-center gap-3 justify-content-end">
                            <div style={{ gap: '3px' }} className="d-flex">
                                <span className={`${ticket?.priority == "low" ? "blue-bg" : ticket?.priority == "Medium" ? "blue-bg" : "blue-bg"}`} style={{ height: '10px', width: '20px' }}></span>
                                <span className={`${ticket?.priority == "low" ? "grey-bg" : ticket?.priority == "Medium" ? "blue-bg" : "blue-bg"}`} style={{ height: '10px', width: '20px' }}></span>
                                <span className={`${ticket?.priority == "low" ? "grey-bg" : ticket?.priority == "Medium" ? "grey-bg" : "blue-bg"}`} style={{ height: '10px', width: '20px' }}></span>
                            </div>
                            <span className={`btn sp-button py-2 ${ticket?.status == "Open" ? "sp-green-button" : "sp-red-button"}`}>{ticket?.status}</span>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="d-flex align-items-center gap-2 mb-2">
                                <MapPin size={20} className="black-color" />
                                <p className="font-size-16 black-color fw-semibold mb-0">{ticket?.location?.name}</p>
                            </div>
                            <p className="font-size-16 grey-color mb-0">{ticket?.description}</p>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <div className="d-flex align-items-center gap-1">
                                <CalendarClock className='black-color' />
                                <div>
                                    <p className="font-size-16 grey-color mb-0">Created On {moment(ticket?.createdAt).format("LL")}</p>
                                    <p className="font-size-16 grey-color mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="blue-bg px-4 py-3 d-inline-block rounded-3">
                                <p className="font-size-14 color-white mb-0 fw-semibold">Ticket Generated By {ticket?.userId?.name}</p>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <div className="d-flex align-items-center gap-1 cursor-pointer" onClick={() => setisOpen(true)}>
                                <Download className='black-color' />
                                <p className="font-size-16 black-color mb-0"><u>{ticket?.image}</u></p>
                            </div>
                            {isOpen && (
                                <Lightbox
                                    mainSrc={`${process.env.REACT_APP_IMGURL}/${ticket?.image}`}
                                    onCloseRequest={() => setisOpen(false)}
                                    onError={(error) => console.error('Lightbox Error:', error)}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="sp-ticket-container">
                    {ticket?.comments?.map((data, i) => (<>
                        {data?.assignUser && data?.message ? <>
                            <div className='sp-ticket-single-1'>
                                <p className="font-size-16 light-blue-color mb-0 fw-semibold">{data?.commentedUserId?.name}</p>
                                <p className="font-size-16 color-black mb-0">Message: {data?.message}</p>
                                <p className="font-size-16 color-black mb-0 fw-semibold">Ticket Assign to {data?.assignUser?.name}</p>
                                <span className="ticket-date font-size-12 black-white mb-0">{moment(data?.date).format("MMMM Do YYYY, h:mm a")}</span>
                            </div>
                        </> : data?.assignUser ? <>
                            <div style={{ backgroundColor: 'rgb(69 132 232 / 64%)' }} className="px-5 py-2 d-inline-block rounded-3">
                                <p className="font-size-16 color-black mb-0 fw-semibold">Ticket Assign to {data?.assignUser?.name}</p>
                                <p className="font-size-12 black-white mb-0">{moment(data?.date).format("MMMM Do YYYY, h:mm a")}</p>
                            </div>
                        </> : data?.message ? <>
                            <div className='sp-ticket-single-2'>
                                <p className="font-size-16 light-blue-color mb-0 fw-semibold">{data?.commentedUserId?.name}</p>
                                <p className="font-size-16 black-color mb-0">Message: {data?.message}</p>
                                <span className="ticket-date font-size-12 black-white mb-0">{moment(data?.date).format("MMMM Do YYYY, h:mm a")}</span>
                            </div>
                        </> : <></>}</>
                    ))}
                </div>

                {ticket?.status == "Open" || ticket?.status == "Resolved" ?
                    <div>

                        <div class="form-floating sp-textarea mb-3">
                            <textarea class="form-control" placeholder="Leave a comment here" id="Description" style={{ height: '100px' }} value={message} onChange={(e) => setmessage(e.target.value)}></textarea>
                            <label for="Description">Description</label>
                        </div>
                        <div class="dropdown sp-select-dropdown mb-3">
                            <a class="dropdown-toggle text-capitalize" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {assignUserId ? allUsers.find(data => data._id === assignUserId)?.name : "Assign To Someone"}
                            </a>
                            <ul class="dropdown-menu">
                                <li onClick={() => setassignUserId("")}><a className="dropdown-item">Assign To Someone</a></li>
                                {allUsers?.map((data, i) => (
                                    <li key={i} onClick={() => setassignUserId(data?._id)}><a class="dropdown-item" >{data?.name}</a></li>
                                ))}
                            </ul>
                        </div>
                        <div className="d-flex gap-2">
                            <button class="btn sp-button sp-blue-button" onClick={handleClick}>{assignUserId ? "Assign User" : "Add Comment"}</button>
                            {profile?._id == ticket?.userId._id ? ticket?.status == "Open" ? <button class="btn sp-button sp-blue-button" disabled>Close</button> : ticket.status == "Resolved" ? <button class="btn sp-button sp-blue-button" onClick={closeTheTicket}>Close</button> : <></> : <></>}
                            {profile?._id == ticket?.assignedUser ? ticket.status == "Resolved" ? <button class="btn sp-button sp-blue-button" onClick={reopenTheTicket} >Reopen</button> : <button class="btn sp-button sp-blue-button" onClick={resolveTheTicket} >Resolved</button> : <></>}
                        </div>

                    </div> :
                    <></>}

            </div>





        </>
    )
}

export default TicketDetail
