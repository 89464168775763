import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusiness, getByBusinessData, getSpacesForDropDownPrivate, getProfile, editRoom, newsDetail, editNews } from "../../store/slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Camera } from "lucide-react";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";

const EditNews = () => {

    const dispatch = useDispatch();
    const business = useSelector(getBusiness)
    const navigate = useNavigate()
    const profile = useSelector(getProfile)
    const location = useLocation()

    const [getAllSpaces, setgetAllSpaces] = useState([]);
    const [name, setname] = useState("");
    const [description, setdescription] = useState("");
    const [detail, setdetail] = useState(location?.state);
    const [spaces, setspaces] = useState([]);
    const [images, setImages] = useState([]);
    const [prev_images, setprev_images] = useState(detail?.attachment);
    const [type, settype] = useState("");
    const [load, setload] = useState(false);


    useEffect(() => {
        setspaces(detail?.spaces)
        settype(detail?.type)
        setname(detail?.name)
    }, [detail])

    // const handleCheckboxChangeForLocation = (event) => {
    //     const { value, checked } = event.target;
    //     setspaces((prevInterestedServices) => {
    //         if (checked) {

    //             return [...prevInterestedServices, value];
    //         } else {

    //             return prevInterestedServices.filter((item) => item !== value);
    //         }
    //     });
    // };
    const handleCheckboxChangeForLocation = (event) => {
        if (event && event.target && event.target.id) {
            const value = event.target.id;
            const checked = event.target.checked;
    
            setspaces((prevInterestedServices) => {
                if (checked) {
                    return [...prevInterestedServices, value];
                } else {
                    return prevInterestedServices.filter((item) => item !== value);
                }
            });
        }
    }
    

    const getSpaces = async () => {
        try {
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
            setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    const getdetail = async () => {
        try {
            const response = await dispatch(newsDetail(location.state._id)).unwrap();
            console.log(response)
            setdetail(response?.data)


        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    console.log(detail)
    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getSpaces();
            getMyBusiness()
        }
        return () => {
            mount = false;
        };
    }, [])

    useEffect(() => {

        getdetail()


    }, [location.state]);


    useEffect(() => {
        let mount = true;
        if (mount) {

            getSpaces();
        }
        return () => {
            mount = false;
        };
    }, [business, profile])

    console.log(spaces)
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {


            setload(true);
            const imagesArray = []
            const existingImagesAsFiles = prev_images?.map((image) => (
                typeof image === "string" ? new File([], image) : image
            ));
            for (let i = 0; i < existingImagesAsFiles.length; i++) {
                imagesArray.push(existingImagesAsFiles[i]);
            }
            console.log("imagesArray", imagesArray)


            console.log("hello2", images)

            const newArray = [...imagesArray, ...images];

            if (!name) {
                toast.error('News Title is required');
                setload(false);
                return;
            }
            if (!spaces == 1) {
                toast.error('Locations are required');
                setload(false);
                return;
            }
            if (!type) {
                toast.error('News Type is required');
                setload(false);
                return;
            }

            const form = new FormData();
            form.append("name", name ? name : detail?.name);
            form.append("newsId", detail?._id);
            form.append("description", description ? description : detail?.description);
            form.append("type", type ? type : detail?.type);
            form.append("spaces", JSON.stringify(spaces ? spaces : detail?.spaces));
            newArray?.forEach((item) => {

                form.append("newsImage", item);

            });
            await dispatch(editNews(form)).unwrap();
            setdescription("")
            setImages([])
            setname("")
            navigate("/news")
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const handleImageUpload = (event) => {
        const selectedImages = event.target.files;
        const imagesArray = [];


        for (let i = 0; i < selectedImages.length; i++) {
            imagesArray.push(selectedImages[i]);
        }

        setImages(imagesArray);
    };



    const handleImageRemovePrev = (e, index) => {
        e.preventDefault();
        const updatedImages = [...prev_images];
        updatedImages.splice(index, 1);
        setprev_images(updatedImages);
    };

    const handleImageRemove = (e, index) => {
        e.preventDefault();
        const updatedImages = [...images];
        updatedImages.splice(index, 1);

        setImages(updatedImages);
    };

    const types = [
        {
            id: 1,
            name: 'Trending',
        },
        {
            id: 2,
            name: 'Announcements',
        },
        {
            id: 3,
            name: 'News',
        },
    ]

    return (
        <>
        {load ? <Spinner /> : <></>}
                <div className="sp-add-location-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>Menu</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/news")}>News</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{location.pathname === "/news/news-detail" ? "News Details" : "Edit News"}</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/news-icon-sp.svg`} className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{location.pathname === "/news/news-detail" ? "News Details" : "Edit News"}</h2>
                </div>
                

                <div className="mb-4">
                <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">News Title</label>
                    <div class="form-floating sp-input mb-3">
                        <input disabled={location.pathname === "/news/news-detail"} type="text" class="form-control" id="News-Title" defaultValue={name} onChange={(e) => setname(e.target.value)} placeholder="News Title" />
                        <label for="News-Title">News Title</label>
                    </div>

                    <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">Location</label>
                    <div className="d-flex gap-3 flex-wrap mb-3">
                        {getAllSpaces?.map((data, i) => (
                            <>
                                <input disabled={location.pathname === "/news/news-detail"} class="btn-check" type="checkbox" defaultValue={detail?.spaces} id={data?._id} key={i} checked={spaces?.includes(data?._id)} onChange={(e) => handleCheckboxChangeForLocation(e)} />
                                <label class="btn sp-check-select" for={data?._id}>{data?.name}</label>
                            </>
                        ))}
                    </div>
                    <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">Type</label>
                    <div className="d-flex gap-3 flex-wrap mb-3">
                        {types?.map((data, i) => (
                            <>
                                <input disabled={location.pathname === "/news/news-detail"} class="btn-check" type="radio" name="type" id={`type-${data?.id}`} defaultValue={detail?.type} key={i} checked={type?.includes(data?.name)} onChange={() => settype(data.name)} />
                                <label class="btn sp-check-select" for={`type-${data?.id}`}>{data.name}</label>
                            </>
                        ))}
                    </div>

                    <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">Description</label>
                    <div class="form-floating sp-textarea mb-3">
                        <textarea disabled={location.pathname === "/news/news-detail"} class="form-control" placeholder="Leave a comment here" id="Description" maxLength={300} style={{height: '100px'}} defaultValue={detail?.description} onChange={(e) => setdescription(e.target.value)}></textarea>
                        <label for="Description">Description</label>
                    </div>  

                    
                    <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">Attachments</label>
                    <div className="upload__img-wrap">
                    {prev_images?.map((image, index) => (
                            <div className="upload__img-box" key={index}>
                                <div className="img-bg">
                                    <img src={`${process.env.REACT_APP_IMGURL}/${image}`} alt={`Store Image`} className="img-bg-size" />
                                    <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                                </div>
                            </div>
                        ))}
                        {images?.map((image, index) => (

                            <div className="upload__img-box" key={index}>
                                <div className="img-bg">
                                    { typeof (image) == "string" ? <>
                                            <img src={` ${process.env.REACT_APP_IMGURL}/${image}`} alt={`Store Image`} className="img-bg-size" />
                                        </> : <>
                                            <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                                        </>}
                                    <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                                </div>
                            </div>
                        ))}
                        <input disabled={location.pathname === "/news/news-detail"} type="file" class="btn-check" id="btn-check" multiple data-max_length="10" onChange={handleImageUpload} accept=".png, .jpg, .jpeg" />
                        <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> Add Photos</label>
                    </div>

                        
                    
                    
                </div>
                {location.pathname !== "/news/news-detail" && (
                <div className="text-center">
                    <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>Save</button>
                </div>
                )}
            </div>
        </>
    )
}

export default EditNews