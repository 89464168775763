import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { allRoles, getBusiness, getByBusinessData, getProfile, getUserRight, userEmployeeList } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Users = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const [listOfRoles, setlistOfRoles] = useState([]);
    const [load, setload] = useState(false);
    const [toggle, settoggle] = useState(false);
    const userRights = useSelector(getUserRight)

    const [listOfUser, setlistOfUser] = useState([]);



    const [nameFilter, setNameFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [locationFilter, setlocationFilter] = useState('');
    const [selectedRole, setSelectedRole] = useState([]);


    const applyFilter = () => {
        const filteredResult = listOfUser?.filter(item => {
            const userName = item?.name.toLowerCase();
            const location = item?.email?.toLowerCase();
            const role = item?.role

            return (
                (nameFilter === '' || userName?.includes(nameFilter?.toLowerCase())) &&
                (selectedRole?.length === 0 || selectedRole.includes(role)) &&
                (locationFilter === '' || location?.includes(locationFilter?.toLowerCase()))
            );
        });

        setFilteredData(filteredResult);
        settoggle(false);
    };



    const handleStatusChange = (status) => {

   
        if (selectedRole?.includes(status)) {
            setSelectedRole(prevStatuses => prevStatuses.filter(s => s !== status));
        } else {
            setSelectedRole(prevStatuses => [...prevStatuses, status]);
        }
    };


    const resetFilter = () => {
        setNameFilter('');
        setlocationFilter('')
        setSearchValue('')
        setSearchValueEmail('')

        setFilteredData(listOfUser)
        settoggle(false);
    };

    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getUserList = async () => {
        try {
            setload(true);
            const response = await dispatch(userEmployeeList(business ? business?._id : profile?.businessId)).unwrap();
            setlistOfUser(response?.data);
            setFilteredData(response?.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };
    console.log(listOfUser)
    const getAllRoles = async () => {
        try {
            setload(true);
            const response = await dispatch(allRoles(business ? business?._id : profile?.businessId)).unwrap();
            setlistOfRoles(response?.data)
            setload(false);

        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness()
        }
        return () => {
            mount = false;
        };
    }, [])

    console.log(business)

    useEffect(() => {
        let mount = true;
        if (mount) {
            if (business) {
                getUserList()
                getAllRoles()
            }
        }
        return () => {
            mount = false;
        };
    }, [business])

    useEffect(() => {
        let mount = true;
        if (mount) {
            if (profile) {
                getUserList()
                getAllRoles()
            }
        }
        return () => {
            mount = false;
        };
    }, [profile])


    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [searchValueEmail, setSearchValueEmail] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value.length > 0) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };
    const handleOptionClick = (data) => {
        setSearchValue(data?.name);
        setNameFilter(data?.name);
        setShowDropdown(false);
    };

    const handleInputChange1 = (e) => {
        const value = e.target.value;
        setSearchValueEmail(value);
        if (value.length > 0) {
            setShowDropdown1(true);
        } else {
            setShowDropdown1(false);
        }
    };
    const handleOptionClick1 = (data) => {
        setSearchValueEmail(data?.email);
        setlocationFilter(data?.email);
        setShowDropdown1(false);
    };


    return (
        <>
            <div className="sp-users-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('teams')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                    <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                        <img height="32" src="./assets/images/users-icon-sp.svg" className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('teams')}</h2>
                    </div>
                    <div className="col-md-6 order-md-2 order-3">
                        <div class="dropdown sp-search-dropdown">
                            <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
                                <span>{t('filterAndSearch')}</span>
                                <span>
                                    {toggle ? <X /> : <Search />}
                                </span>
                            </a>

                            <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputname" class="col-sm-2 col-form-label">{t('name')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={(e) => handleInputChange(e)} value={searchValue} placeholder="Filter by Name" class="form-control" id="inputname" />
                                        {showDropdown && (
                                            <div className="dropdown">
                                                <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                    {listOfUser?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                                                        <li key={index} onClick={() => handleOptionClick(data)}><a class="dropdown-item" >{data?.name}</a></li>
                                                    ))}
                                                    {listOfUser?.length > 0 && listOfUser?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                                                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputemail" class="col-sm-2 col-form-label">{t('email')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={(e) => handleInputChange1(e)} value={searchValueEmail} placeholder="Filter by Email" class="form-control" id="inputemail" />
                                        {showDropdown1 && (
                                            <div className="dropdown">
                                                <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                    {listOfUser?.filter((data) => data?.email?.toLowerCase()?.includes(searchValueEmail?.toLowerCase()))?.map((data, index) => (
                                                        <li key={index} onClick={() => handleOptionClick1(data)}><a class="dropdown-item" >{data?.email}</a></li>
                                                    ))}
                                                    {listOfUser?.length > 0 && listOfUser?.filter((data) => data?.email?.toLowerCase()?.includes(searchValueEmail?.toLowerCase()))?.length === 0 && (
                                                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputname" class="col-sm-2 col-form-label">{t('status')}</label>
                                    <div class="col-sm-10 d-flex gap-2 flex-wrap">
                                        {
                                            listOfRoles?.map((data, index) => (
                                                <>

                                                    <input type="checkbox" class="btn-check" id={index} value={data?.roleName} checked={selectedRole.includes(data?.roleName)} onChange={() => handleStatusChange(data?.roleName)} />
                                                    <label class="btn sp-button sp-green-button" for={index}>{data?.roleName}</label>
                                                </>
                                            ))
                                        }




                                    </div>
                                </div>
                                <div className="d-flex gap-2 justify-content-end">
                                    <button class="btn sp-button sp-blue-button" onClick={applyFilter}>{t('search')}</button>
                                    <button class="btn sp-button sp-blue-button" onClick={resetFilter}>{t('reset')}</button>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                        {userRights?.Teams?.create == 1 || userRights == null ? <button class="btn sp-button sp-blue-button" onClick={() => navigate("/teams/add-team")}>{t('addTeam')}</button> : <></>}
                    </div>
                </div>
                <div className="row mb-4 gy-3 gx-3">
                    {load ? new Array(12).fill(null).map((_, index) => (<div className="col-md-2"><div style={{ height: '81px' }} className="shimmer rounded w-100"></div></div>)) : listOfRoles?.map((data, i) => (
                        <div className="col-md-2" key={i}>
                            <div className="px-3 py-4 sp-shadow rounded-3 white-bg">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="font-size-20 titilium-font blue-color mb-0">{data?.roleName}</p>
                                    <h3 className="font-size-28 titilium-font fw-bold blue-color mb-0">{data?.assignRoles}</h3>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="sp-shadow sp-radius-20 white-bg">
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: "80px" }} scope="col">{t('name')}</th>
                                    <th scope="col">{t('email')}</th>
                                    <th scope="col">{t('phone')}</th>
                                    <th scope="col">{t('role')}</th>
                                    <th scope="col">{t('location')}</th>
                                    <th scope="col">{t('lastLogin')}</th>
                                    <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '60px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((data, i) => (
                                    <tr>
                                        <td>
                                            <div style={{ paddingLeft: '10px' }} className="d-flex flex-row align-items-center gap-3">
                                                {data?.imageName ? <img class="rounded-circle sp-user-profile" alt="avatar" src={`${process.env.REACT_APP_IMGURL}/${data?.imageName}`} /> : <img class="rounded-circle sp-user-profile" src="./assets/images/dummy.png" />}
                                                <span>{data?.name}</span>
                                            </div>
                                        </td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.email}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.phone}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.assignRole?.roleName}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data.isAllSpaces ? <>All Locations</> : data?.assignSpaces?.map((item, i) => (<span key={i}>{item?.name}, </span>))}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.lastLogin ? moment(data?.lastLogin).format("MMMM Do YYYY HH:mm") : "Not Login Yet"}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                                {userRights?.Teams?.edit == 1 || userRights == null ? <div className={`sp-action-icons`} onClick={() => navigate("/teams/edit-team", { state: data })}><Pencil width="18" /></div> : <></>}
                                                {userRights?.Teams?.delete == 1 || userRights == null ? <div className={`sp-action-icons`}><X width="18" /></div> : <></>}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users