import React, { useEffect, useState } from "react";
import { getByBusinessData, getSpacesForDropDown, getBusiness, addLead, getCountry, allRoles, addUser, getProfile, getSpacesForDropDownPrivate, allSupportCategory } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Camera } from "lucide-react";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";




const AddUser = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)

    const [getAllSpaces, setgetAllSpaces] = useState([]);
    const [spaces, setspaces] = useState([]);
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState(null);
    const [listOfRoles, setlistOfRoles] = useState([]);
    const [selectedRoleId, setSelectedRoleId] = useState("");
    const [selectedRoleName, setSelectedRoleName] = useState("");
    const [image, setImage] = useState(null);
    const [isAllSpaces, setisAllSpaces] = useState(0);
    const [selectAllClicked, setSelectAllClicked] = useState(false);
    const [load, setload] = useState(false);
    const [listOfCategory, setlistOfCategory] = useState([]);
    const [categoryId, setcategoryId] = useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setload(true);
            if (email) {
                const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
                if (!isValidEmail) {
                    toast.error('Please enter a valid email address!');
                    setload(false);
                    return;
                }
            }
            if (!name) {
                toast.error('Name is required');
                setload(false);
                return;
            }
            if (!phone) {
                toast.error('Phone is required');
                setload(false);
                return;
            }
            if (!email) {
                toast.error('Email is required');
                setload(false);
                return;
            }

            const form = new FormData();
            form.append("name", name);
            form.append("phone", phone);
            form.append("email", email);
            form.append("isAllSpaces", isAllSpaces);
            form.append("imageName", image);
            form.append("assignedSpaces", JSON.stringify(spaces));
            form.append("role", selectedRoleName);
            form.append("roleId", selectedRoleId);
            form.append("category", categoryId ? JSON.stringify(categoryId?._id) : null);
            form.append("businessId", business ? business._id : profile?.businessId);

            await dispatch(addUser(form)).unwrap();

            setname("")
            setemail("")
            setphone("")
            setImage(null)
            setspaces([])
            setgetAllSpaces([])
            setSelectedRoleId("")
            setSelectedRoleName("")
            setload(false);
            navigate("/teams")

        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getSpaces = async () => {
        try {
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
            setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const handleChange = (e, data) => {

        setSelectedRoleId(data._id)
        setSelectedRoleName(data.roleName)
    };
    console.log("selectedRoleName", selectedRoleName)
    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getAllRoles = async () => {
        try {
            const response = await dispatch(allRoles(business ? business._id : profile.businessId)).unwrap();
            setlistOfRoles(response?.data)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getAllRoles();

        }
        return () => {
            mount = false;
        };
    }, [business])


    const handleCheckboxChangeForLocation = (text) => {
        const updatedCheckboxes = spaces.includes(text)
            ? spaces.filter((item) => item !== text)
            : [...spaces, text];

        setspaces(updatedCheckboxes);

        // Check if all spaces are manually selected
        const allSpacesManuallySelected = updatedCheckboxes.length === getAllSpaces.length;

        // If select all was clicked manually, don't set isAllSpaces to 1
        if (selectAllClicked && !allSpacesManuallySelected) {
            setSelectAllClicked(false);
            setisAllSpaces(0);
        } else {
            // Check if any space is manually deselected
            if (!updatedCheckboxes.includes(text)) {
                // If any space is manually deselected, set isAllSpaces to 0
                setisAllSpaces(0);
            }
        }
    };

    console.log("isAllSpaces", isAllSpaces)
    console.log("assignedSpaces", spaces)
    // const handleSelectAll = () => {
    //     // Select all spaces
    //     setspaces(getAllSpaces.map(data => data._id));
    //     setSelectAllClicked(true);
    // };

    const handleSelectAll = () => {
        if (isAllSpaces === 1) {
            // Deselect all spaces
            setspaces([]);
            setisAllSpaces(0);
        } else {
            // Select all spaces
            setspaces(getAllSpaces.map(data => data._id));
            setisAllSpaces(1);
        }
    };

    // useEffect(() => {
    //     const isAllSpacesSelected = spaces?.length === getAllSpaces?.length;
    //     if (selectAllClicked) {
    //         setisAllSpaces(isAllSpacesSelected ? 1 : 0);
    //     }
    // }, [spaces, getAllSpaces, selectAllClicked]);

    const getAllCategory = async () => {
        try {
            setload(true);
            const response = await dispatch(allSupportCategory(business ? business?._id : profile?.businessId)).unwrap();
            setlistOfCategory(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getSpaces();
            getAllCategory()
        }
        return () => {
            mount = false;
        };
    }, [business, profile])

    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness()

        }
        return () => {
            mount = false;
        };
    }, [])

    const handlePhoneChange = (e) => {
        let input = e.target.value;

        if (!input) {
            setphone('');
            return;
        }
        input = input.replace(/[^0-9]/g, '');
        const formattedPhone = input.length > 0 ? `+${input}` : '+';
        setphone(formattedPhone);
    };

    return (
        <>
            {load ? <Spinner /> : <></>}
            <div className="sp-add-user-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/teams")}>{t('teams')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('add')} {t('new')} {t('team')}</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/users-icon-sp.svg`} className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('add')} {t('team')}</h2>
                </div>
                <div className="mb-4">
                    <div className="row mb-4">
                        <div className="col-md-2">
                            <div className="sp-profile-upload">
                                <div class="avatar-upload">
                                    <div class="avatar-edit">
                                        <input onChange={(e) => { setImage(e.target.files[0]) }} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                                        <label for="imageUpload"><Camera width={18} strokeWidth={1} /> {t('upload')}</label>
                                    </div>
                                    <div class="avatar-preview">
                                        {image && <img id="output" src={URL.createObjectURL(image)} alt="" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 row gy-3 mb-3">
                            <div className="col-md-12">
                                <div class="form-floating sp-input">
                                    <input type="text" class="form-control" id="floatingInput" maxLength={40} placeholder="Full Name"
                                        autocomplete="Full Name" value={name} onChange={(e) => setname(e.target.value)} />
                                    <label for="floatingInput">{t('full')} {t('name')}</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="form-floating sp-input">
                                    <input type="email" class="form-control" id="floatingInput" maxLength={320} placeholder="Email"
                                        autocomplete="Email" value={email} onChange={(e) => setemail(e.target.value)} />
                                    <label for="floatingInput">{t('email')}</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="form-floating mb-3 sp-input">
                                    <input type="tel" class="form-control" id="floatingInput" placeholder="Contact Number"
                                        value={phone} autocomplete="Contact Number" maxLength={13} onChange={handlePhoneChange} />
                                    <label for="floatingInput">{t('contactNumber')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <p className="font-size-16 blue-color fw-semibold mb-2">{t('locations')}</p>
                        <div className="d-flex flex-wrap gap-3 mb-3">
                            {getAllSpaces?.map((data, i) => (<>
                                <input className="btn-check" type="checkbox" name="location" value={spaces} id={data?._id} key={i} checked={spaces.includes(data?._id)} onChange={() => handleCheckboxChangeForLocation(data._id)} />
                                <label className="btn sp-check-select" for={data?._id}>{data?.name}</label>
                            </>))}
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="location" id="all"  checked={isAllSpaces === 1} onChange={handleSelectAll} />
                            <label class="form-check-label" for="all" >
                                {t('Access to current and future locations')}
                            </label>
                        </div>
                    </div>
                    {selectedRoleName !== "Community Manager" && <>
                        <div className="mb-3">
                            <p className="font-size-16 blue-color fw-semibold mb-2">{t('category')}</p>
                            <div className="d-flex flex-wrap gap-3">
                                {listOfCategory?.map((data, i) => (<>
                                    <input className="btn-check" type="radio" name="category" value={categoryId} id={data?._id} key={i} onChange={() => setcategoryId(data)} />
                                    <label className="btn sp-check-select" for={data?._id}>{data?.name}</label>
                                </>))}
                            </div>
                        </div></>}

                    <div className="mb-3">
                        <p className="font-size-16 blue-color fw-semibold mb-2">{t('userRoles')}</p>
                        <div className="d-flex flex-wrap gap-3">
                            {((profile.role === 'Admin' || profile.role === 'SpaceOwner') ? listOfRoles :
                                listOfRoles?.filter(data => data.roleName !== 'Admin'))
                                ?.map((data) => (<>
                                    <input
                                        className="btn-check"
                                        type="radio"
                                        checked={selectedRoleId === data._id}
                                        onChange={(e) => handleChange(e, data)}
                                        name="user-role"
                                        id={`${data.roleName}`}
                                    />
                                    <label className="btn sp-check-select" for={`${data.roleName}`}>
                                        {data.roleName}
                                        <ul className="mb-0">
                                            {data?.roleItem?.map((item) => (
                                                <li>
                                                    {item.name} (
                                                    {item.view === 1 && 'View'},
                                                    {item.create === 1 && 'Create'},
                                                    {item.edit === 1 && 'Edit'},
                                                    {item.delete === 1 && 'Delete'}
                                                    )
                                                </li>
                                            ))}
                                        </ul>
                                    </label></>
                                ))}
                        </div>
                    </div>
                </div>

                <div className="text-center">
                    <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>{t('save')}</button>
                </div>
            </div>
        </>
    )
}

export default AddUser