import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { getBusiness, getSpacesForDropDown, getCustomersForDropDown, addBooking, getByBusinessData, getRoomsAndDesksForDropDown, getPlansForBookingForDropDown, getProfile, getSpacesForDropDownPrivate, availableSlots, checkDeskAvaibility, checkHotDeskAvaibility, getDeskCountSlice } from "../../store/slices/userSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
const AddBooking = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const [spaceId, setspaceId] = useState('');
    const [userId, setuserId] = useState('');
    const [handleMonth, sethandleMonth] = useState();
    const [isEndToggle, setisEndToggle] = useState(false);
    const [getAllSpaces, setgetAllSpaces] = useState([]);
    const [getAllCustomers, setgetAllCustomers] = useState([]);
    const [getAllRoomsAndDesks, setgetAllRoomsAndDesks] = useState([]);
    const [cart, setcart] = useState([]);
    const [cartJson, setcartJson] = useState([]);
    const [bookJson, setbookJson] = useState([]);
    const [roomAndDeskId, setroomAndDeskId] = useState('');
    const [planId, setplanId] = useState("");
    const [planData, setplanData] = useState();
    const [selectedRoomDeskData, setSelectedRoomDeskData] = useState();
    const [count, setcount] = useState(0);
    const [subtotal, setsubtotal] = useState(0);
    const [depositTotal, setdepositTotal] = useState(0);
    const [discountedTotal, setdiscountedTotal] = useState(0);
    const [discount, setdiscount] = useState(0);
    const [tax, settax] = useState(0);
    const [netTotal, setnetTotal] = useState(0);
    const [taxedAmount, settaxedAmount] = useState(0);
    const [taxvalue, settaxvalue] = useState();
    const [dueAmount, setdueAmount] = useState(0);
    const [totalAmount, settotalAmount] = useState(0);
    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");
    const [disableEndDate, setdisableEndDate] = useState("");
    const [dueDate, setdueDate] = useState("");
    const [paymentMethods, setpaymentMethods] = useState([]);
    const [toggleEndTime, settoggleEndTime] = useState(0);
    const [pricing, setpricing] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [userRequestedDeskCount, setuserRequestedDeskCount] = useState(null);
    const [selectHours, setselectHours] = useState(null);
    const [planDetails, setplanDetails] = useState(null);
    const [plans, setplans] = useState(null);
    const [selectedRange, setSelectedRange] = useState({ from: null, to: null });
    const [startTime, setstartTime] = useState(null);
    const [endTime, setendTime] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [enableDaysForSingleCalender, setenableDaysForSingleCalender] = useState(null);
    const [allSlots, setallSlots] = useState([]);
    const [getBookedData, setgetBookedData] = useState()
    const [startDeskTime, setstartDeskTime] = useState(null);
    const [endDeskTime, setendDeskTime] = useState(null);
    const [totalCount, settotalCount] = useState(0)
    const [load, setload] = useState(false);
    const [threeMonth, setthreeMonth] = useState(new Date());

    useEffect(() => {
        // Function to add three months to the current date
        const addThreeMonths = (date) => {
            const newDate = new Date(date);
            newDate.setMonth(newDate.getMonth() + 3);
            return newDate;
        };

        // Set the date after three months in the state
        setthreeMonth(addThreeMonths(new Date()));
    }, []);


    useEffect(() => {
        const nextWeekSameDay = new Date(startDate);
        nextWeekSameDay.setDate(nextWeekSameDay.getDate() - 1);
        setdueDate(moment(nextWeekSameDay).format("YYYY-MM-DD"));

    }, [startDate]);

    useEffect(() => {
        // Get the current date in the format 'YYYY-MM-DD'
        const currentDate = new Date().toISOString().split('T')[0];

        // Set the minimum date for the input
        document.getElementById('due-date').min = currentDate;
    }, []); // Run this effect only once when the component mounts



    const handleSelectChange = (selectedId) => {
        // const selectedId = event.target.value;
        const selectedRoomDeskData = getAllRoomsAndDesks.find((data) => data?._id === selectedId);
        // setgetAllRoomsAndDesks((prevData) => prevData.filter((item) => item._id !== selectedRoomDeskData._id));
        setSelectedRoomDeskData(selectedRoomDeskData);
        setroomAndDeskId(selectedId);
        settax(null)
        setsubtotal(null)
        settotalAmount(null)
        setdiscount(0)
        setstartDate("")
        setendDate("")
        setdisableEndDate("")
        setisEndToggle(false)
        setselectHours(0)

    };


    const getDeskCounts = async () => {
        try {
            const response = await dispatch(getDeskCountSlice(roomAndDeskId)).unwrap();
            settotalCount(response?.count)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        getDeskCounts()
    }, [roomAndDeskId])


    const handleCheckboxChange = (text) => {
        const updatedCheckboxes = paymentMethods.includes(text)
            ? paymentMethods.filter((item) => item !== text)
            : [...paymentMethods, text];

        setpaymentMethods(updatedCheckboxes);
    };

    const updateCartJson = (deskCount) => {
        // Assuming planDetails and roomDeskId are accessible in this scope
        const updatedCartJson = [
            {
                planData: planData, // replace planData with the actual state or variable
                pricing: pricing, // replace pricing with the actual state or variable
                [selectedRoomDeskData.behave === "Room" ? "roomId" : "deskCategoryId"]: roomAndDeskId,
                count: deskCount,
            },
        ];

        setcartJson(updatedCartJson);
    };

    const handlePlan = (data) => {

        setpricing(data?.pricingType)
        setplanData(data?.planName)
        setcartJson([{ planData: data.planName, pricing: data.pricingType, [selectedRoomDeskData.behave === "Room" ? "roomId" : selectedRoomDeskData.behave === "Virtual Office" ? "virtualOfficeId" : "deskCategoryId"]: roomAndDeskId, ...(selectedRoomDeskData.behave === "Desk" ? { count: userRequestedDeskCount ? userRequestedDeskCount : 1 } : null) }])
        setdiscount(0)
        settax(0)
        settaxvalue("Select Tax Amount")
        setisEndToggle(false)
        setendDate(null)
        setdisableEndDate("")
        sethandleMonth(null)
    }



    const handleTimeSlots = (data) => {
        setstartTime(data?.startTime)
        setendTime(data?.endTime)
    }

    useEffect(() => {
        setpricing(null)
        setplanData(null)
        setcartJson(null)
        setuserRequestedDeskCount(null)

        const combinedPlans = [];
        planDetails?.planDetail?.forEach((plan) => {
            plan.pricing.forEach((pricingOption) => {
                const combinedPlan = {
                    planName: plan,
                    pricingType: pricingOption,
                };
                combinedPlans.push(combinedPlan);
            });
        });

        setroomAndDeskId(selectedRoomDeskData?._id)
        setplans(combinedPlans)
        setSelectedRange({})
    }, [planDetails])


    const addToCart = async (e) => {
        e.preventDefault();

        try {
            setload(true);
            if (planData) {


                if (planDetails?.type === "Private Room" || planDetails?.type === "Executive Cabin") {

                    let payload = {
                        userId: userId,
                        cart: cartJson,
                        dueDate,
                        spaceId: planDetails?.spaceDetail?._id,
                        paymentType: pricing?.type,
                        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
                        depositAmount: depositTotal,
                        totalAmount: netTotal,
                        discount,
                        tax,
                        paymentMethods: paymentMethods,
                        subtotal,
                        businessId: planDetails?.businessId,
                        paymentType: pricing?.type,
                        dueAmount: depositTotal + netTotal
                    }


                    const response = await dispatch(addBooking(payload)).unwrap();
                    navigate("/bookings/booking-detail", { state: response?.data })


                } else if (planDetails?.behave == "Desk") {
                    let payload = {
                        userId: userId,
                        cart: cartJson,
                        spaceId: planDetails?.spaceDetail?._id,
                        paymentType: pricing?.type,
                        // startDate: selectedRange?.from ? moment(selectedRange?.from).format("YYYY-MM-DD") : null,
                        // endDate: selectedRange?.to ? moment(selectedRange?.to).format("YYYY-MM-DD") : null,
                        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
                        startTime: startDeskTime ? startDeskTime : null,
                        endTime: endDeskTime ? endDeskTime : null,
                        depositAmount: depositTotal,
                        totalAmount: netTotal,
                        dueDate,
                        discount,
                        paymentMethods: paymentMethods,
                        tax,
                        subtotal: subtotal,
                        businessId: planDetails?.businessId,
                        paymentType: pricing?.type,
                        dueAmount: depositTotal + (netTotal),
                    }


                    if (!userRequestedDeskCount) {
                        toast.error("Please select Desks")
                    } else {


                        const response = await dispatch(addBooking(payload)).unwrap();
                        navigate("/bookings/booking-detail", { state: response?.data })
                    }
                } else if (planDetails?.behave == "Virtual Office") {
                    let payload = {
                        userId: userId,
                        cart: cartJson,
                        spaceId: planDetails?.spaceDetail?._id,
                        paymentType: pricing?.type,
                        // startDate: selectedRange?.from ? moment(selectedRange?.from).format("YYYY-MM-DD") : null,
                        // endDate: selectedRange?.to ? moment(selectedRange?.to).format("YYYY-MM-DD") : null,
                        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
                        depositAmount: depositTotal,
                        totalAmount: netTotal,
                        discount,
                        dueDate,
                        paymentMethods: paymentMethods,
                        tax,
                        subtotal: subtotal,
                        businessId: planDetails?.businessId,
                        paymentType: pricing?.type,
                        dueAmount: depositTotal + (netTotal),
                    }

                    const response = await dispatch(addBooking(payload)).unwrap();
                    navigate("/bookings/booking-detail", { state: response?.data })
                } else {
                    let payload = {
                        userId: userId,
                        cart: cartJson,
                        spaceId: planDetails?.spaceDetail?._id,
                        paymentType: pricing?.type,
                        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                        endDate: moment(startDate).format("YYYY-MM-DD"),
                        startTime: moment(startTime, 'hh:mm A').format('HH:mm'),
                        endTime: moment(endTime, 'hh:mm A').format('HH:mm'),
                        depositAmount: depositTotal,
                        totalAmount: netTotal,
                        discount,
                        paymentMethods: paymentMethods,
                        tax,
                        dueDate,
                        subtotal: subtotal,
                        bookingHours: selectHours,
                        businessId: planDetails?.businessId,
                        paymentType: pricing?.type,
                        dueAmount: depositTotal + (netTotal),
                    }

                    if (allSlots && !startTime) {
                        toast.error("Please select a slot")
                    } else {
                        const response = await dispatch(addBooking(payload)).unwrap();
                        navigate("/bookings/booking-detail", { state: response?.data })

                    }
                }
            } else {
                toast.error("Please select a plan")
            }
            setload(false);
        } catch (error) {
            setload(false);
            console.log(error);
        }

    };

    useEffect(() => {
        sethandleMonth(startDate)

    }, [startDate])
    useEffect(() => {
        sethandleMonth(startDate)
    }, [pricing])

    // For Default Month
    const handleToggle = () => {

        setisEndToggle(!isEndToggle);

        if (pricing?.type == "Yearly") {
            const nextYearSameMonth = new Date(handleMonth);
            nextYearSameMonth.setFullYear(nextYearSameMonth.getFullYear() + 1);
            sethandleMonth(nextYearSameMonth);
        } else if (pricing?.type == "Monthly") {
            const nextMonthSameDay = new Date(handleMonth);
            nextMonthSameDay.setMonth(nextMonthSameDay.getMonth() + 1);
            sethandleMonth(nextMonthSameDay);
        } else if (pricing?.type == "Weekly") {
            const nextWeekSameDay = new Date(handleMonth);
            nextWeekSameDay.setDate(nextWeekSameDay.getDate() + 7);
            sethandleMonth(nextWeekSameDay);
        }
    };




    // For Default Date
    useEffect(() => {
        const addCustomDate = (date, duration) => {
            const newDate = new Date(date);

            switch (duration?.type) {
                case 'Weekly':
                    newDate.setDate(newDate.getDate() + 7);
                    break;
                case 'Monthly':
                    newDate.setMonth(newDate.getMonth() + 1);
                    break;
                case 'Yearly':
                    newDate.setMonth(newDate.getMonth() + 12);
                    break;
                default:
                    break;
            }

            return newDate;
        };

        setendDate(addCustomDate(startDate, pricing));
        setdisableEndDate(addCustomDate(startDate, pricing));

    }, [startDate]);

    // Effect for handling changes in pricing
    useEffect(() => {
        // You can handle other logic related to pricing here
        const addCustomDate = (date, duration) => {
            const newDate = new Date(date);

            switch (duration?.type) {
                case 'Weekly':
                    newDate.setDate(newDate.getDate() + 7);
                    break;
                case 'Monthly':
                    newDate.setMonth(newDate.getMonth() + 1);
                    break;
                case 'Yearly':
                    newDate.setMonth(newDate.getMonth() + 12);
                    break;
                default:
                    break;
            }

            return newDate;
        };

        setendDate(addCustomDate(startDate, pricing));
        setdisableEndDate(addCustomDate(startDate, pricing));
    }, [pricing]);


    const handleRadioChange = (index) => {
        setuserRequestedDeskCount(index + 1);
        updateCartJson(index + 1);
    };

    console.log("business", business)
    const getSpaces = async () => {
        try {
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
            setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getCustomers = async () => {
        try {

            const response = await dispatch(getCustomersForDropDown({ businessId: business ? business?._id : profile?.businessId })).unwrap();
            console.log(response?.data)
            setgetAllCustomers(response?.data)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const getRoomsAndDesks = async () => {
        try {
            setgetAllRoomsAndDesks([])
            const response = await dispatch(getRoomsAndDesksForDropDown(spaceId)).unwrap();
            setgetAllRoomsAndDesks(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getPlansForBooking = async () => {
        try {
            setplans()
            const response = await dispatch(getPlansForBookingForDropDown({ id: selectedRoomDeskData?._id, type: selectedRoomDeskData?.behave })).unwrap();
            setgetBookedData(response?.data[0]?.bookedDates?.map(booking => ({
                start: new Date(booking.start).setHours(0, 0, 0, 0),
                end: new Date(booking.end).setHours(0, 0, 0, 0)
            })));
            setenableDaysForSingleCalender(response?.data[0]?.spaceDetail?.days)
            setplanDetails(response?.data[0])
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {

            getCustomers()
        }
        return () => {
            mount = false;
        };
    }, [business])

    useEffect(() => {
        let mount = true;
        if (mount) {
            getSpaces();

        }
        return () => {
            mount = false;
        };
    }, [business, profile])

    useEffect(() => {
        if (spaceId) {
            getRoomsAndDesks()
        }
    }, [spaceId])

    useEffect(() => {
        if (roomAndDeskId) {
            getPlansForBooking()
        }
    }, [roomAndDeskId])



    const calculateSubtotal = () => {
        let totalSubtotal = 0;
        let depositTotal = 0;

        for (const item of cartJson ? cartJson : []) {
            if (item?.planData && item?.pricing) {
                // For the first object
                if (item.count) {

                    const { count } = item;
                    const { price } = item.pricing;
                    const { deposit } = item.planData;
                    const subtotal1 = count * price;
                    const depositTotal1 = deposit

                    totalSubtotal += subtotal1;
                    depositTotal += depositTotal1
                }
                if (!item.count) {
                    if (selectedRoomDeskData?.type == "Private Room" || selectedRoomDeskData?.type == "Executive Cabin") {
                        const { deposit } = item.planData;
                        const { price } = item.pricing;
                        totalSubtotal += price;
                        depositTotal += deposit;
                    } else {
                        const { deposit } = item?.planData;
                        const { price } = item?.pricing;
                        const subtotal1 = (selectHours ? selectHours : 1) * price;
                        totalSubtotal += subtotal1;
                        depositTotal += deposit;
                    }
                }
            }
        }



        setsubtotal(Math.round(totalSubtotal))
        setdiscountedTotal(Math.round(totalSubtotal))
        setdepositTotal(Math.round(depositTotal))
        settotalAmount(Math.round(totalSubtotal))
        setnetTotal(Math.round(totalSubtotal))
        return depositTotal;
    };




    useEffect(() => {
        calculateSubtotal()
    }, [cartJson])
    useEffect(() => {
        calculateSubtotal()
    }, [selectHours])


    const calculateDiscount = () => {
        setdiscountedTotal(Math.round(subtotal - discount));
        setnetTotal(Math.round(subtotal - discount));
    };



    useEffect(() => {
        settaxedAmount(Math.round((discountedTotal * tax)));

    }, [tax, subtotal])
    useEffect(() => {
        setnetTotal(taxedAmount + discountedTotal)
    }, [taxedAmount])



    useEffect(() => {
        if (cart?.length <= 0) {
            setdiscount(0)
            settax(0)
        }
    }, [cart])

    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness()
        }
        return () => {
            mount = false;
        };
    }, [])

    const PaymentMethod = [
        {
            id: 1,
            option: 'Bank',
        },
        {
            id: 2,
            option: 'Cash',
        },
        {
            id: 3,
            option: 'Stripe',
        },
        {
            id: 4,
            option: 'POS',
        },
        {
            id: 5,
            option: 'Cheque',
        },
        {
            id: 6,
            option: 'Paypal',
        },


    ];


    const numberOfMonths = window.innerWidth > 768 ? 2 : 1;

    const getAvailableSlots = async () => {
        try {
            let payload = {
                slotDurationInHours: selectHours,
                id: planDetails._id,
                date: moment(startDate).format("YYYY-MM-DD")
            }
            const response = await dispatch(availableSlots(payload)).unwrap();
            setallSlots(response?.data)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            if (startDate && selectHours) {
                getAvailableSlots();
            }
        }
        return () => {
            mount = false;
        };
    }, [startDate, selectHours])

    const isDateBooked = (date) => {
        return getBookedData?.some((booking) => {
            return date >= booking.start && date <= booking.end;
        });
    };


    const handleDayClick = (day) => {
        setisEndToggle(false)
        setendDate(null)
        setdisableEndDate("")
        sethandleMonth(null)
        const selectedDateTimestamp = Date.parse(day);

        if (!selectedRange.from || selectedRange.to) {
            // If no start date is selected or both start and end dates are selected, start a new range
            setSelectedRange({ from: selectedDateTimestamp, to: null });
        } else if (selectedDateTimestamp > selectedRange.from) {
            // If a start date is selected and the clicked date is after it, set it as the end date
            setSelectedRange((prevRange) => ({ ...prevRange, to: selectedDateTimestamp }));

            // Check if any date within the range is booked
            const isRangeBooked = getBookedData.some(
                (booking) => {
                    for (let date = selectedRange.from; date <= selectedDateTimestamp; date += 86400000) {
                        // 86400000 milliseconds = 1 day
                        if (date >= booking.start && date <= booking.end) {
                            return true; // If any date within the range is booked, return true
                        }
                    }
                    return false;
                }
            );

            if (isRangeBooked) {
                // If any date within the range is booked, reset the range
                setSelectedRange({ from: selectedDateTimestamp, to: null });
            }
        } else {
            // If a start date is selected and the clicked date is before it, start a new range
            setSelectedRange({ from: selectedDateTimestamp, to: null });
        }
    };

    const handleDayClickForEndDate = (day) => {

        const selectedDateTimestamp = Date.parse(day);

        if (!selectedRange.from || selectedRange.to) {
            // If no start date is selected or both start and end dates are selected, start a new range
            setSelectedRange({ from: selectedDateTimestamp, to: null });
        } else if (selectedDateTimestamp > selectedRange.from) {
            // If a start date is selected and the clicked date is after it, set it as the end date
            setSelectedRange((prevRange) => ({ ...prevRange, to: selectedDateTimestamp }));

            // Check if any date within the range is booked
            const isRangeBooked = getBookedData.some(
                (booking) => {
                    for (let date = selectedRange.from; date <= selectedDateTimestamp; date += 86400000) {
                        // 86400000 milliseconds = 1 day
                        if (date >= booking.start && date <= booking.end) {
                            return true; // If any date within the range is booked, return true
                        }
                    }
                    return false;
                }
            );

            if (isRangeBooked) {
                // If any date within the range is booked, reset the range
                setSelectedRange({ from: selectedDateTimestamp, to: null });
            }
        } else {
            // If a start date is selected and the clicked date is before it, start a new range
            setSelectedRange({ from: selectedDateTimestamp, to: null });
        }
    };

    const disabledDays = (day) => {
        // if (!getBookedData) {
        //     return false;
        // }
        // return isDateBooked(day);

        const today = new Date(); // Get the current date
        today.setHours(0, 0, 0, 0);
        return !getBookedData || day < today || isDateBooked(day);


    };

    // const disabledDaysForDesk = (day) => {
    //     if (!getBookedData) {
    //         return false;
    //     }
    //     return isDateBooked(day);
    // };

    const disabledDaysForDesk = (day) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set hours to 0 to compare only dates

        // Disable dates prior to today
        if (day < today) {
            return true;
        }

        if (!getBookedData) {
            return false;
        }
        return isDateBooked(day);
    };

    console.log("getBookedData", business)


    // const isDisabled = (day) => {
    //     if (!enableDaysForSingleCalender) {
    //         return false;
    //     }
    //     const dayName = day.toLocaleDateString('en-US', { weekday: 'short' });
    //     return !enableDaysForSingleCalender.includes(dayName);
    // };

    const isDisabled = (day) => {
        if (!enableDaysForSingleCalender) {
            return true; // Disable all days if enableDaysForSingleCalender is not set
        }

        // Disable past dates
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (day < today) {
            return true;
        }

        // Disable specific days
        const dayName = day.toLocaleDateString('en-US', { weekday: 'short' });

        console.log("dayName", dayName)
        return !enableDaysForSingleCalender.includes(dayName);
    };

    const getDeskBookedSlots = async () => {
        try {
            let paylaod = {
                userRequestedDeskCount,
                id: planDetails._id,
                startTime: startDeskTime,
                endTime: endDeskTime
            }
            if (planDetails?.type == "Hot Desk") {
                // const response = await dispatch(checkHotDeskAvaibility(paylaod)).unwrap();
                // setgetBookedData(response?.data?.map(booking => ({
                //     start: new Date(booking.start).setHours(0, 0, 0, 0),
                //     end: new Date(booking.end).setHours(0, 0, 0, 0)
                // })));

                const response = await dispatch(checkDeskAvaibility(paylaod)).unwrap();
                setgetBookedData(response?.data?.map(booking => ({
                    start: new Date(booking.start).setHours(0, 0, 0, 0),
                    end: new Date(booking.end).setHours(0, 0, 0, 0)
                })));


            } else {
                const response = await dispatch(checkDeskAvaibility(paylaod)).unwrap();
                setgetBookedData(response?.data?.map(booking => ({
                    start: new Date(booking.start).setHours(0, 0, 0, 0),
                    end: new Date(booking.end).setHours(0, 0, 0, 0)
                })));

            }

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        if (userRequestedDeskCount) {

            getDeskBookedSlots()

        }
    }, [userRequestedDeskCount])



    return (
        <>
            {load ? <Spinner /> : <></>}
            <div className="sp-location-detail-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/bookings")}>{t('bookings')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('booking')} {t('detail')}</li>
                    </ol>
                </nav>

                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/bookings-icon-sp.svg`} className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('add')} {t('booking')}</h2>
                </div>
                <div className="row mb-4 gy-3">
                    <div className="col-md-4">
                        <div class="dropdown sp-select-dropdown">
                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {/* {userId ? userId?.name : "User"} */}
                                {userId ? getAllCustomers.find(user => user.user?._id === userId)?.user?.name : <>{t('user')}</>}
                            </a>
                            <ul class="dropdown-menu">
                                {getAllCustomers?.map((data, i) => (
                                    <li key={i} onClick={() => setuserId(data?.user?._id)}><a class="dropdown-item" >{data?.user?.name}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div class="dropdown sp-select-dropdown">
                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {/* {userId ? userId?.name : "User"} */}
                                {spaceId ? getAllSpaces.find(space => space?._id === spaceId)?.name : <>{t('location')}</>}
                            </a>
                            <ul class="dropdown-menu">
                                {getAllSpaces?.map((data, i) => (
                                    <li key={i} onClick={() => setspaceId(data?._id)}><a class="dropdown-item" >{data?.name}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div class="dropdown sp-select-dropdown">
                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {/* {userId ? userId?.name : "User"} */}
                                {roomAndDeskId ? getAllRoomsAndDesks.find(desk => desk?._id === roomAndDeskId)?.name : <>{t('roomdesk')}</>}
                            </a>
                            <ul class="dropdown-menu">
                                {getAllRoomsAndDesks?.map((data, i) => (
                                    <li key={i} onClick={() => handleSelectChange(data?._id)} ><a class="dropdown-item" ><span style={{ color: "#202971" }}><b>{data?.behave}</b> </span> : {data?.name}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                {selectedRoomDeskData && <div className="sp-shadow sp-radius-20 white-bg p-4 mb-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="mb-3">
                                <label class="light-blue-color fw-semibold mb-2 d-block">{t('selectPlans')}</label>
                                <div class="d-flex flex-wrap gap-3">
                                    {plans?.filter(plan => plan.pricingType.price !== 0)?.map((data, index) => (
                                        <>
                                            <input key={index} type="radio" name="plan" class="btn-check" onChange={() => handlePlan(data)} id={`${data.planName.name}-${data.pricingType.type}-checked`} />
                                            <label class="btn sp-check-select" for={`${data.planName.name}-${data.pricingType.type}-checked`}>{data.planName.name} {data.pricingType.type} - {profile?.currency} {data.pricingType.price}</label>
                                        </>))}
                                </div>
                            </div>
                            {/* {planDetails?.type == "Hot Desk" && <><div class="mb-3">
                                <label class="light-blue-color fw-semibold mb-2 d-block">{t('selectTime')}</label>
                                <div class="col-sm-10 d-flex gap-3">
                                    <div class="form-floating sp-input w-100">
                                        <input type="time" class="form-control" id="start-time" onChange={(e) => setstartDeskTime(e.target.value)} placeholder="Space Name" />
                                        <label for="start-time">{t('startTime')}</label>
                                    </div>
                                    <div class="form-floating sp-input w-100">
                                        <input type="time" class="form-control" id="end-time" onChange={(e) => setendDeskTime(e.target.value)} placeholder="Space Name" />
                                        <label for="end-time">{t('endTime')}</label>
                                    </div>
                                </div>
                            </div></>} */}
                            {(planDetails?.type !== "Private Room" && planDetails?.type !== "Virtual Office" && planDetails?.type !== "Executive Cabin") && <>
                                <div class="mb-3">
                                    <label class="light-blue-color fw-semibold mb-2 d-block">{planDetails?.behave == "Desk" ? <>{t('deskSelected')}</> : <>{t('selectHours')}</>}</label>
                                    <div className="d-flex flex-wrap gap-3">
                                        {planDetails?.behave == "Desk" ?
                                            [...Array(totalCount)].map((_, index) => (
                                                <>
                                                    <input
                                                        type="radio"
                                                        name="desks"
                                                        className="btn-check"
                                                        onChange={() => handleRadioChange(index)}
                                                        id={`desks-${index + 1}-checked`}
                                                    />
                                                    <label className="btn sp-check-select" htmlFor={`desks-${index + 1}-checked`}>
                                                        {index + 1} {t('desk')}
                                                    </label>
                                                </>
                                            )) :
                                            [...Array(planDetails?.noOfHours)].map((_, index) => (
                                                <>
                                                    <input
                                                        type="radio"
                                                        name="hours"
                                                        className="btn-check"
                                                        onChange={() => { setselectHours(index + 1) }}
                                                        id={`hour-${index + 1}-checked`}
                                                    />
                                                    <label className="btn sp-check-select" htmlFor={`hour-${index + 1}-checked`}>
                                                        {planDetails ? `${index + 1} Hour` : <></>}
                                                    </label>
                                                </>
                                            ))}
                                    </div>
                                </div>




                            </>}
                            {(planDetails?.type === "Meeting Room" || planDetails?.type === "Podcast Room" || planDetails?.type === "Huddle Room" || planDetails?.type === "Event Space") && allSlots?.length > 0 && <>
                                <div class="mb-3">
                                    <label class="light-blue-color fw-semibold mb-2 d-block">{t('timeSlot')}</label>
                                    <div class="d-flex flex-wrap gap-3">
                                        {allSlots?.map((time, index) => (
                                            <div key={index}>
                                                <input type="radio" name="time-slot" class="btn-check" id={`${time?.startTime}-time-slot`} onChange={() => handleTimeSlots(time)} />
                                                <label class="btn sp-check-select" for={`${time.startTime}-time-slot`}>{time?.startTime} - {time?.endTime}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div></>}

                        </div>
                        <div className="col-md-6">
                            <div class="mb-3">
                                <label class="light-blue-color fw-semibold mb-2 d-block">{t('date')}</label>
                                <div class="sm-day-picker">
                                    {planDetails?.type == "Private Room" || planDetails?.type == "Executive Cabin" ? <>
                                        <div className="d-flex justify-content-between">
                                            <DayPicker
                                                mode="single"
                                                // selected={selectedRange}
                                                selected={startDate}
                                                onSelect={setstartDate}
                                                onDayClick={handleDayClick}
                                                disabled={disabledDays}
                                                toDate={threeMonth}
                                            />
                                            {isEndToggle ? <DayPicker
                                                mode="single"
                                                // selected={selectedRange}
                                                selected={endDate}
                                                onSelect={setendDate}
                                                onDayClick={handleDayClickForEndDate}
                                                defaultMonth={handleMonth}
                                                disabled={{ before: disableEndDate }}
                                            /> : <></>}
                                        </div>
                                        <div class="form-check sp-signup-checkbox mb-4">
                                            <input class="form-check-input" type="checkbox" name="endRadio" id="endRadio" checked={isEndToggle} onChange={() => handleToggle()} />
                                            <label class="form-check-label" for="endRadio">Do you want to provide end Date</label>
                                        </div>
                                    </> : planDetails?.behave == "Virtual Office" ? <>
                                        <div className="d-flex justify-content-between">
                                            <DayPicker
                                                mode="single"
                                                // selected={selectedRange}
                                                selected={startDate}
                                                onSelect={setstartDate}
                                                onDayClick={handleDayClick}
                                                toDate={threeMonth}
                                            />
                                            {isEndToggle ? <DayPicker
                                                mode="single"
                                                // selected={selectedRange}
                                                selected={endDate}
                                                onSelect={setendDate}
                                                onDayClick={handleDayClickForEndDate}
                                                defaultMonth={handleMonth}
                                                disabled={{ before: disableEndDate }}
                                            /> : <></>
                                            }
                                        </div>
                                        <div class="form-check sp-signup-checkbox mb-4">
                                            <input class="form-check-input" type="checkbox" name="endRadio" id="endRadio" checked={isEndToggle} onChange={() => handleToggle()} />
                                            <label class="form-check-label" for="endRadio">Do you want to provide end Date</label>
                                        </div>
                                    </> : planDetails?.behave == "Desk" ? <>
                                        <div className="d-flex justify-content-between">
                                            <DayPicker
                                                mode="single"
                                                // selected={selectedRange}
                                                selected={startDate}
                                                onSelect={setstartDate}
                                                onDayClick={handleDayClick}
                                                disabled={disabledDaysForDesk}
                                                toDate={threeMonth}
                                            />
                                            {isEndToggle ? <DayPicker
                                                mode="single"
                                                // selected={selectedRange}
                                                selected={endDate}
                                                onSelect={setendDate}
                                                onDayClick={handleDayClickForEndDate}
                                                defaultMonth={handleMonth}
                                                disabled={{ before: disableEndDate }}
                                            /> : <></>}
                                        </div>

                                        <div class="form-check sp-signup-checkbox mb-4">
                                            <input class="form-check-input" type="checkbox" name="endRadio" id="endRadio" checked={isEndToggle} onChange={() => handleToggle()} />
                                            <label class="form-check-label" for="endRadio">Do you want to provide end Date</label>
                                        </div>
                                    </> :
                                        <>
                                            <DayPicker
                                                mode="single"
                                                selected={startDate}
                                                onSelect={setstartDate}
                                                disabled={(day) => isDisabled(day)}
                                                toDate={threeMonth}

                                            />
                                        </>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>}

                <div className="l-blue-bg p-4 mb-3 sp-shadow sp-radius-20">
                    <div className="row gy-4 justify-content-between">
                        <div className="col-md-4 d-flex flex-column gap-3">
                            <div class="form-floating sp-input">
                                <input type="date" class="form-control" id="due-date" defaultValue={dueDate} onChange={(e) => setdueDate(e.target.value)} placeholder="Due Date" />
                                <label for="due-date">{t('due')} {t('Date')}</label>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-2">
                                <p className="font-size-18 black-color mb-0 ">{t('discount')}</p>
                                <input class="form-control form-control-sm border-0 py-3 sp-shadow rounded w-50" type="number" placeholder={profile?.currency} value={discount} onChange={(e) => {
                                    const inputValue = parseInt(e.target.value, 10);
                                    if (!isNaN(inputValue) && inputValue >= 0) {
                                        setdiscount(inputValue);
                                    }
                                }} />
                                <p className="font-size-18 dark-blue-color mb-0 cursor-pointer" onClick={calculateDiscount}><u>{t('applyDiscount')}</u></p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="font-size-18 black-color mb-0 w-50">{t('taxes')}</p>
                                <div class="dropdown sp-select-dropdown w-100">
                                    <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {taxvalue ? taxvalue : <>{t('selectTaxAmount')}</>}
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li onClick={() => { settax(0 / 100); settaxvalue("Select Tax Amount"); }}><a class="dropdown-item" >{t('selectTaxAmount')}</a></li>
                                        <li onClick={() => { settax(20 / 100); settaxvalue("VAT 20%"); }}><a class="dropdown-item" >VAT 20%</a></li>
                                        <li onClick={() => { settax(13 / 100); settaxvalue("SST 13%"); }}><a class="dropdown-item" >SST 13%</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <p className="font-size-16 dark-blue-color fw-semibold mb-2">{t('paymentMethod')}</p>

                                <div className="d-flex flex-wrap gap-3">
                                    {PaymentMethod?.map((data, index) => (
                                        <>
                                            <input key={index} type="radio" name="payment-method" id={`${data.option}-payment`} class="btn-check" value={data.option} checked={paymentMethods.includes(data.option)} onChange={() => handleCheckboxChange(data.option)} />
                                            <label class="btn sp-check-select" for={`${data.option}-payment`}>{data?.option}</label>
                                        </>))}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <p className="font-size-30 titilium-font light-blue-color fw-bold mb-2">{t('billing')} {t('detail')}:</p>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <p className="font-size-18 black-color mb-0">{t('subtotal')}</p>
                                <p className="font-size-18 dark-blue-color fw-semibold mb-0">{profile?.currency} {subtotal}</p>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <p className="font-size-18 black-color mb-0">{t('Discount')}</p>
                                <p className="font-size-18 dark-blue-color fw-semibold mb-0">{profile?.currency} - {discount}</p>
                            </div>


                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <p className="font-size-18 black-color mb-0">{t('Total')}</p>
                                <p className="font-size-18 dark-blue-color fw-semibold mb-0">{profile?.currency} {discountedTotal}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <p className="font-size-18 black-color mb-0">{t('Tax')}  <span className="light-blue-color"> {tax ? tax * 100 + " %" : ""}</span></p>
                                <p className="font-size-18 dark-blue-color fw-semibold mb-0">{profile?.currency} {taxedAmount} </p>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <p className="font-size-18 black-color mb-0">{t('totalAmount')}</p>
                                {planDetails?.behave == "Desk" ?
                                    <div className="d-flex gap-2 align-items-center">
                                        <p className="font-size-14 black-color mb-0">{t('for')} {userRequestedDeskCount} {t('desk')}</p>
                                        <h3 className="font-size-18 dark-blue-color fw-semibold mb-0">{profile?.currency}. {pricing?.price ? netTotal : ""}</h3>
                                    </div>
                                    : planDetails?.type == "Private Room" || planDetails?.type == "Executive Cabin" ?
                                        <div className="d-flex gap-2 align-items-center">
                                            <p className="font-size-14 black-color mb-0">{t('for')}  {pricing?.type}</p>
                                            <h3 className="font-size-18 dark-blue-color fw-semibold mb-0">{profile?.currency}. {netTotal}</h3>
                                        </div>
                                        : planDetails?.type == "Meeting Room" || planDetails?.type == "Podcast Room" || planDetails?.type == "Huddle Room" || planDetails?.type == "Event Space" ?
                                            <div className="d-flex gap-2 align-items-center">
                                                <p className="font-size-14 black-color mb-0">{t('for')} {selectHours} {t('hour')}</p>
                                                <h3 className="font-size-18 dark-blue-color fw-semibold mb-0">{profile?.currency}. {netTotal}</h3>
                                            </div> : planDetails?.type == "Virtual Office" ?
                                                <div className="d-flex gap-2 align-items-center">
                                                    <p className="font-size-14 black-color mb-0">{t('for')}  {pricing?.type}</p>
                                                    <h3 className="font-size-18 dark-blue-color fw-semibold mb-0">{profile?.currency}. {netTotal}</h3>
                                                </div>
                                                : <></>
                                }
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <p className="font-size-18 black-color mb-0">{t('deposit')} {t('amount')}</p>
                                <p className="font-size-18 dark-blue-color fw-semibold mb-0">{profile?.currency} {depositTotal}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <p className="font-size-18 black-color mb-0">{t('amount')} {t('due')}</p>
                                <p className="font-size-30 titilium-font light-blue-color fw-bold mb-0">{profile?.currency} {netTotal + depositTotal}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-center mt-5">
                    <button class="btn sp-button sp-blue-button" onClick={addToCart}>{t('save')}</button>
                </div>
            </div>
        </>
    )
}

export default AddBooking