
import { initializeApp } from "firebase/app";
import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfile, getUserRight, signinUser, socialLogin } from "../../store/slices/userSlice";
import { signInWithPopup } from "firebase/auth";
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import i18n from '../../components/i18n';

// const firebaseConfig = {
//     apiKey: "AIzaSyBZyQ2C4iqXIQOLZYmrsWebC3Yy85MvC5w",
//     authDomain: "spaces-manager-b5e9c.firebaseapp.com",
//     projectId: "spaces-manager-b5e9c",
//     storageBucket: "spaces-manager-b5e9c.appspot.com",
//     messagingSenderId: "225008593420",
//     appId: "1:225008593420:web:e6248b5d093835e96d1c29",
//     measurementId: "G-FK83H8WJ3X"
//   };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
const google = new GoogleAuthProvider();
const facebook = new FacebookAuthProvider();
const apple = new OAuthProvider("apple.com");

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRight = useSelector(getUserRight);
    const profile = useSelector(getProfile);

    const [user_device_token, setuser_device_token] = useState(true)

    console.log("login right check", userRight, profile)



    const socialSignin = async (provider) => {
        try {
            const result = await signInWithPopup(getAuth(), provider);
            if (result) {

                const response = await dispatch(
                    socialLogin({
                        socialToken: result?.user?.uid,
                        socialType: result?.providerId,
                        email: result?.user?.email,
                        name: result?.user?.displayName,
                    })
                ).unwrap();
                navigate("/dashboard")
            } else {
                console.log("Caught error Popup closed");
            }
        } catch (error) {
            console.log(error.code, error.message);
        }
    };

    const handleGoogleSignIn = (e) => {
        e.preventDefault()

        socialSignin(new GoogleAuthProvider())
    }

    const handleFacebookSignIn = (e) => {
        e.preventDefault()

        socialSignin(new FacebookAuthProvider())
    }

    useEffect(() => {
        const data = Cookies.get("user_device_token");
        setuser_device_token(data);
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let paylaod = {
                email,
                password,
                devicetoken: user_device_token
            }
            const response = await dispatch(signinUser(paylaod)).unwrap();

            navigate('/dashboard');
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSecureEntry, setisSecureEntry] = useState(true)

    const currentLanguage = i18n.language;

    return (
        <>
            <section class="authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-sm rounded border-0">
                                <div class="card-body p-5 text-center">
                                    <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}assets/images/logo-new.svg`} />
                                    {/* <div class="d-grid gap-3 mb-3">
                                        <button class="btn social-btn btn-lg sp-shadow" type="button" onClick={(e) => handleGoogleSignIn(e)}><i class="fab fa-google me-2" ></i>Log in with Google</button>
                                        <button class="btn social-btn btn-lg sp-shadow" type="button" onClick={(e) => handleFacebookSignIn(e)}><i class="fab fa-facebook-f me-2"></i>Log in with Facebook</button>
                                    </div>
                                    <p className="font-size-18 dark-blue-color"><b>or</b></p> */}
                                    <form onSubmit={handleSubmit} >
                                        <div class="form-floating sp-input mt-3 mb-3">
                                            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                                                onChange={(e) => setEmail(e.target.value)} autocomplete="email" required />
                                            <label for="floatingInput">Email address</label>
                                        </div>
                                        <div class="form-floating sp-input mb-3 ">
                                            <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                                                type={isSecureEntry ? "password" : "text"} autocomplete="password" required />
                                            <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                                            <label for="floatingPassword">Password</label>
                                        </div>

                                        <div class="row justify-content-between mb-3">
                                            <div class="col-md-4">
                                                <div class="form-check sp-login-checkbox">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                        Keep me login
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-4 text-end">
                                                <a onClick={() => navigate("/forget-password")} className="reset-pass">Forgot password?</a>
                                            </div>
                                        </div>


                                        {/* <div class="text-center mb-4"> */}
                                        <button class="btn sp-button sp-blue-button mb-4 w-100" type="submit">Log in</button>
                                        {/* </div> */}
                                    </form>
                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                        <div onClick={(e) => handleFacebookSignIn(e)} className="sp-social-login"><i class="bi bi-facebook"></i></div>
                                        <div onClick={(e) => handleGoogleSignIn(e)} className="sp-social-login"><i class="bi bi-google"></i></div>
                                    </div>
                                </div>
                                <div class="card-footer sp-auth-card-footer">
                                    <div className="text-center">
                                        <a
                                            className=""
                                            onClick={() => navigate("/sign-up")}
                                        >
                                            Don't have a Spaces Manager account? <b className="light-blue-color">Sign up</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Login;
