import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getAllSpaces, getBusiness, getByBusinessData, getProfile, getUserRight } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Eye, Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Spaces = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const userRights = useSelector(getUserRight)
    const [listOfSpace, setlistOfSpace] = useState([])
    const [load, setload] = useState(false);
    const [toggle, settoggle] = useState(false);




    const [nameFilter, setNameFilter] = useState('');
    const [emailFilter, setEmailFilter] = useState('');
    const [manager, setmanager] = useState('');
    const [filteredData, setFilteredData] = useState([]);


    const applyFilter = () => {
        const filteredResult = listOfSpace?.filter(item => {
            const userName = item?.name?.toLowerCase();
            const userEmail = item?.spaceEmail?.toLowerCase();
            const spaceManager = item?.spaceManagerUser ? item?.spaceManagerUser?.name.toLowerCase() : ''; // Check for existence of company

            return (
                (nameFilter === '' || userName?.includes(nameFilter?.toLowerCase())) &&
                (manager === '' || spaceManager?.includes(manager?.toLowerCase())) &&
                (emailFilter === '' || userEmail?.includes(emailFilter?.toLowerCase()))
            );
        });

        setFilteredData(filteredResult);
        settoggle(false);
    };



    const resetFilter = () => {
        setNameFilter('');
        setEmailFilter('');
        setmanager('');
        setFilteredData(listOfSpace)
        settoggle(false);
    };


    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const getSpaceList = async () => {
        try {
            setload(true);
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,

            }


            const response = await dispatch(getAllSpaces(payload)).unwrap();


            setlistOfSpace(response?.data)
            setFilteredData(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness();

        }
        return () => {
            mount = false;
        };
    }, [])

    useEffect(() => {
        let mount = true;
        if (mount) {
            getSpaceList();

        }
        return () => {
            mount = false;
        };
    }, [business, profile])

    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [searchValueEmail, setSearchValueEmail] = useState('');
    const [searchValueCompany, setSearchValueCompany] = useState('');


    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value.length > 0) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };
    const handleOptionClick = (data) => {
        setSearchValue(data?.name);
        setNameFilter(data?.name);
        setShowDropdown(false);
    };

    const handleInputChange1 = (e) => {
        const value = e.target.value;
        setSearchValueEmail(value);
        if (value.length > 0) {
            setShowDropdown1(true);
        } else {
            setShowDropdown1(false);
        }
    };
    const handleOptionClick1 = (data) => {
        setSearchValueEmail(data?.spaceEmail);
        setEmailFilter(data?.spaceEmail);
        setShowDropdown1(false);
    };


    const handleInputChange2 = (e) => {
        const value = e.target.value;
        setSearchValueCompany(value);
        if (value.length > 0) {
            setShowDropdown2(true);
        } else {
            setShowDropdown2(false);
        }
    };
    const handleOptionClick2 = (data) => {
        setSearchValueCompany(data?.spaceManagerUser.name);
        setmanager(data?.spaceManagerUser.name);
        setShowDropdown2(false);
    };



    return (
        <>
            <div className="sp-locations-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('spaces')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('locations')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                    <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                        <img height="32" src="./assets/images/spaces-icon-sp.svg" className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('locations')}</h2>
                    </div>
                    <div className="col-md-6 order-md-2 order-3">
                        <div class="dropdown sp-search-dropdown">
                            <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
                                <span>{t('filterAndSearch')}</span>
                                <span>
                                    {toggle ? <X /> : <Search />}
                                </span>
                            </a>

                            <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputname" class="col-sm-2 col-form-label">{t('spaceName')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={(e) => handleInputChange(e)} value={searchValue} placeholder="Filter by Space Name" class="form-control" id="inputname" />
                                        {showDropdown && (
                                            <div className="dropdown">
                                                <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                    {listOfSpace?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                                                        <li key={index} onClick={() => handleOptionClick(data)}><a class="dropdown-item" >{data?.name}</a></li>
                                                    ))}
                                                    {listOfSpace?.length > 0 && listOfSpace?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                                                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputemail" class="col-sm-2 col-form-label">{t('email')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={(e) => handleInputChange1(e)} value={searchValueEmail} placeholder="Filter by Email" class="form-control" id="inputemail" />
                                        {showDropdown1 && (
                                            <div className="dropdown">
                                                <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                    {listOfSpace?.filter((data) => data?.spaceEmail?.toLowerCase()?.includes(searchValueEmail?.toLowerCase()))?.map((data, index) => (
                                                        <li key={index} onClick={() => handleOptionClick1(data)}><a class="dropdown-item" >{data?.spaceEmail}</a></li>
                                                    ))}
                                                    {listOfSpace?.length > 0 && listOfSpace?.filter((data) => data?.spaceEmail?.toLowerCase()?.includes(searchValueEmail?.toLowerCase()))?.length === 0 && (
                                                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputname" class="col-sm-2 col-form-label">{t('manager')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={(e) => handleInputChange2(e)} value={searchValueCompany} placeholder="Filter by Manager Name" class="form-control" id="inputname" />
                                        {showDropdown2 && (
                                            <div className="dropdown">
                                                <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                    {listOfSpace?.filter((data) => data?.data?.spaceManagerUser.name?.toLowerCase()?.includes(searchValueCompany?.toLowerCase()))?.map((data, index) => (
                                                        <li key={index} onClick={() => handleOptionClick2(data)}><a class="dropdown-item" >{data?.data?.spaceManagerUser.name}</a></li>
                                                    ))}
                                                    {listOfSpace?.length > 0 && listOfSpace?.filter((data) => data?.data?.spaceManagerUser.name?.toLowerCase()?.includes(searchValueCompany?.toLowerCase()))?.length === 0 && (
                                                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex gap-2 justify-content-end">
                                    <button class="btn sp-button sp-blue-button" onClick={applyFilter}>{t('search')}</button>
                                    <button class="btn sp-button sp-blue-button" onClick={resetFilter}>{t('reset')}</button>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                        {userRights?.Spaces?.create == 1 || userRights == null ? <button class="btn sp-button sp-blue-button" onClick={() => navigate("/locations/add-location")}>{t('add')} {t('location')}</button> : <></>}
                    </div>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg">
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">{t('spaceName')}</th>
                                    <th scope="col">{t('manager')}</th>
                                    <th scope="col">{t('email')}</th>
                                    <th scope="col">{t('phone')}</th>
                                    <th scope="col">{t('desks')}</th>
                                    <th scope="col">{t('rooms')}</th>
                                    <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((data, i) => (
                                    <tr>
                                        <td>{data?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.spaceManagerUser ? data?.spaceManagerUser.name : "Not Assigned Yet"}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.spaceEmail}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.spacePhone}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.totaldesks}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.totalRooms}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div onClick={() => navigate("/locations/location-detail", { state: data })} className={`sp-action-icons`}><Eye width="18" /></div>
                                                {userRights?.Spaces?.edit == 1 || userRights == null ? <div onClick={() => navigate("/locations/edit-location", { state: data })} className={`sp-action-icons`}><Pencil width="18" /></div> : <></>}
                                                {userRights?.Spaces?.delete == 1 || userRights == null ? <div className={`sp-action-icons`}><X width="18" /></div> : <></>}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Spaces