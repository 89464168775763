import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getBusiness, getByBusinessData, getProfile, getUserRight, listOfInvoices, listOfTransaction } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Download, Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Invoices = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const userRights = useSelector(getUserRight)
    const [load, setload] = useState(false);
    const [toggle, settoggle] = useState(false);

    const [invoices, setinvoices] = useState([]);




    const [nameFilter, setNameFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [locationFilter, setlocationFilter] = useState('');
    const [selectedStatuses, setSelectedStatuses] = useState([]);


    const applyFilter = () => {
        const filteredResult = invoices?.filter(item => {
            const userName = item?.users?.name.toLowerCase();
            const location = item?.spaces?.name?.toLowerCase();
            const status = item?.status

            return (
                (nameFilter === '' || userName?.includes(nameFilter?.toLowerCase())) &&
                (selectedStatuses?.length === 0 || selectedStatuses.includes(status)) &&
                (locationFilter === '' || location?.includes(locationFilter?.toLowerCase()))
            );
        });

        setFilteredData(filteredResult);
        settoggle(false);
    };



    const handleStatusChange = (status) => {
        if (selectedStatuses?.includes(status)) {
            setSelectedStatuses(prevStatuses => prevStatuses.filter(s => s !== status));
        } else {
            setSelectedStatuses(prevStatuses => [...prevStatuses, status]);
        }
    };


    const resetFilter = () => {
        setNameFilter('');
        setlocationFilter('')

        setFilteredData(invoices)
        settoggle(false);
    };



    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getInvoices = async () => {
        try {
            setload(true);
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }
            const response = await dispatch(listOfInvoices(payload)).unwrap();
            setinvoices(response?.data)
            setFilteredData(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };



    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness()
        }
        return () => {
            mount = false;
        };
    }, [])


    useEffect(() => {
        let mount = true;
        if (mount) {
            if (business) {
                getInvoices()

            }
        }
        return () => {
            mount = false;
        };
    }, [business])

    useEffect(() => {
        let mount = true;
        if (mount) {
            if (profile) {
                getInvoices()

            }
        }
        return () => {
            mount = false;
        };
    }, [profile])


    return (
        <>
            <div className="sp-invoices-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('finance')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('Invoices')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                    <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                        <img height="32" src="./assets/images/finance-icon-sp.svg" className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('Invoices')}</h2>
                    </div>
                    <div className="col-md-6 order-md-2 order-3">
                        <div class="dropdown sp-search-dropdown">
                            <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
                                <span>{t('filterAndSearch')}</span>
                                <span>
                                {toggle ? <X /> : <Search />}
                                </span>
                            </a>

                            <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputname" class="col-sm-2 col-form-label">{t('name')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={e => setNameFilter(e.target.value)} value={nameFilter} placeholder="Filter by Name" class="form-control" id="inputname" />
                                    </div>
                                </div>

                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputemail" class="col-sm-2 col-form-label">{t('location')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={e => setlocationFilter(e.target.value)} value={locationFilter} placeholder="Filter by Location" class="form-control" id="inputemail" />
                                    </div>
                                </div>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputname" class="col-sm-2 col-form-label">{t('status')}</label>
                                    <div class="col-sm-10 d-flex gap-2 flex-wrap">
                                        <input type="checkbox" class="btn-check" id="member-btn" value="Paid" checked={selectedStatuses.includes('Paid')} onChange={() => handleStatusChange('Paid')} />
                                        <label class="btn sp-button sp-green-button" for="member-btn">{t('paid')}</label>

                                        <input type="checkbox" class="btn-check" id="Lead-btn" value="Unpaid" checked={selectedStatuses.includes('Unpaid')} onChange={() => handleStatusChange('Unpaid')} />
                                        <label class="btn sp-button sp-grey-button" for="Lead-btn">{t('unpaid')}</label>


                                    </div>
                                </div>

                                <div className="d-flex gap-2 justify-content-end">
                                    <button class="btn sp-button sp-blue-button" onClick={applyFilter}>{t('search')}</button>
                                    <button class="btn sp-button sp-blue-button" onClick={resetFilter}>{t('reset')}</button>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                    </div>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg">
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">{t('invoiceNo')}</th>
                                    <th scope="col">{t('dueDate')}</th>
                                    <th scope="col">{t('location')}</th>
                                    <th scope="col">{t('memberName')}</th>
                                    <th scope="col">{t('status')}</th>
                                    <th scope="col">{t('amount')}</th>
                                    <th scope="col">{t('Invoice')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((data, i) => (
                                    <tr>
                                        <td><u className="cursor-pointer" onClick={() => navigate("/invoices/invoice-detail", { state: data })}>{data?.invoiceNumber}</u></td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{moment(data?.dueDate).format("MMMM Do YYYY")}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.spaces?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.users?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}><span className={`btn sp-button py-2 ${data.status == "Paid" ? "sp-green-button" : "sp-red-button"}`} >{data?.status}</span></td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{profile?.currency} {data?.totalAmount + data?.depositAmount}</td>
                                        <td><a href={`${process.env.REACT_APP_IMGURL}/${data?.invoiceFile}`} target="_blank" download ><Download stroke='var(--color5)' strokeWidth={1} /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invoices