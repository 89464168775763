import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllPaymentMethods, getBusiness, getByBusinessData, getNotifications, getProfile, setPaymentMethodForBusiness, signOut } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { context } from '../../context/context';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { getSocket } from '../../store/slices/socketSlice';
import moment from 'moment';

const Navbar = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile)
  const business = useSelector(getBusiness)
  const navigate = useNavigate();
  const socket = useSelector(getSocket);
  const [notifications, setNotifications] = useState([])
  const [isNotify, setisNotify] = useState(false)
  const [userDetail, setuserDetail] = useState("")
  const [id, setid] = useState("")


  const listOfNotifications = async () => {
    try {


      const response = await dispatch(getNotifications()).unwrap();
      setisNotify(false)
      setNotifications(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  useEffect(() => {

    // Listen for 'checknotification' event
    socket?.on('checknotification', (data) => {

      socket?.emit("getNotificationStatus", {
        data: data?.user
      });

      setuserDetail(data?.user)

    });

    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  const callMessage = () => {
    socket?.on("response", (data) => {
      if (data?.object_type == "check_all_notification") {
        console.log(data)
        setid(data?.data)
        setisNotify(true)

      }
    });
  };


  useEffect(() => {
    callMessage()
  }, [socket, userDetail])

  console.log("nabar", socket)

  const { toggleButton, SetToggleButton } = useContext(context);
  const [listOfMethods, setlistOfMethods] = useState(null)
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const logout = async () => {
    try {
      await dispatch(signOut()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  const handleCheckboxChange = (data) => {
    // Check if the data.name is already in the array
    if (selectedPaymentMethods.includes(data.name)) {
      // If it is, remove it
      setSelectedPaymentMethods(prevMethods =>
        prevMethods.filter(method => method !== data.name)
      );
    } else {
      // If it's not, add it to the array
      setSelectedPaymentMethods(prevMethods => [...prevMethods, data.name]);
    }
  };



  const getMyBusiness = async () => {
    try {
      await dispatch(getByBusinessData()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const allPaymentMethods = async () => {
    try {
      const response = await dispatch(getAllPaymentMethods()).unwrap();
      setlistOfMethods(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleSubmit = async () => {
    try {
      let payload = {
        id: profile?.businessId ? profile?.businessId : business._id,
        paymentMethods: selectedPaymentMethods
      }
      await dispatch(setPaymentMethodForBusiness(payload)).unwrap();

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      allPaymentMethods();
      getMyBusiness()
    }
    return () => {
      mount = false;
    };
  }, [])



  const currentLanguage = i18n.language;

  return (
    <>


      <header className={`${isScrolled ? 'sp-navbar white-bg' : 'sp-navbar'}`}>
        <div className="row px-2 gy-4">
          <div className="col-md-6 d-flex align-items-center gap-4">
            <span>
              <img onClick={() => navigate("/")} className="sp-logo cursor-pointer" width="320" src={`../assets/images/logo-${currentLanguage === 'ar' ? 'arabic-' : ''}new.svg`} />
              <p style={{ marginTop: '-10px', marginRight: '10px' }} className="text-end light-blue-color font-size-16 text-uppercase mb-0">{t('Business')}</p>
            </span>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-center" style={{ "gap": "25px" }}>
            <LanguageSwitcher />
            <img class="" alt="messages" src={`${process.env.REACT_APP_FRONTURL}assets/images/message-icon-sp.svg`} className='cursor-pointer' onClick={() => navigate("/chat")} />

            <div class="dropdown sp-notification-list">
              <a class="" onClick={listOfNotifications} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {isNotify && profile?._id === id ? <img class="" alt="messages" src={`${process.env.REACT_APP_FRONTURL}assets/images/notification-fill-icon-sp.svg`} /> : <img class="" alt="messages" src={`${process.env.REACT_APP_FRONTURL}assets/images/notification-icon-sp.svg`} />}
              </a>

              <ul class="dropdown-menu">
                {notifications?.map((data, index) => (
                  <li className={`d-flex align-items-center gap-2 sp-notification-list-single mb-2 `} style={{ background: data?.isRead ? "white" : "red" }} key={index}>
                    <img src={`${process.env.REACT_APP_IMGURL}/${data?.otherUser?.imageName}`} alt="Image" className="rounded-circle" />
                    <div className="flex-shrink-1">
                      <div className="d-flex justify-content-between">
                        <p className="font-size-14 light-blue-color fw-semibold mb-0">{data?.otherUser?.name}</p>
                        <p className="font-size-12 grey-color mb-0">{moment(data.createdAt).format("MMMM Do YYYY")}</p>
                      </div>
                      <p className="font-size-12 black-color mb-0">{data?.body}</p>
                    </div>
                  </li>
                ))}
                <li className="text-center light-blue-color"><a onClick={() => navigate("/notification")}>See All</a></li>
              </ul>
            </div>

             <div class="d-flex align-items-center gap-3 sp-profile-img">
              <div className="text-end">
                <p className="font-size-14 titilium-font dark-blue-color mb-0 fw-semibold text-capitalize">{profile?.name}</p>
                <p className="font-size-14 grey-blue-color mb-0">{profile?.role}</p>
              </div>
              {profile.imageName ? <img class="rounded-circle" alt="avatar" src={`${process.env.REACT_APP_IMGURL}/${profile?.imageName}`} /> : <img class="rounded-circle" alt="avatar" src='https://via.placeholder.com/65x65' />}
            </div>

            <div class="dropdown sp-nav-dropdown-link">
              <a class="" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img class="" alt="dropdown" src={`${process.env.REACT_APP_FRONTURL}assets/images/dropdown_icon.svg`} />
              </a>

              <ul class="dropdown-menu">
                <li><a class="dropdown-item" onClick={()=> navigate('/settings')}>{t('settings')}</a></li>
                <li><a class="dropdown-item" onClick={logout}>{t('logout')}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <div class="modal fade" id="set-payment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style={{ "--bs-modal-width": "525px" }}>
          <div class="modal-content sp-modal-content">
            <div class="modal-header sp-shadow">
              <h1 class="font-size-26 titilium-font blue-color fw-bold mb-0" id="staticBackdropLabel">Add Payment</h1>
              <button type="button" class="btn-close blue-color" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p className="font-size-16 blue-color mb-2 fw-bold">Payment Method</p>
              <div>
                {
                  listOfMethods?.map((data) => (
                    <div className="form-check form-check-inline sp-checkbox" key={data._id}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${data.name}-${data._id}`}
                        value={data.name}
                        checked={selectedPaymentMethods.includes(data.name)}
                        onChange={() => handleCheckboxChange(data)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`${data.name}-${data._id}`}
                      >
                        {data.name}
                      </label>
                    </div>
                  ))
                }
              </div>

            </div>
            <div class="modal-footer sp-shadow">
              <button type="button" class="btn sp-blue-btn sp-btn py-2" data-bs-dismiss="modal" onClick={handleSubmit}>Add</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar