import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getBusiness, getByBusinessData, getInvoiceDetail, getMyPaymentMethods, getProfile, invoiceDescription, invoicePayment, invoiceSend, receiptSend } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';


const InvoiceDetail = () => {
    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profile = useSelector(getProfile)
    const business = useSelector(getBusiness)

    const [invoice, setinvoice] = useState([]);
    const [paymentType, setpaymentType] = useState("")
    const [description, setdescription] = useState("")
    const [paymentAmount, setpaymentAmount] = useState(0)
    const [paymentMethods, setpaymentMethods] = useState(null)
    const [load, setload] = useState(false)


    const invoiceDetail = async () => {
        try {
            const response = await dispatch(getInvoiceDetail(location?.state?._id)).unwrap();
            setinvoice(response?.data)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const getMyPayment = async () => {
        try {
            const response = await dispatch(getMyPaymentMethods({ id: profile?.businessId ? profile?.businessId : business._id, })).unwrap();


            setpaymentMethods(response?.data?.paymentMethods)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const saveInvoiceDescription = async () => {
        try {

            let payload = {
                text: description,
                date: moment(new Date).format('MMMM Do YYYY'),
                user: profile.name,
                id: invoice._id

            }
            await dispatch(invoiceDescription(payload)).unwrap();

            invoiceDetail()
            setdescription("")
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const handleSubmit = async () => {
        try {
            let payload = {
                paymentAmount,
                paymentType,
                currency: profile?.currency,
                id: invoice?._id,
                ...invoice
            }

            await dispatch(invoicePayment(payload)).unwrap();
            setpaymentType("")
            setpaymentAmount("")
            invoiceDetail()



        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const downloadInvoice = async () => {
        try {
            setload(true)
            const response = await axios.post(`${process.env.REACT_APP_APIURL}download-invoice`, { ...invoice, currency: profile?.currency }, { responseType: 'blob' });

            // Check if the response contains data
            if (response) {
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });



                // You can use a library like file-saver to save the PDF
                saveAs(pdfBlob, 'invoice.pdf');
                setload(false)

            } else {
                console.log('No PDF data received in the response');
                setload(false)
            }
        } catch (error) {
            console.error('Error downloading invoice:', error);
            setload(false)
        }
    };

    const sendInvoice = async () => {
        try {

            await dispatch(invoiceSend({ ...invoice, type: "Invoice", id: profile.name }))
            // const response = await axios.post(`${process.env.REACT_APP_APIURL}email-invoice`, { ...invoice, type: "Invoice", id: profile.name })
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const sendReceipt = async () => {
        try {
            await dispatch(receiptSend({ ...invoice, type: "Receipt", id: profile.name }))
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            invoiceDetail();
            getMyBusiness()


        }
        return () => {
            mount = false;
        };
    }, [location?.state])

    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyPayment()

        }
        return () => {
            mount = false;
        };
    }, [business, profile])


    console.log(paymentMethods)


    return (
        <>
            <div className="sp-invoice-detail-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('finance')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/invoices")}>{t('invoices')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('invoice')} {t('detail')}</li>
                    </ol>
                </nav>
                <div className="row mb-4">
                    <div className="col-md-6 d-flex align-items-center gap-2">
                        <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/finance-icon-sp.svg`} className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('invoice')} {t('detail')}</h2>
                    </div>
                    <div className="col-md-6 d-flex flex-row align-items-center gap-3 justify-content-end">
                        <button class="btn sp-button sp-blue-button" onClick={sendInvoice} >{t('email')} {t('invoice')}</button>
                        {invoice?.dueAmount <= 0 ? <button class="btn sp-button sp-blue-button" onClick={sendReceipt}>{t('email')} {t('receipt')}</button> : <></>}
                        <button class="btn sp-button sp-blue-button" disabled={load} onClick={downloadInvoice} >
                            {load ? (
                                <div className="spinner-border sp-spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                t('downloadPDF')
                            )}</button>
                    </div>
                </div>
                <div className="sp-shadow sp-radius-20 white-bg p-4 mb-4">
                    <div className="row mb-3">

                        <div className="col-md-6">
                            <p className="font-size-18 blue-color mb-2 fw-semibold">{t('billedTo')}:</p>
                            <p className="font-size-16 blue-color mb-1 fw-semibold">{invoice?.user?.name}</p>
                            <p className="font-size-16 black-color mb-1">{invoice?.user?.location?.address}</p>
                            {/* <p className="font-size-16 black-color mb-1">{invoice?.user?.location?.city}</p> */}
                            <p className="font-size-16 black-color mb-0">{invoice?.user?.location?.city}, {invoice?.user?.location?.country}</p>
                        </div>
                        <div className="col-md-6 text-end">
                            <p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('invoiceNo')}:</span> {invoice?.invoiceNumber}</p>
                            <p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('generatedOn')}:</span> {moment(invoice?.createdAt).format("MMMM Do YYYY")}</p>
                            <p className="font-size-16 black-color mb-1"><span className="fw-semibold blue-color">{t('dueOn')}:</span> {moment(invoice?.dueDate).format("MMMM Do YYYY")}</p>
                            <div className="d-flex align-items-center gap-3 mt-2 justify-content-end">
                                <p className="font-size-16 blue-color mb-0 fw-semibold">{t('status')}</p>
                                <span className={`btn sp-button py-2 ${invoice?.status == "Paid" ? "sp-green-button" : "sp-red-button"}`} >{invoice?.status}</span>
                            </div>
                        </div>

                    </div>

                    <p className="font-size-18 blue-color fw-semibold">{t('booking')} {t('detail')}</p>
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">{t('location')}</th>
                                    <th scope="col">{t('type')}</th>
                                    <th scope="col">{t('plan')}</th>
                                    <th scope="col">{t('quantity')}</th>
                                    <th scope="col">{t('price')}</th>
                                    <th style={{ width: '100px' }} scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoice?.booking?.map((data, i) => (
                                    <tr>
                                        <td style={{ 'verticalAlign': 'middle' }}>{invoice?.space?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{`${data?.carts?.roomData ? data?.carts?.roomData?.name : data?.carts?.virtualOfficeData ? data?.carts?.virtualOfficeData?.name : data?.carts?.deskData?.name}`}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.carts?.planData?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.carts?.deskData ? data?.carts?.count : 1}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{profile?.currency} {data?.carts?.deskData ? data?.carts?.pricing?.price * data.carts?.count : data?.carts?.pricing?.price}</td>
                                        <td></td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('subtotal')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice?.subtotal}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('discount')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} - {invoice?.discount}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('Total')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice?.subtotal - invoice?.discount}</span></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('tax')} {invoice?.tax * 100} %</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {Math.round((invoice?.subtotal - invoice?.discount) * invoice?.tax)}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('total')} {t('amount')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {Math.round(invoice?.subtotal - invoice?.discount + ((invoice?.subtotal - invoice?.discount) * invoice?.tax))}</span></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('deposit')} {t('amount')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice?.depositAmount}</span></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('total')} {t('amount')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice?.totalAmount + (invoice?.depositAmount ? invoice?.depositAmount : 0)}</span></td>
                                </tr>


                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('amount')} {t('paid')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice?.totalAmount + (invoice?.depositAmount ? invoice?.depositAmount : 0) - (invoice?.dueAmount > 0 ? invoice?.dueAmount : 0)}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('amount')} {t('remaining')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice.dueAmount == 0 ? 0 : invoice?.dueAmount}</span></td>
                                </tr>

                                {/* <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('tax')} {invoice?.tax * 100} %</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {Math.round((invoice?.subtotal - invoice?.discount) * invoice?.tax)}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('total')} {t('amount')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {Math.round(invoice?.subtotal - invoice?.discount + (invoice?.subtotal * invoice?.tax))}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('deposit')} {t('amount')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice?.depositAmount}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('total')} {t('amount')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice?.totalAmount + (invoice?.depositAmount ? invoice?.depositAmount : 0)}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('amount')} {t('paid')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice?.totalAmount + (invoice?.depositAmount ? invoice?.depositAmount : 0) - (invoice?.dueAmount > 0 ? invoice?.dueAmount : 0)}</span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="light-blue-color">{t('amount')} {t('remaining')}</span></td>
                                    <td><span className="light-blue-color">{profile?.currency} {invoice.dueAmount == 0 ? 0 : invoice?.dueAmount}</span></td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row mb-3 gy-4">
                    <div className="col-md-6">
                        <div className="sp-shadow sp-radius-20 white-bg p-3 h-100">
                            <p className="font-size-18 blue-color fw-semibold text-start">{t('emails')}</p>
                            <div class="table-responsive">
                                <table class="table sp-table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">{t('date')}</th>
                                            <th scope="col">{t('user')}</th>
                                            <th scope="col">{t('type')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoice?.emailCycle?.map((data, i) => (
                                            <tr>
                                                <td style={{ 'verticalAlign': 'middle' }}>{moment(data?.date).format("LLL")}</td>
                                                <td style={{ 'verticalAlign': 'middle' }}>{data?.user}</td>
                                                <td style={{ 'verticalAlign': 'middle' }}>{data?.type}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="sp-shadow sp-radius-20 white-bg p-3">
                            <p className="font-size-18 blue-color fw-semibold text-start">{t('payments')}</p>
                            <div class="table-responsive">
                                <table class="table sp-table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">{t('date')}</th>
                                            <th scope="col">{t('method')}</th>
                                            <th scope="col">{t('payment')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoice?.paymentCycle?.map((data, i) => (
                                            <tr>
                                                <td style={{ 'verticalAlign': 'middle' }}>{moment(data?.date).format("LLL")}</td>
                                                <td style={{ 'verticalAlign': 'middle' }}>{data?.paymentType}</td>
                                                <td style={{ 'verticalAlign': 'middle' }}>{profile?.currency} {data?.paymentAmount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="text-end">
                                <button class="btn sp-button sp-blue-button py-2" data-bs-toggle="modal" data-bs-target="#add-payment">{t('add')} {t('payment')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sp-shadow sp-radius-20 white-bg p-3">
                            <p className="font-size-18 blue-color fw-semibold text-start">{t('commentBox')}</p>
                            <textarea class="form-control border-0 sp-shadow" maxLength={100} id="" value={description} onChange={(e) => setdescription(e.target.value)} rows="4"></textarea>
                            <p className="font-size-12 grey-blue-color mt-2 mb-2 mb-0 text-end">0/100</p>
                            <div className="text-end mb-4">
                                <button class="btn sp-button sp-blue-button py-2" onClick={saveInvoiceDescription}>{t('add')}</button>
                            </div>
                            {invoice?.description?.length > 0 && (<>
                                <p className="font-size-18 blue-color fw-semibold text-start">{t('commentHistory')}</p>
                                {invoice?.description?.map((data, i) => (
                                    <div className="l-blue-bg p-2 mb-1" key={i}>
                                        <div className="d-flex align-items-center gap-2 mb-2 justify-content-between">
                                            <p className="font-size-14 black-color mb-0">{data?.date}</p>
                                            <p className="font-size-14 black-color mb-0">{data?.user}</p>
                                        </div>
                                        <p className="font-size-14 black-color mb-0">{data?.text}</p>
                                    </div>
                                ))}
                            </>)}
                        </div>
                    </div>


                </div>

            </div>

            <div class="modal fade" id="add-payment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" style={{ "--bs-modal-width": "525px" }}>
                    <div class="modal-content sp-modal-content">
                        <div class="modal-header sp-shadow">
                            <h1 class="font-size-26 titilium-font blue-color fw-bold mb-0" id="staticBackdropLabel">{t('add')} {t('payment')}</h1>
                            <button type="button" class="btn-close blue-color" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p className="font-size-16 blue-color mb-2 fw-bold">{t('paymentMethod')}</p>
                            <div className="d-flex flex-wrap gap-2">
                                {paymentMethods?.map((data) => (
                                    <>
                                        <input class="btn-check" type="radio" name="payment-method" id={`${data}`} checked={paymentType?.includes(data)} onChange={() => setpaymentType(data)} />
                                        <label class="btn sp-check-select" for={`${data}`}>{data}</label>
                                    </>
                                ))}
                            </div>
                            <div class="form-floating sp-input mt-3 mb-3">
                                <input type="number" class="form-control" id="floatingInput" placeholder="price"
                                    autocomplete="price" required value={parseFloat(paymentAmount)} onChange={(event) => {
                                        // console.log('first')
                                        let newValue = parseFloat(event.target.value);
                                        if (newValue < 0 || isNaN(newValue)) {
                                            newValue = "";
                                        }
                                        setpaymentAmount(newValue);
                                    }}
                                //   onChange={(e) => setpaymentAmount(e.target.value)} 
                                />
                                <label for="floatingInput">{t('amount')}</label>
                            </div>
                        </div>
                        <div class="modal-footer sp-shadow">
                            <button type="button" class="btn sp-button sp-blue-button py-2" data-bs-dismiss="modal" onClick={handleSubmit}>{t('add')}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default InvoiceDetail