import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { forgetPassword } from "../../store/slices/userSlice";

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [email, setEmail] = useState("");

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            let payload = { email }
            const response = await dispatch(forgetPassword(payload)).unwrap();
            navigate("/verify-account", { state: response?.data });
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    return (
        <>
            <section class="authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-2-strong border-0 rounded">
                                <div class="card-body p-5 text-center">

                                <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}assets/images/logo-new.svg`} />
                                    <h3 className="font-size-24 titilium-font fw-bold light-blue-color mb-1">Forgot your Password?</h3>
                                    <p className="color-black font-size-14 mb-3">Please enter your email address you’d like your password Reset information sent to</p>

                                    <div class="form-floating sp-input mb-3">
                                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                                            onChange={(e) => setEmail(e.target.value)} autocomplete="email" required />
                                        <label for="floatingInput">Your Email Address</label>
                                    </div>
                                    {/* <div class="text-center mt-4">
                                        <button class="btn sp-blue-btn sp-btn btn-lg" onClick={handleClick}>Confirm</button>
                                    </div> */}
                                    <button class="btn sp-button sp-blue-button w-100" onClick={handleClick}>Confirm</button>

                                </div>
                                <div class="card-footer sp-auth-card-footer">
                                    <div className="text-center">
                                        <a
                                            className=""
                                            onClick={() => navigate("/login")}
                                        >
                                            <b className="light-blue-color">Back to Log in</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ForgetPassword;