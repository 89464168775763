import ContextProvider from "./context/context"
import { useDispatch } from 'react-redux'
import { getByBusinessData, token } from "./store/slices/userSlice"

import './App.css';
import { useEffect } from "react";
import Layout from "./components/Layout/Layout";
import { setSocket } from "./store/slices/socketSlice";
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, messaging } from "./firebase"
import { onMessage } from "firebase/messaging"


function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    try {
      dispatch(token())
      dispatch(setSocket())
      requestForToken();
      onMessage(messaging, (payload) => {
        toast(payload.notification.body)
        console.log("foreground", payload)
      })

    } catch (error) {
      console.log(error)
    }
  }, [])


  return (

    <ContextProvider >
      <Layout />
    </ContextProvider>

  );
}

export default App;
