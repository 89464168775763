import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
 import {  addNews, getBusiness, getByBusinessData,   getProfile, getSpacesForDropDownPrivate, } from "../../store/slices/userSlice";
 import { useNavigate } from "react-router-dom";
import { Camera } from "lucide-react";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";


const AddNews = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const navigate = useNavigate()
    const [images, setImages] = useState([]);
    const [prev_images, setprev_images] = useState([]);
    const [name, setname] = useState("");
    const [type, settype] = useState("");
   
 
    const [description, setdescription] = useState("");
   
  
    const [getAllSpaces, setgetAllSpaces] = useState([]);
    const [spaces, setspaces] = useState([]);
    const [load, setload] = useState(false);


 
 
    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

 


    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness();
      

        }
        return () => {
            mount = false;
        };
    }, [])

 

    const handleImageUpload = (event) => {

        const selectedImages = event.target.files;
        const imagesArray = [];

        for (let i = 0; i < selectedImages.length; i++) {
            imagesArray.push(selectedImages[i]);
        }
        setImages(images.concat(imagesArray));
    };


    const handleImageRemovePrev = (e, index) => {
        e.preventDefault();
        const updatedImages = [...prev_images];
        updatedImages.splice(index, 1);
        setprev_images(updatedImages);
    };

    const handleImageRemove = (e, index) => {
        e.preventDefault();
        const updatedImages = [...images];
        updatedImages.splice(index, 1);

        setImages(updatedImages);
    };

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            setload(true);
            if (!name) {
                toast.error('News Title is required');
                setload(false);
                return;
            }
            if (!spaces == 1) {
                toast.error('Locations are required');
                setload(false);
                return;
            }
            if (!type) {
                toast.error('News Type is required');
                setload(false);
                return;
            }
            const form = new FormData();
            form.append("name", name);
            form.append("type", type);
            form.append("description", description);
            form.append("spaces", JSON.stringify(spaces));
            form.append("businessId", business ? business._id : profile.businessId);
            images.forEach((item) => {
                form.append("newsImage", item);
            });
            await dispatch(addNews(form)).unwrap();

            setname("")
            setdescription("")
         
       
            setspaces("")
            setImages("")
            setload(false);
            navigate("/news")
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getSpaces = async () => {
        try {
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
            setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getSpaces();
        }
        return () => {
            mount = false;
        };
    }, [business, profile])

    const handleCheckboxChangeForLocation = (text) => {
        const updatedCheckboxes = spaces.includes(text)
            ? spaces.filter((item) => item !== text)
            : [...spaces, text];

        setspaces(updatedCheckboxes);
    };

    const types = [
        {
            id: 1,
            name: 'Trending',
        },
        {
            id: 2,
            name: 'Announcements',
        },
        {
            id: 3,
            name: 'News',
        },
    ]

    return (
        <>
        {load ? <Spinner /> : <></>}
            <div className="sp-add-location-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/news")}>{t('news')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('add')} {t('news')}</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/news-icon-sp.svg`} className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('add')} {t('news')}</h2>
                </div>


                <div className="mb-4">
                    <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('newsTitle')}</label>
                    <div class="form-floating sp-input mb-3">
                        <input type="text" class="form-control" id="News-Title" maxLength={100} value={name} onChange={(e) => setname(e.target.value)} placeholder="News Title" />
                        <label for="News-Title">{t('newsTitle')}</label>
                    </div>

                    <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('location')}</label>
                    <div className="d-flex gap-3 flex-wrap mb-3">
                        {getAllSpaces?.map((data, i) => (
                            <>
                                <input class="btn-check" type="checkbox" name="space" value={spaces} id={data?._id} key={i} checked={spaces.includes(data._id)} onChange={() => handleCheckboxChangeForLocation(data._id)} />
                                <label class="btn sp-check-select" for={data?._id}>{data.name}</label>
                            </>
                        ))}
                    </div>
                    <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('type')}</label>
                    <div className="d-flex gap-3 flex-wrap mb-3">
                        {types?.map((data, i) => (
                            <>
                                <input class="btn-check" type="radio" name="type" id={`type-${data?.id}`} key={i} onChange={() => settype(data.name)} />
                                <label class="btn sp-check-select" for={`type-${data?.id}`}>{data.name}</label>
                            </>
                        ))}
                    </div>

                    <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('description')}</label>
                    <div class="form-floating sp-textarea mb-3">
                        <textarea class="form-control" placeholder="Leave a comment here" maxLength={300} id="Description" style={{ height: '100px' }} value={description} onChange={(e) => setdescription(e.target.value)}></textarea>
                        <label for="Description">{t('description')}</label>
                    </div>


                    <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('attachments')}</label>
                    <div className="upload__img-wrap">
                        {prev_images.map((image, index) => (
                            <div className="upload__img-box" key={index}>
                                <div className="img-bg">
                                    <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt={`Store Image`} className="img-bg-size" />
                                    <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                                </div>
                            </div>
                        ))}

                        {
                            images?.map((image, index) => (
                                <div className="upload__img-box" key={index}>
                                    <div className="img-bg">
                                        <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                                        <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                                    </div>
                                </div>
                            ))
                        }
                        <input type="file" class="btn-check" id="btn-check" multiple data-max_length="10" onChange={handleImageUpload} accept=".png, .jpg, .jpeg" />
                        <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> {t('addphotos')}</label>
                    </div>




                </div>
                <div className="text-center">
                    <button class="btn sp-button sp-blue-button" onClick={handleClick}>{t('save')}</button>
                </div>
            </div>

        </>
    )
}

export default AddNews