import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getBusiness, getByBusinessData, getAllRooms, getProfile, getUserRight, getSpacesForDropDownPrivate } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Eye, Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Rooms = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const userRights = useSelector(getUserRight)
    const [load, setload] = useState(false);
    const [toggle, settoggle] = useState(false);

    const [listOfRooms, setlistOfRooms] = useState([])
    const [getAllSpaces, setgetAllSpaces] = useState([]);

    const getSpaces = async () => {
        try {
          let payload = {
            businessId: business ? business?._id : null,
            assignSpace: profile ? profile?.assignedSpaces : [],
            isAllSpaces: profile ? profile?.isAllSpaces : null,
            businessId2: profile ? profile?.businessId : null,
          }
    
          const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
          setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      };




    const [nameFilter, setNameFilter] = useState('');
    const [typeFilter, setypeFilter] = useState('');
    const [locationFilter, setlocationFilter] = useState('');
    const [capacityFilter, setcapacityFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);


    const applyFilter = () => {
        const filteredResult = listOfRooms?.filter(item => {
            const name = item?.name?.toLowerCase();
            const type = item?.type?.toLowerCase();
            const capacity = item?.capacity.toString()
            const location = item?.spaceDetail?.name?.toLowerCase();


            return (
                (nameFilter === '' || name?.includes(nameFilter.toLowerCase())) &&
                (typeFilter === '' || type?.includes(typeFilter.toLowerCase())) &&
                (capacityFilter === '' || capacity?.includes(capacityFilter.toString())) &&
                (locationFilter === '' || location?.includes(locationFilter.toLowerCase()))

            );
        });

        setFilteredData(filteredResult);
        settoggle(false);
    };

    console.log(filteredData)

    const resetFilter = () => {
        setNameFilter('');
        setypeFilter('')
        setlocationFilter('')
        setcapacityFilter('')
        setSearchValue(''); 
        setSearchValueLocation(''); 
        setFilteredData(listOfRooms)
        settoggle(false);
    };




    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getRoomList = async () => {
        try {
            setload(true);
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }
            const response = await dispatch(getAllRooms(payload)).unwrap();
            setlistOfRooms(response?.data)
            setFilteredData(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness();
        }
        return () => {
            mount = false;
        };
    }, [])

    useEffect(() => {
        let mount = true;
        if (mount) {
            getRoomList();
            getSpaces();

        }
        return () => {
            mount = false;
        };
    }, [business, profile])

    

    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [searchValueLocation, setSearchValueLocation] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value.length > 0) {
          setShowDropdown(true);
        } else {
          setShowDropdown(false);
        }
      };
      const handleOptionClick = (data) => {
        setSearchValue(data?.name); 
        setNameFilter(data?.name); 
        setShowDropdown(false); 
      };

      const handleInputChange1 = (e) => {
        const value = e.target.value;
        setSearchValueLocation(value);
        if (value.length > 0) {
          setShowDropdown1(true);
        } else {
          setShowDropdown1(false);
        }
      };
      const handleOptionClick1 = (data) => {
        setSearchValueLocation(data?.name); 
        setlocationFilter(data?.name); 
        setShowDropdown1(false); 
      };

      const roomTypes = ["Private Room", "Meeting Room", "Podcast Room", "Executive Cabin", "Huddle Room", "Event Space", "Resources"];


    return (
        <>
            <div className="sp-rooms-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('spaces')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('rooms')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                    <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                        <img height="32" src="./assets/images/spaces-icon-sp.svg" className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('rooms')}</h2>
                    </div>
                    <div className="col-md-6 order-md-2 order-3">
                        <div class="dropdown sp-search-dropdown">
                            <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
                                <span>{t('filterAndSearch')}</span>
                                <span>
                                {toggle ? <X /> : <Search />}
                                </span>
                            </a>

                            <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
                            <div class="row mb-3 sp-input-filter">
                                <label for="inputname" class="col-sm-2 col-form-label">{t('name')}</label>
                                <div class="col-sm-10">
                                    <input type="text" onChange={(e) => handleInputChange(e)} value={searchValue} placeholder="Filter by Room Name" class="form-control" id="inputname" />
                                    {showDropdown && (
                                        <div className="dropdown">
                                        <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                            {listOfRooms?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                                                <li key={index} onClick={() => handleOptionClick(data)}><a class="dropdown-item" >{data?.name}</a></li>
                                            ))}
                                            {listOfRooms?.length > 0 && listOfRooms?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                                            <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                            )}
                                        </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div class="row mb-3 sp-input-filter">
                                    <label for="inputemail" class="col-sm-2 col-form-label">{t('location')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={(e) => handleInputChange1(e)} value={searchValueLocation} placeholder="Filter by Location" class="form-control" id="inputemail" />
                                        {showDropdown1 && (
                                        <div className="dropdown">
                                        <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                            {getAllSpaces?.filter((data) => data?.name?.toLowerCase()?.includes(searchValueLocation?.toLowerCase()))?.map((data, index) => (
                                                <li key={index} onClick={() => handleOptionClick1(data)}><a class="dropdown-item" >{data?.name}</a></li>
                                            ))}
                                            {getAllSpaces?.length > 0 && getAllSpaces?.filter((data) => data?.name?.toLowerCase()?.includes(searchValueLocation?.toLowerCase()))?.length === 0 && (
                                            <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                            )}
                                        </ul>
                                        </div>
                                    )}
                                    </div>
                            </div>
                            <div class="row mb-3 sp-input-filter">
                                <label for="inputemail" class="col-sm-2 col-form-label">{t('type')}</label>
                                <div className="col-sm-10 d-flex flex-wrap gap-2 mb-3">
                                    {roomTypes.map((type, i) => (
                                        <React.Fragment key={i}>
                                        <input
                                            className="btn-check"
                                            type="radio"
                                            value={type}
                                            id={`room-type-${i}`}
                                            checked={typeFilter === type}
                                            onChange={(e) => setypeFilter(e.target.value)}
                                        />
                                        <label className="btn sp-check-select" htmlFor={`room-type-${i}`}>
                                            {type}
                                        </label>
                                        </React.Fragment>
                                    ))}
                                    </div>
                            </div>
                                <div className="d-flex gap-2 justify-content-end">
                                    <button class="btn sp-button sp-blue-button" onClick={applyFilter}>{t('search')}</button>
                                    <button class="btn sp-button sp-blue-button" onClick={resetFilter}>{t('reset')}</button>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                        {userRights?.Spaces?.create == 1 || userRights == null ? <button class="btn sp-button sp-blue-button" onClick={() => navigate("/rooms/add-room")}>{t('addRoom')}</button> : <></>}
                    </div>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg">
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">{t('name')}</th>
                                    <th scope="col">{t('capacity')}</th>
                                    <th scope="col">{t('roomSize')}</th>
                                    <th scope="col">{t('location')}</th>
                                    <th scope="col">{t('type')}</th>
                                    <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((data, i) => (
                                    <tr>
                                        <td>{data?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.capacity}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.roomSize}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.spaceDetail?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.type}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                            <div onClick={() => navigate("/rooms/room-detail", { state: data })} className={`sp-action-icons`}><Eye width="18" /></div>
                                                {userRights?.Spaces?.edit == 1 || userRights == null ? <div onClick={() => navigate("/rooms/edit-room", { state: data })} className={`sp-action-icons`}><Pencil width="18" /></div> : <></>}
                                                {userRights?.Spaces?.delete == 1 || userRights == null ? <div className={`sp-action-icons`}><X width="18" /></div> : <></>}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Rooms