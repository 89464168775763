import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpacesForDropDown, addDesk, getBusiness, getByBusinessData, getPlans, getProfile, getSpacesForDropDownPrivate } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { Camera } from "lucide-react";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";

const AddDesk = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [images, setImages] = useState([]);
  const [prev_images, setprev_images] = useState([]);
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [deskCount, setdeskCount] = useState("");
  const [deskSize, setdeskSize] = useState("");
  const [type, settype] = useState("");
  const [presentDesk, setpresentDesk] = useState("");
  const [getAllSpaces, setgetAllSpaces] = useState([]);
  const [getAllPlans, setgetAllPlans] = useState([]);
  const [spaceId, setspaceId] = useState("");
  const [plans, setplans] = useState([]);
  const [load, setload] = useState(false);

  const dispatch = useDispatch();
  const business = useSelector(getBusiness)
  const profile = useSelector(getProfile)


  const [allPlans, setallPlans] = useState([
    {
      name: '',
      description: '',
      autorenewal: 0,
      multiplequantity: 0,
      pricing: [
        { type: 'Weekly', price: 0 },
        { type: 'Monthly', price: 0 },
        { type: 'Yearly', price: 0 },
      ],
    },
  ]);


  const handleInputChange = (index, key, value) => {
    const newPlans = [...allPlans];
    newPlans[index][key] = value;
    setallPlans(newPlans);
  };

  const handlePricingInputChange = (index, pricingIndex, key, value) => {
    const newAllPlans = [...allPlans];
    newAllPlans[index].pricing[pricingIndex][key] = parseFloat(value); // Convert price to a number
    setallPlans(newAllPlans);
  };

  const handleCheckboxChange = (index, key, checked) => {
    const newAllPlans = [...allPlans];
    newAllPlans[index][key] = checked ? 1 : 0;
    setallPlans(newAllPlans);
  };


  const handleAddPlan = () => {
    setallPlans([
      ...allPlans,
      {
        name: '',
        description: '',
        autorenewal: 0,
        multiplequantity: 0,
        deposit: 0,
        pricing: [
          { type: 'Weekly', price: 0 },
          { type: 'Monthly', price: 0 },
          { type: 'Yearly', price: 0 },
        ],
      },
    ]);
  };

  const handleRemovePlan = (index) => {
    if (allPlans.length > 1) {
      const newAllPlans = [...allPlans];
      newAllPlans.splice(index, 1);
      setallPlans(newAllPlans);
    }
  };

  console.log(allPlans)

  const getMyBusiness = async () => {
    try {
      await dispatch(getByBusinessData()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const handleChangeForLocation = (event) => {
    setspaceId(event.target.value);

  };

  const getPlansForDropDown = async () => {
    try {
      const response = await dispatch(getPlans({ spaceId, type: "Desk" })).unwrap();
      setgetAllPlans(response?.data)
      setplans([])

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const getSpaces = async () => {
    try {
      let payload = {
        businessId: business ? business?._id : null,
        assignSpace: profile ? profile?.assignedSpaces : [],
        isAllSpaces: profile ? profile?.isAllSpaces : null,
        businessId2: profile ? profile?.businessId : null,
      }

      const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
      setgetAllSpaces(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (spaceId) {
      getPlansForDropDown()

    }
  }, [spaceId]);

  useEffect(() => {
    let mount = true;
    if (mount) {
      getMyBusiness();

    }
    return () => {
      mount = false;
    };
  }, [])


  useEffect(() => {
    let mount = true;
    if (mount) {

      getSpaces();
    }
    return () => {
      mount = false;
    };
  }, [business, profile])






  const handleImageUpload = (event) => {

    const selectedImages = event.target.files;
    const imagesArray = [];

    for (let i = 0; i < selectedImages.length; i++) {
      imagesArray.push(selectedImages[i]);
    }
    setImages(images.concat(imagesArray));
  };

  const handleImageRemovePrev = (e, index) => {
    e.preventDefault();
    const updatedImages = [...prev_images];
    updatedImages.splice(index, 1);
    setprev_images(updatedImages);
  };

  const handleImageRemove = (e, index) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);

    setImages(updatedImages);
  };



  function validatePlans(plans) {
    const uniqueNames = new Set();

    for (const plan of plans) {
      let key, value; // Declare key and value outside the loop

      for (key in plan) {
        if (plan.hasOwnProperty(key)) {
          value = plan[key];

          if (value === null || value === undefined || value === '') {
            toast.error(`Kindly complete plan - ${key} cannot be empty`);
            throw new Error(`Kindly complete plan - ${key} cannot be empty`);
          }

          if (Array.isArray(value) && value.length === 0) {
            toast.error(`Kindly complete plan - ${key} cannot be an empty array`);
            throw new Error(`Kindly complete plan - ${key} cannot be an empty array`);
          }
        }
      }

      // Check if the name is unique (case-insensitive)
      const caseSensitiveName = plan.name.toLowerCase();
      if (uniqueNames.has(caseSensitiveName)) {
        toast.error(`Plan name cannot be the same for multiple plans`);
        throw new Error(`Plan name cannot be the same for multiple plans`);
      }
      uniqueNames.add(caseSensitiveName);

      // Additional validations can be added here if needed
      if (key === 'pricing' && Array.isArray(value) && value.every(item => item.price === 0)) {
        toast.error('Any of pricing should have a value');
        throw new Error('Any of pricing should have a value');
      }

      if (key === 'pricing' && Array.isArray(value) && value.length === 1 && value[0].price === 0) {
        toast.error('Any of pricing should have a value');
        throw new Error('Any of pricing should have a value');
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setload(true);
      if (!name) {
        toast.error('Name is required');
        setload(false);
        return;
      }
      if (!deskCount) {
        toast.error('Desk Count is required');
        setload(false);
        return;
      }
      if (!spaceId) {
        toast.error('Location is required');
        setload(false);
        return;
      }
      if (!type) {
        toast.error('Desk Type is required');
        setload(false);
        return;
      }
      if (!images.length == 1) {
        toast.error('Images are required');
        setload(false);
        return;
      }
      validatePlans(allPlans);
      const form = new FormData();
      form.append("name", name);
      form.append("description", description);
      form.append("deskCount", deskCount);
      form.append("presentDesk", presentDesk);
      form.append("deskSize", deskSize);
      form.append("businessId", profile?.businessId ? profile?.businessId : business._id);
      form.append("spaceId", spaceId);
      form.append("type", type);
      form.append("allPlans", JSON.stringify(allPlans));
      images.forEach((item) => {
        form.append("deskImage", item);
      });

      await dispatch(addDesk(form)).unwrap();
      setname("")
      setdescription("")
      setdeskCount("")
      setdeskSize("")
      setspaceId("")
      setImages([])
      setload(false);
      navigate("/desks")

    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  // const types = ["Hot Desk", "Dedicated Desk", "Shared Desk"]
  const types = ["Hot Desk", "Dedicated Desk" ]

  return (
    <>
      {load ? <Spinner /> : <></>}
      <div className="sp-add-location-page pb-5 px-md-5 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
            <li class="breadcrumb-item"><a>{t('menu')}</a></li>
            <li class="breadcrumb-item"><a>{t('spaces')}</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/desks")}>{t('desk')}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{t('add')} {t('desk')}</li>
          </ol>
        </nav>
        <div className="d-flex align-items-center gap-2 mb-4">
          <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/spaces-icon-sp.svg`} className="icon-text" />
          <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('add')} {t('desk')}</h2>
        </div>
        <div className="mb-4">
          <div className="row gy-3 mb-3">
            <div className="col-md-6">
              <div class="form-floating sp-input mb-3">
                <input type="text" class="form-control" id="name" maxLength={40} placeholder="Name" value={name} onChange={(e) => setname(e.target.value)} />
                <label for="name">{t('name')}*</label>
              </div>
              <div class="form-floating sp-textarea mb-3">
                <textarea class="form-control" placeholder="Leave a comment here" maxLength={300} id="Description" style={{ height: '100px' }} value={description} onChange={(e) => setdescription(e.target.value)}></textarea>
                <label for="Description">{t('description')}</label>
              </div>
              <div class="form-floating sp-input mb-3">
                <input type="number" class="form-control" id="Count-Desks" maxLength={4} placeholder="Count / Desks" value={parseFloat(deskCount)} onChange={(event) => {

                  let newValue = parseFloat(event.target.value);
                  if (newValue < 0 || isNaN(newValue)) {
                    newValue = "";
                  }
                  setdeskCount(newValue);
                }} />
                <label for="Count-Desks">{type == "Hot Desk" ? "No of Bookings" : t('countDesks')}</label>
              </div>
              {type == "Hot Desk" && <div class="form-floating sp-input mb-3">
                <input type="number" class="form-control" id="Count-Desks" maxLength={4} placeholder="Count / Desks" value={parseFloat(presentDesk)} onChange={(event) => {

                  let newValue = parseFloat(event.target.value);
                  if (newValue < 0 || isNaN(newValue)) {
                    newValue = "";
                  }
                  setpresentDesk(newValue);
                }} />
                <label for="Count-Desks">Present Desk</label>
              </div>}
              <div class="form-floating sp-input mb-3">
                <input type="number" class="form-control" id="Desks Size / Sq ft" maxLength={5} placeholder="Desks-Size" value={parseFloat(deskSize)} onChange={(event) => {
                  // console.log('first')
                  let newValue = parseFloat(event.target.value);
                  if (newValue < 0 || isNaN(newValue)) {
                    newValue = "";
                  }
                  setdeskSize(newValue);
                }} />
                <label for="Desks-Size">{t('desksSizeSqft')}</label>
              </div>
            </div>
            <div className="col-md-6">
              <div class="mb-3">
                <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('location')}</label>
                <div className="d-flex gap-3 flex-wrap">
                  {getAllSpaces?.map((data, i) => (
                    <>
                      <input class="btn-check" type="checkbox" value={spaceId} id={data?._id} key={i} checked={spaceId.includes(data._id)} onClick={() => setspaceId(data?._id)} />
                      <label class="btn sp-check-select" for={data?._id}>{data.name}</label>
                    </>
                  ))}
                </div>
              </div>
              <div class="mb-3">
                <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('type')}</label>
                <div className="d-flex gap-3 flex-wrap">
                  {types.map((ty, i) => (
                    <>
                      <input class="btn-check" type="radio" name="ty" id={`type-${ty}`} onChange={() => settype(ty)} key={i} />
                      <label style={{ width: '120px' }} class="btn sp-check-select d-flex flex-column gap-3 justify-content-center align-items-center" for={`type-${ty}`}>
                        <img src={`../assets/images/white-${ty.replace(/[\/\s]+/g, '-').toLowerCase()}-icon-sp.svg`} alt={ty} className="sp-white-icon" />
                        <img src={`../assets/images/${ty.replace(/[\/\s]+/g, '-').toLowerCase()}-icon-sp.svg`} alt={ty} className="sp-color-icon" />
                        {ty}
                      </label>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="upload__img-wrap mb-4">
            {prev_images?.map((image, index) => (
              <div className="upload__img-box" key={index}>
                <div className="img-bg">
                  <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt={`Store Image`} className="img-bg-size" />
                  <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                </div>
              </div>
            ))}

            {images?.map((image, index) => (
              <div className="upload__img-box" key={index}>
                <div className="img-bg">
                  <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                  <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                </div>
              </div>
            ))}
            <input type="file" class="btn-check" id="btn-check" multiple data-max_length="10" onChange={handleImageUpload} accept=".png, .jpg, .jpeg" />
            <label class="upload__btn" for="btn-check"><Camera strokeWidth={1} /> {t('addphotos')}</label>
          </div>
          <p className="font-size-24 titilium-font fw-bold blue-color mb-2">{t('add')} {t('plan')}</p>
          {allPlans?.map((plan, index) => (
            <div key={index}>
              <div className="d-flex justify-content-between">
                <p className="font-size-16 titilium-font fw-bold blue-color mb-2">{t('plan')} # {index + 1}</p>
                <p className="font-size-16 titilium-font fw-bold blue-color mb-2 cursor-pointer" onClick={handleRemovePlan}>{t('remove')}</p>
              </div>
              <div className="row gy-3">
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="text" class="form-control" id="name" maxLength={40} placeholder="Name" value={plan.name} onChange={(e) => handleInputChange(index, 'name', e.target.value)} />
                    <label for="name">{t('name')}*</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="number" class="form-control" id="Deposit" placeholder="Deposit" value={parseFloat(plan.deposit)} onChange={(event) => {
                      // console.log('first')
                      let newValue = parseFloat(event.target.value);
                      if (newValue < 0 || isNaN(newValue)) {
                        newValue = "";
                      }
                      handleInputChange(index, 'deposit', newValue);
                    }} />
                    <label for="Deposit">{t('deposit')}</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div class="form-floating sp-textarea ">
                    <textarea class="form-control" placeholder="Leave a comment here" id="Description" maxLength={300} style={{ height: '100px' }} value={plan.description} onChange={(e) => handleInputChange(index, 'description', e.target.value)}></textarea>
                    <label for="Description">{t('description')}</label>
                  </div>
                </div>
                <div className="col-md-12 d-flex flex-wrap gap-3">
                  <input type="checkbox" class="btn-check" checked={plan.autorenewal === 1} onChange={(e) => handleCheckboxChange(index, 'autorenewal', e.target.checked)} id={`${index + 1}-Auto-Renewal`} />
                  <label className="btn sp-check-select" for={`${index + 1}-Auto-Renewal`}>{t('autoRenewal')}</label>

                  <input type="checkbox" class="btn-check" checked={plan.multiplequantity === 1} onChange={(e) => handleCheckboxChange(index, 'multiplequantity', e.target.checked)} id={`${index + 1}-Multiple-Quantity`} />
                  <label className="btn sp-check-select" for={`${index + 1}-Multiple-Quantity`}>{t('multipleQuantity')}</label>
                </div>
                {plan.pricing.map((pricingItem, pricingIndex) => (
                  <div key={pricingIndex} className="col-md-4">
                    <div class="form-floating sp-input mb-3">
                      <input type="number" class="form-control" id={pricingItem.type} placeholder={pricingItem.type} value={parseFloat(pricingItem.price.toString())} onChange={(event) => {
                        // console.log('first')
                        let newValue = parseFloat(event.target.value);
                        if (newValue < 0 || isNaN(newValue)) {
                          newValue = "";
                        }
                        handlePricingInputChange(index, pricingIndex, 'price', newValue);
                      }} />
                      <label for={pricingItem.type}>{pricingItem.type}</label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <button class="btn sp-button sp-blue-button py-2" onClick={handleAddPlan}>{t('addMore')}</button>


        </div>

        <div className="text-center">
          <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>{t('save')}</button>
        </div>
      </div>
    </>
  )
}


export default AddDesk