import React, { useState, useEffect } from 'react';
import { allSupportCategory, getBusiness, getProfile, getSpaceAndCategoryCombination, getSpacesForDropDownPrivate, updateCombination } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TicketLog = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profile = useSelector(getProfile)
    const business = useSelector(getBusiness)




    const [userMapping, setUserMapping] = useState([]);
    const [getAllSpaces, setgetAllSpaces] = useState([]);
    const [listOfCategory, setlistOfCategory] = useState([]);
    const [load, setload] = useState(false);

    const fetchData = async () => {
        try {

            const response = await dispatch(getSpaceAndCategoryCombination(business ? business?._id : profile?.businessId)).unwrap();

            setUserMapping(response?.data);
        } catch (error) {
            console.error('Error fetching user mapping:', error);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {

            fetchData();

        }
        return () => {
            mount = false;
        };
    }, [business, profile]);

    console.log(userMapping)

    const getSpaces = async () => {
        try {
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
            setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const getAllCategory = async () => {
        try {
            setload(true);
            const response = await dispatch(allSupportCategory(business ? business?._id : profile?.businessId)).unwrap();
            setlistOfCategory(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };
    useEffect(() => {
        let mount = true;
        if (mount) {

            getSpaces();
            getAllCategory()
        }
        return () => {
            mount = false;
        };
    }, [business, profile])

    const organizedData = userMapping.reduce((acc, user) => {
        const { spaceId, categoryId, userId, username, isActive, role } = user;

        if (!acc[categoryId]) {
            acc[categoryId] = {};
        }

        if (!acc[categoryId][spaceId]) {
            acc[categoryId][spaceId] = [];
        }

        acc[categoryId][spaceId].push({ userId, username, isActive, role });

        return acc;
    }, {});

    console.log(organizedData)
    const handleSelect = async (spaceId, categoryId, userId) => {

        try {

            const response = await dispatch(updateCombination({ spaceId, categoryId, userId })).unwrap();


        } catch (rejectedValueOrSerializedError) {

            console.log(rejectedValueOrSerializedError);
        }
        // Do something with spaceId, categoryId, userId

    };
    return (
        <>
        <div className="sp-add-ticket-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb sp-breadcrumb">
                  <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                  <li class="breadcrumb-item"><a onClick={() => navigate("/support-tickets")}>{t('technicalSupport')}</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{t('ticketLog')}</li>
                </ol>
            </nav>
            <div className="d-flex align-items-center gap-2 mb-4">
            <img height="32" src="./assets/images/it-icon-sp.svg" className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('ticketLog')}</h2>
          </div>
          <div className="sp-shadow sp-radius-20 white-bg">
                <div class="table-responsive">
                    <table class="table sp-table table-borderless">
                    <thead>
                    <tr>
                    <th scope="col">{t('category')}</th>
                    {getAllSpaces?.map(space => (
                        <th scope="col" key={space._id}>{space.name}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={9}><div style={{height:'35px'}} className='w-100 shimmer'></div></td></tr>)) : listOfCategory?.map(category => (
                    <tr key={category._id}>
                        <td>{category.name}</td>
                        {getAllSpaces?.map(space => (
                            <td key={space._id}>
                                <select  class="form-select sp-select"
                                    onChange={(e) => handleSelect(space._id, category._id, e.target.value)}
                                >
                                    {organizedData[category._id] && organizedData[category._id][space._id] ? (
                                        <>
                                            {organizedData[category._id][space._id].map(user => (
                                                <option
                                                    key={user.userId}
                                                    value={user.userId}
                                                    selected={user.isActive === 1}
                                                >
                                                    {user?.username} -{user?.role}
                                                </option>
                                            ))}
                                            {organizedData[category._id][space._id].every(user => user.isActive === 0) && (
                                                <option value="" disabled selected>Please select user</option>
                                            )}
                                        </>
                                    ) : (
                                        <option value="" disabled selected>Please select user</option>
                                    )}
                                </select>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
                    </table>
                </div>
            </div>
            

   
        </div>
            
        </>
    );
};

export default TicketLog;
