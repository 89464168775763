import React, { useEffect, useState } from 'react'
import { getByBusinessData, getBusiness, getProfile, userList, getUserRight, getMailAndPackage, getSpacesForDropDownPrivate } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Eye, Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';


const MailAndPackages = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const userRights = useSelector(getUserRight)
 
    const [listOfMailPackage, setlistOfMailPackage] = useState([]);
    const [load, setload] = useState(false);
    const [toggle, settoggle] = useState(false);



    const [nameFilter, setNameFilter] = useState('');
    const [locationFilter, setlocationFilter] = useState('');
    const [selectedType, setselectedType] = useState([]);
    const [selectedFinalStatus, setselectedFinalStatus] = useState([]);
    const [memberFilter, setmemberFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [getAllSpaces, setgetAllSpaces] = useState([]);

    const getSpaces = async () => {
        try {
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
            setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const applyFilter = () => {
        const filteredResult = listOfMailPackage?.filter(item => {
            const userStatus = item?.type;
            const finalStatus = item?.finalStatus;
            const location = item?.spaces?.name?.toLowerCase();
            const member = item?.memberId?.name?.toLowerCase();


            return (
                (locationFilter === '' || location.includes(locationFilter.toLowerCase())) &&
                (memberFilter === '' || member.includes(memberFilter.toLowerCase())) &&
                (selectedType?.length === 0 || selectedType?.includes(userStatus)) &&
                (selectedFinalStatus?.length === 0 || selectedFinalStatus?.includes(finalStatus))
            );
        });

        setFilteredData(filteredResult);
        settoggle(false);
    };

    const handleType = (status) => {

        if (selectedType.includes(status)) {
            setselectedType(prevStatuses => prevStatuses.filter(s => s !== status));
        } else {
            setselectedType(prevStatuses => [...prevStatuses, status]);
        }
    };
    
    const handleStatus = (status) => {

        if (selectedFinalStatus.includes(status)) {
            setselectedFinalStatus(prevStatuses => prevStatuses.filter(s => s !== status));
        } else {
            setselectedFinalStatus(prevStatuses => [...prevStatuses, status]);
        }
    };

    const resetFilter = () => {
        setNameFilter('');
        setSearchValueLocation('');
        setlocationFilter('');
        setSearchValue('');
        setmemberFilter('');
        setselectedType([]);
        setFilteredData(listOfMailPackage)
        settoggle(false);
    };


    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    const getListOfMailPackage = async () => {
        try {
            setload(true);
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }
            const response = await dispatch(getMailAndPackage(payload)).unwrap();
            setlistOfMailPackage(response?.data)
            setFilteredData(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

 
    useEffect(() => {
        let mount = true;
        if (mount) {
            if (business) {
                getListOfMailPackage()
                getSpaces()
            }
        }
        return () => {
            mount = false;
        };
    }, [business])

    useEffect(() => {
        let mount = true;
        if (mount) {
            if (profile) {
                getListOfMailPackage()
                getSpaces()
            }
        }
        return () => {
            mount = false;
        };
    }, [profile])


    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness()
        }
        return () => {
            mount = false;
        };
    }, [])

    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [searchValueLocation, setSearchValueLocation] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value.length > 0) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };
    const handleOptionClick = (data) => {
        setSearchValue(data?.memberId?.name);
        setmemberFilter(data?.memberId?.name);
        setShowDropdown(false);
    };

    const handleInputChange1 = (e) => {
        const value = e.target.value;
        setSearchValueLocation(value);
        if (value.length > 0) {
            setShowDropdown1(true);
        } else {
            setShowDropdown1(false);
        }
    };
    const handleOptionClick1 = (data) => {
        setSearchValueLocation(data?.name);
        setlocationFilter(data?.name);
        setShowDropdown1(false);
    };

    const types = [
        {
            id: 1,
            name: 'Envelopes',
        },
        {
            id: 2,
            name: 'Small Parcel',
        },
        {
            id: 3,
            name: 'Large Parcel',
        },
        {
            id: 4,
            name: 'Oversized Parcel',
        },
        {
            id: 5,
            name: 'Temperature Sensitive',
        },
        {
            id: 6,
            name: 'Custom Package',
        }
    ]

    return (
        <div className="sp-customer-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb sp-breadcrumb">
                    <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('mailAndPackages')}</li>
                </ol>
            </nav>

            <div className="row gy-3 mb-4 align-items-center">
                <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                    <img height="32" src="./assets/images/mail-packages-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('mailAndPackages')}</h2>
                </div>
                <div className="col-md-6 order-md-2 order-3">
                    <div class="dropdown sp-search-dropdown">
                        <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
                            <span>{t('filterAndSearch')}</span>
                            <span>
                                {toggle ? <X /> : <Search />}
                            </span>
                        </a>

                        <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
                            <div class="row mb-3 sp-input-filter">
                                <label for="inputname" class="col-sm-2 col-form-label">{t('member')}</label>
                                <div class="col-sm-10">
                                    <input type="text" onChange={(e) => handleInputChange(e)} value={searchValue} placeholder="Filter by Member Name" class="form-control" id="inputname" />
                                    {showDropdown && (
                                        <div className="dropdown">
                                            <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                {listOfMailPackage?.filter((data) => data?.memberId?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                                                    <li key={index} onClick={() => handleOptionClick(data)}><a class="dropdown-item" >{data?.memberId?.name}</a></li>
                                                ))}
                                                {listOfMailPackage?.length > 0 && listOfMailPackage?.filter((data) => data?.memberId?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                                                    <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div class="row mb-3 sp-input-filter">
                                <label for="inputemail" class="col-sm-2 col-form-label">{t('location')}</label>
                                <div class="col-sm-10">
                                    <input type="text" onChange={(e) => handleInputChange1(e)} value={searchValueLocation} placeholder="Filter by Location" class="form-control" id="inputemail" />
                                    {showDropdown1 && (
                                        <div className="dropdown">
                                            <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                {getAllSpaces?.filter((data) => data?.name?.toLowerCase()?.includes(searchValueLocation?.toLowerCase()))?.map((data, index) => (
                                                    <li key={index} onClick={() => handleOptionClick1(data)}><a class="dropdown-item" >{data?.name}</a></li>
                                                ))}
                                                {getAllSpaces?.length > 0 && getAllSpaces?.filter((data) => data?.name?.toLowerCase()?.includes(searchValueLocation?.toLowerCase()))?.length === 0 && (
                                                    <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div class="row mb-3 sp-input-filter">
                                <label for="inputname" class="col-sm-2 col-form-label">{t('type')}</label>
                                <div class="col-sm-10 d-flex gap-2 flex-wrap">
                                    {types?.map((data, i) => (<>
                                        <input type="checkbox" class="btn-check" name="type" id={`type-${data.id}`} value="mail" checked={selectedType.includes(data.name)} onChange={() => handleType(data.name)} />
                                        <label class="btn sp-button sp-green-button" for={`type-${data.id}`}>{data.name}</label>
                                    </>
                                    ))}
                                </div>
                            </div>
                            <div class="row mb-3 sp-input-filter">
                                <label for="inputname" class="col-sm-2 col-form-label">{t('finalStatus')}</label>
                                <div class="col-sm-10 d-flex gap-2 flex-wrap">
                                    <input type="checkbox" class="btn-check" id="completed-btn" value="Completed" checked={selectedFinalStatus.includes('Completed')} onChange={() => handleStatus('Completed')} />
                                    <label class="btn sp-button sp-green-button" for="completed-btn">{t('completed')}</label>

                                    <input type="checkbox" class="btn-check" id="not-completed-btn" value="Not Completed" checked={selectedFinalStatus.includes('Not Completed')} onChange={() => handleStatus('Not Completed')} />
                                    <label class="btn sp-button sp-grey-button" for="not-completed-btn">Not {t('completed')}</label>

                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-end">
                                <button class="btn sp-button sp-blue-button" onClick={applyFilter}>{t('search')}</button>
                                <button class="btn sp-button sp-blue-button" onClick={resetFilter}>{t('reset')}</button>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                    {userRights?.['Mails And Packages']?.create == 1 || userRights == null ? <button class="btn sp-button sp-blue-button" onClick={() => navigate("/mail-and-packages/add-mail-and-package")}>{t('add')} {t('mailAndPackage')}</button> : <></>}
                </div>
            </div>

            <div className="sp-shadow sp-radius-20 white-bg">
                <div class="table-responsive">
                    <table class="table sp-table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">{t('trackingNumber')}</th>
                                <th scope="col">{t('type')}</th>
                                <th scope="col">{t('location')}</th>
                                <th scope="col">{t('member')}</th>
                                <th scope="col">{t('receivedBy')}</th>
                                <th scope="col">{t('memberStatus')}</th>
                                <th scope="col">{t('receivedAt')}</th>
                                <th scope="col">{t('finalStatus')}</th>
                                <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={9}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((data, i) => (
                                <tr>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.trackingNumber}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.type}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.spaces?.name}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.memberId?.name}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.receivedBy?.name}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.userStatus ? data?.userStatus : "NAN"}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{moment(data.dateTime).format("MMMM Do YYYY HH:mm")}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}><span className={`btn sp-button py-2 ${data.finalStatus == "Completed" ? "sp-green-button" : "sp-red-button"}`} >{data?.finalStatus == "Completed" ? data?.finalStatus : "Not Completed"}</span></td>
                                    {userRights?.['Mails And Packages']?.edit == 1 || userRights == null ? <td style={{ 'verticalAlign': 'middle' }}>
                                        <div className="d-flex align-items-center gap-2">
                                            <div onClick={() => navigate("/mail-and-packages/mail-package-detail", { state: data })} className={`sp-action-icons`}><Eye width="18" /></div>
                                            <div onClick={() => navigate("/mail-and-packages/edit-mail-package", { state: data })} className={`sp-action-icons`}><Pencil width="18" /></div>
                                        </div>
                                    </td> : <></>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MailAndPackages