import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
        loadPath: '/locales/{{lng}}/translation.json',
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false,
    },
  })

  .then(() => {
    console.log('i18n initialized successfully');
    console.log('Available Languages:', i18n.languages);
    console.log('Current Language:', i18n.language);
    console.log('Translations:', i18n.store.data); // Check the loaded translations
  })

  .catch((error) => {
    console.error('Error initializing i18n', error);
  });

export default i18n;

