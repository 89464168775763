import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByBusinessData, getSpacesForDropDown, getBusiness, addLead, getCountry, getProfile } from "../../store/slices/userSlice";
import { Camera } from "lucide-react";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from 'react-hot-toast';

const AddLead = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const business = useSelector(getBusiness)
  const profile = useSelector(getProfile)

  const [age, setAge] = useState('');
  const [getAllSpaces, setgetAllSpaces] = useState([]);

  const [name, setname] = useState('');
  const [address, setaddress] = useState('');
  const [email, setemail] = useState('');
  const [city, setcity] = useState('');
  const [phone, setphone] = useState('');
  const [country, setcountry] = useState(null);
  const [linkedIn, setlinkedIn] = useState('');
  const [postCode, setpostCode] = useState('');
  const [isCompany, setisCompany] = useState(false);
  const [companyName, setcompanyName] = useState('');
  const [companyAddress, setcompanyAddress] = useState('');
  const [companyEmail, setcompanyEmail] = useState('');
  const [companyCity, setcompanyCity] = useState("");
  const [companyPhone, setcompanyPhone] = useState('');
  const [companyCountry, setcompanyCountry] = useState(null);
  const [companyWebsite, setcompanyWebsite] = useState('');
  const [companyLinkedIn, setcompanyLinkedIn] = useState('');
  const [companyPostCode, setcompanyPostCode] = useState('');
  const [interestedServices, setinterestedServices] = useState([]);
  const [interestedSpaces, setinterestedSpaces] = useState([]);
  const [listOfCountry, setlistOfCountry] = useState(null);
  const [image, setImage] = useState();
  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);
  const [isPostCodeValid, setIsPostCodeValid] = useState(true);
  const [isPostCodeValid1, setIsPostCodeValid1] = useState(true);



  const Services = [
    {
      id: 1,
      text: 'Virtual Office',
    },
    {
      id: 2,
      text: 'Desks Space',
    },
    {
      id: 3,
      text: 'Private Room',
    },
    {
      id: 4,
      text: 'Meeting Room',
    },
    {
      id: 5,
      text: 'Event',
    },
  ];

  const getMyBusiness = async () => {
    try {
      await dispatch(getByBusinessData()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getSpaces = async () => {
    try {
      setload1(true);
      const response = await dispatch(getSpacesForDropDown(profile?.businessId ? profile?.businessId : business._id)).unwrap();
      setgetAllSpaces(response?.data)
      setload1(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const allCountry = async () => {
    try {

      const response = await dispatch(getCountry()).unwrap();
      setlistOfCountry(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  useEffect(() => {
    let mount = true;
    if (mount) {
      getSpaces();
      getMyBusiness()
      allCountry()
    }
    return () => {
      mount = false;
    };
  }, [])

  useEffect(() => {
    let mount = true;
    if (mount) {
      getSpaces();

    }
    return () => {
      mount = false;
    };
  }, [profile])





  const handleCheckboxChange = (text) => {
    const updatedCheckboxes = interestedServices.includes(text)
      ? interestedServices.filter((item) => item !== text)
      : [...interestedServices, text];

    setinterestedServices(updatedCheckboxes);
  };
  const handleCheckboxChangeForLocation = (text) => {


    const updatedCheckboxes = interestedSpaces.includes(text)
      ? interestedSpaces.filter((item) => item !== text)
      : [...interestedSpaces, text];

    setinterestedSpaces(updatedCheckboxes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setload(true);
      if (!name) {
        toast.error('Name is required');
        setload(false);
        return;
      }
      if (!phone) {
        toast.error('Phone is required');
        setload(false);
        return;
      }

      if (email) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (!isValidEmail) {
          toast.error('Please enter a valid email address!');
          setload(false);
          return;
        }
      }
      if (companyEmail) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(companyEmail);
        if (!isValidEmail) {
          toast.error('Please enter a valid email address!');
          setload(false);
          return;
        }
      }


      const form = new FormData();
      form.append("name", name);
      form.append("address", address);
      form.append("email", email);
      form.append("imageName", image);
      form.append("city", city);
      form.append("phone", phone);
      form.append("currency", profile?.currency);
      form.append("country", country);
      form.append("linkedIn", linkedIn);
      form.append("postCode", postCode);
      form.append("isCompany", Boolean(isCompany));
      form.append("companyName", companyName);
      form.append("companyAddress", companyAddress);
      form.append("companyEmail", companyEmail);
      form.append("companyCity", companyCity);
      form.append("companyLinkedIn", companyLinkedIn);
      form.append("companyPhone", companyPhone);
      form.append("companyCountry", companyCountry);
      form.append("companyWebsite", companyWebsite);
      form.append("companyPostCode", companyPostCode);
      form.append("interestedServices", interestedServices ? JSON.stringify(interestedServices) : []);
      form.append("interestedSpaces", interestedSpaces ? JSON.stringify(interestedSpaces) : []);
      form.append("businessId", profile?.businessId ? profile?.businessId : business._id);


      await dispatch(addLead(form)).unwrap();
      setname("")
      setaddress("")
      setemail("")
      setcity("")
      setphone("")
      setcountry(null)
      setlinkedIn("")
      setpostCode("")
      setisCompany(false)
      setcompanyName("")
      setcompanyEmail("")
      setcompanyAddress("")
      setcompanyCity("")
      setcompanyCountry("")
      setcompanyLinkedIn("")
      setcompanyPostCode("")
      setcompanyPhone("")
      setcompanyWebsite("")
      setinterestedServices([])
      setinterestedSpaces([])

      setload(false);
      navigate("/customers");
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [highlightedIndex1, setHighlightedIndex1] = useState(-1);

  const [searchValue, setSearchValue] = useState('');
  const [searchValue1, setSearchValue1] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setHighlightedIndex(-1);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  const handleOptionClick = (data) => {
    setSearchValue(data?.name);
    setcountry(data?.name);
    setShowDropdown(false);
  };
  const handleCompanyInputChange = (e) => {
    const value = e.target.value;
    setSearchValue1(value);
    setHighlightedIndex1(-1);
    if (value.length > 0) {
      setShowDropdown1(true);
    } else {
      setShowDropdown1(false);
    }
  };
  const handleOptionClickCompany = (data) => {
    setSearchValue1(data?.name);
    setcompanyCountry(data?.name);
    setShowDropdown1(false);
  };

  const filteredCountries = listOfCountry?.filter((data) =>
    data.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const filteredCountries1 = listOfCountry?.filter((data) =>
    data.name.toLowerCase().includes(searchValue1.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };
  const handleKeyDown1 = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex1((prevIndex) =>
        prevIndex < filteredCountries1.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex1((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex1 !== -1) {
        handleOptionClickCompany(filteredCountries1[highlightedIndex1]);
      }
    }
  };

  // useEffect(() => {
  //   const initAutocomplete = () => {
  //     const autocomplete = new window.google.maps.places.Autocomplete(
  //       document.getElementById('autocomplete'),
  //       { types: ['geocode'] }
  //     );

  //     autocomplete.addListener('place_changed', () => {
  //       const place = autocomplete.getPlace();

  //       const newCity = place.address_components.find(
  //         (component) => component.types.includes('locality') || component.types.includes('administrative_area_level_1')
  //       )?.long_name || '';

  //       const newCountry = place.address_components.find(
  //         (component) => component.types.includes('country')
  //       )?.long_name || null;

  //       const newPostCode = place.address_components.find(
  //         (component) => component.types.includes('postal_code')
  //       )?.long_name || '';

  //       setcity(newCity);
  //       const selectedCountry = listOfCountry?.find(data => data?.name === newCountry);
  //       console.log(selectedCountry, "selected country");
  //       setSearchValue(selectedCountry?.name || null);
  //       setcountry(selectedCountry?.name || null);
  //       setpostCode(newPostCode);
  //     });

  //     // Add a listener for input changes
  //     autocomplete.addListener('place_changed', () => {
  //       const input = document.getElementById('autocomplete');

  //       // Use AutocompleteService to get place predictions based on input
  //       const service = new window.google.maps.places.AutocompleteService();
  //       service.getPlacePredictions({ input: input.value }, (predictions, status) => {
  //         if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
  //           // Log the predictions to console (you can use them as suggestions or display them in a dropdown)
  //           console.log(predictions);
  //         }
  //       });
  //     });
  //   };

  //   initAutocomplete();
  // }, []);

  const handlePhoneChange = (e) => {
    let input = e.target.value;

    if (!input) {
      setphone('');
      return;
    }
    input = input.replace(/[^0-9]/g, '');
    const formattedPhone = input.length > 0 ? `+${input}` : '+';
    setphone(formattedPhone);
  };

  const handleCompanyPhoneChange = (e) => {
    let input = e.target.value;

    if (!input) {
      setcompanyPhone('');
      return;
    }
    input = input.replace(/[^0-9]/g, '');
    const formattedPhone = input.length > 0 ? `+${input}` : '+';
    setcompanyPhone(formattedPhone);
  };



  const validatePostCode = (postcode) => {
    const postcodeRegex = /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$/;
    return postcodeRegex.test(postcode);
  };



  return (
    <>
      {load ? <Spinner /> : <></>}
      <div className="sp-add-location-page pb-5 px-md-5 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
            <li class="breadcrumb-item"><a>{t('menu')}</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/customers")}>{t('customers')}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{t('addCustomer')}</li>
          </ol>
        </nav>
        <div className="d-flex align-items-center gap-2 mb-4">
        <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/customers-icon-sp.svg`} className="icon-text" />
          <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('addCustomer')}</h2>
        </div>
        <div className="mb-4">
          <div className="row">
            <div className="col-md-2">
              <div className="sp-profile-upload">
                <div class="avatar-upload">
                  <div class="avatar-edit">
                    <input onChange={(e) => { setImage(e.target.files[0]) }} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                    <label for="imageUpload"><Camera width={18} strokeWidth={1} /> {t('upload')}</label>
                  </div>
                  <div class="avatar-preview">
                    {image && <img id="output" src={URL.createObjectURL(image)} alt="" />}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-10 row gy-3 mb-3">
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="name" placeholder="Name" maxLength={40} value={name} onChange={(e) => setname(e.target.value)} required />
                  <label for="name">{t('name')}*</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="email" class="form-control" id="email" placeholder="Email" maxLength={320} value={email} onChange={(e) => setemail(e.target.value)} />
                  <label for="email">{t('email')}*</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="phone" placeholder="Phone" maxLength={13} onChange={handlePhoneChange} value={phone} />
                  <label for="phone">{t('phone')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="LinkedIn" placeholder="LinkedIn" value={linkedIn} onChange={(e) => setlinkedIn(e.target.value)} />
                  <label for="LinkedIn">{t('linkedIn')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="autocomplete" maxLength={120} placeholder="Address" value={address} onChange={(e) => setaddress(e.target.value)} />
                  <label for="autocomplete">{t('address')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="country" maxLength={120} placeholder="country" onKeyDown={handleKeyDown} onChange={(e) => handleInputChange(e)} value={searchValue} />
                  <label for="country">{t('country')}</label>
                </div>
                {showDropdown && (
                  <div className="dropdown sp-select-dropdown">
                    <ul className="dropdown-menu show" style={{ width: '100%' }}>
                      {listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                        <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                      ))}
                      {listOfCountry?.length > 0 && listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="city" maxLength={20} placeholder="City" value={city} onChange={(e) => setcity(e.target.value)} />
                  <label for="city">{t('city')}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div class="form-floating sp-input">
                  <input type="text" class="form-control" id="postcode" maxLength={9} placeholder="Postcode" value={postCode} onChange={(e) => setpostCode(e.target.value)} />
                  <label for="postcode">{t('postcode')}</label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="form-check sp-checkbox mb-3">
              <input class="form-check-input" checked={isCompany ? true : false} onChange={() => setisCompany(!isCompany)} type="checkbox" id="Representing-company" />
              <label class="form-check-label" for="Representing-company">{t('representingCompany')}</label>
            </div>
          </div>
          {
            isCompany ? <>
              <p className="font-size-16 blue-color fw-semibold mb-2">{t('company')} {t('detail')}</p>
              <div className="row gy-3 mb-4">
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="text" class="form-control" id="name" placeholder="Name" maxLength={40} value={companyName} onChange={(e) => setcompanyName(e.target.value)} />
                    <label for="name">{t('name')}*</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="email" class="form-control" id="email" placeholder="Email" maxLength={320} value={companyEmail} onChange={(e) => setcompanyEmail(e.target.value)} />
                    <label for="email">{t('email')}*</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="text" class="form-control" id="phone" placeholder="Phone" maxLength={13} value={companyPhone} onChange={handleCompanyPhoneChange} />
                    <label for="phone">{t('phone')}</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="text" class="form-control" id="LinkedIn" placeholder="LinkedIn" value={companyLinkedIn} onChange={(e) => setcompanyLinkedIn(e.target.value)} />
                    <label for="LinkedIn">{t('linkedIn')}</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="text" class="form-control" id="address" placeholder="Address" maxLength={120} value={companyAddress} onChange={(e) => setcompanyAddress(e.target.value)} />
                    <label for="address">{t('address')}</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="text" class="form-control" id="country" maxLength={120} placeholder="country" onKeyDown={handleKeyDown1} onChange={(e) => handleCompanyInputChange(e)} value={searchValue1} />
                    <label for="country">{t('country')}</label>
                  </div>
                  {showDropdown1 && (
                    <div className="dropdown sp-select-dropdown">
                      <ul className="dropdown-menu show" style={{ width: '100%' }}>
                        {listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.map((data, index) => (
                          <li key={index} onClick={() => handleOptionClickCompany(data)} className={highlightedIndex1 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                        ))}
                        {listOfCountry?.length > 0 && listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.length === 0 && (
                          <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="text" class="form-control" id="city" maxLength={20} placeholder="City" value={companyCity} onChange={(e) => setcompanyCity(e.target.value)} />
                    <label for="city">{t('city')}</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-floating sp-input">
                    <input type="text" class="form-control" id="postcode" maxLength={9} placeholder="Postcode" value={companyPostCode} onChange={(e) => setcompanyPostCode(e.target.value)} />
                    <label for="postcode">{t('postcode')}</label>
                  </div>
                </div>
              </div>
            </> : <></>}
          <div className="mb-3">
            <p className="font-size-16 blue-color fw-semibold mb-2">{t('services')}</p>
            <div className="d-flex flex-wrap gap-3">
              {Services.map((data, i) => (
                <>
                  <input name="services" type="checkbox" class="btn-check" checked={interestedServices.includes(data?.text)} onChange={() => handleCheckboxChange(data?.text)} id={data?.text} />
                  <label className="btn sp-check-select" for={data?.text}>{data.text}</label>
                </>
              ))}
            </div>
          </div>
          <div className="mb-3">
            <p className="font-size-16 blue-color fw-semibold mb-2">{t('locations')}</p>
            <div className="d-flex flex-wrap gap-3">
              {load1 ? new Array(6).fill(null).map((_, index) => (<div style={{ height: '49px', width: '120px' }} className='rounded shimmer'></div>)) : getAllSpaces.map((data, i) => (
                <>
                  <input name="locations" type="checkbox" class="btn-check" checked={interestedSpaces.includes(data._id)} onChange={() => handleCheckboxChangeForLocation(data._id)} id={data._id} />
                  <label className="btn sp-check-select" for={data._id}>{data.name}</label>
                </>
              ))}
            </div>
          </div>
        </div>

        <div className="text-center">
          <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>{t('save')}</button>
        </div>
      </div>
    </>
  )
}

export default AddLead