import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import ReactApexChart from 'react-apexcharts';

import {
  getBusiness, getGraphData, getProfile, getSpacesForDropDownPrivate
} from "../../store/slices/userSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DashboardAnalytics = () => {

  const { t } = useTranslation();
  const business = useSelector(getBusiness)
  const profile = useSelector(getProfile)

  const [getAllSpaces, setgetAllSpaces] = useState([]);
  const dispatch = useDispatch();

  const [daystype, setdaystype] = useState("last 7 days")
  const [value, setvalue] = useState("Members")
  const [data, setdata] = useState([])
  const [selectedSpaces, setSelectedSpaces] = useState([]);


  const handleCheckboxChange = (id) => {
    setSelectedSpaces((prevSelectedSpaces) => {
      if (prevSelectedSpaces.includes(id)) {
        // If the ID is already selected, remove it
        return prevSelectedSpaces.filter((spaceId) => spaceId !== id);
      } else {
        // If the ID is not selected, add it
        return [...prevSelectedSpaces, id];
      }
    });
  };

  console.log(selectedSpaces)
  const [series, setSeries] = useState([
    {
      name: "",
      data: [

      ],
    }
  ]);


  const [options, setOptions] = useState({   //days
    chart: {
      type: 'bar',
      height: 380,
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [{
            from: 0,
            to: 100,
            color: 'var(--color5)', // Set the desired color for the bars
          }],
        },
      },
    },

    xaxis: {
      type: 'category',
      labels: {
        formatter: (val) => {
          return dayjs(val).format("DD-MM-YYYY");
        },
        style: {
          fontSize: '8px', // Set the desired font size here
        },
      },
    },

    tooltip: {
      x: {
        formatter: (val) => dayjs(val).format("MMMM"),
        style: {
          fontSize: '14px', // Set the desired font size here
        },
      },
    },

  });

  const getSpaces = async () => {
    try {
      let payload = {
        businessId: business ? business?._id : null,
        assignSpace: profile ? profile?.assignedSpaces : [],
        isAllSpaces: profile ? profile?.isAllSpaces : null,
        businessId2: profile ? profile?.businessId : null,
      }

      const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
      setgetAllSpaces(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const graphData = async () => {
    try {
      let payload = {
        businessId: business ? business?._id : null,
        assignSpace: profile ? profile?.assignedSpaces : [],
        isAllSpaces: profile ? profile?.isAllSpaces : null,
        businessId2: profile ? profile?.businessId : null,
        value,
        daystype,
        selectedSpaces

      }



      const response = await dispatch(getGraphData(payload)).unwrap();
      setSeries([{ name: value, data: response?.data }]);

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };





  useEffect(() => {
    let mount = true;
    if (mount) {
      getSpaces();
    }
    return () => {
      mount = false;
    };
  }, [business, profile])


  useEffect(() => {
    let mount = true;
    if (mount) {
      graphData();
    }
    return () => {
      mount = false;
    };
  }, [business, profile, daystype, value ,selectedSpaces])


  return (
    <>
      <div className="sp-shadow sp-radius-20 white-bg p-4 mb-3">
        <div style={{ "--bs-border-color": "rgb(0 0 0 / 20%)" }} className="row pb-1 border-bottom">
          <div className="col-md-3 d-flex align-items-center gap-2">
            <img height="32" src="./assets/images/analytics-icon-sp.svg" className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-semibold black-color mb-0">{t('analytics')}</h2>
          </div>
          <div className="col-md-6">
            <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('location')}</label>
            <div className="d-flex gap-3 flex-wrap mb-3">
              {getAllSpaces?.map((data, i) => (
                <>
                  <input
                    class="btn-check"
                    type="checkbox"
                    checked={selectedSpaces.includes(data._id)}
                    onChange={() => handleCheckboxChange(data._id)}
                    id={data?._id}
                    key={i} />
                  <label class="btn sp-check-select" for={data?._id}>{data.name}</label>
                </>
              ))}
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-end">
            <select onChange={(e) => setdaystype(e.target.value)} style={{ border: '0', outline: '0', color: 'var(--color2)' }}>
              <option value="" disabled>Select</option>
              <option value="last 7 days">7 Days</option>
              <option value="monthly">Monthly</option>
              <option value="last 30 days">Last 30 days</option>
            </select>
          </div>
        </div>
        <div className="d-flex pt-5">
          <div className="sp-chart-tab">
            <ul>
              <li onClick={() => setvalue("Members")} className={value == "Members" && "active"}>
                <img src="./assets/images/d-members-icon-sp.svg" alt="" width={25} />
                <div>
                  <p className="font-size-14 fw-semibold mb-0">{t('members')}</p></div>
              </li>
              <li onClick={() => setvalue("Financial")} className={value == "Financial" && "active"}>
                <img src="./assets/images/d-financial-icon-sp.svg" alt="" width={25} />
                <div>
                  <p className="font-size-14 fw-semibold mb-0">{t('financial')}</p></div>
              </li>
              <li onClick={() => setvalue("Occupancy")} className={value == "Occupancy" && "active"}>
                <img src="./assets/images/d-occupancy-icon-sp.svg" alt="" width={25} />
                <div>
                  <p className="font-size-14 fw-semibold mb-0">{t('occupancy')}</p></div>
              </li>
              <li onClick={() => setvalue("Retention")} className={value == "Retention" && "active"}>
                <img src="./assets/images/d-retention-icon-sp.svg" alt="" width={25} />
                <div>
                  <p className="font-size-14 fw-semibold mb-0">{t('retention')}</p></div>
              </li>
            </ul>
          </div>
          <div id="chart" className="w-100">
            <ReactApexChart options={options} series={series} type="bar" height={360} />
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardAnalytics