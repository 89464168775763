import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { addStickyNote, chatList, getBusiness, getDashboard, getDashboardData, getProfile, getSpacesForDropDownPrivate, getUserRight } from '../../store/slices/userSlice';
import DashboardAnalytics from './DashboardAnalytics';
import moment from 'moment';
import { ImageDown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';


export const Dashboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const userRights = useSelector(getUserRight)

    const [filterExistingChats, setfilterExistingChats] = useState();
    const [data, setdata] = useState(null);
    const [stickyNotes, setstickyNotes] = useState("");

    const [load, setload] = useState(false);
    const [load1, setload1] = useState(false);
    const [load2, setload2] = useState(false);



    const dashboard = async () => {
        try {
            setload1(true);
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getDashboardData(payload)).unwrap();
            setdata(response)
            setload1(false);
        } catch (rejectedValueOrSerializedError) {
            setload1(false);
            console.log(rejectedValueOrSerializedError);
        }
    };




    const getAllChats = async () => {
        try {
            setload(true);
            const response = await dispatch(chatList()).unwrap();

            setfilterExistingChats(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const addSticky = async () => {
        try {
            let payload = {
                stickyNotes: stickyNotes ? stickyNotes : profile?.stickyNote
            }
            const response = await dispatch(addStickyNote(payload)).unwrap();


        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            getAllChats();
        }
        return () => {
            mount = false;
        };
    }, [])

    useEffect(() => {
        let mount = true;
        if (mount) {
            dashboard();

        }
        return () => {
            mount = false;
        };
    }, [business, profile])



    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addSticky();
        }
    };

    const handleClick = () => {
        navigate('/visitings', { state: { showTodayFilter: true, comingFromDashboard: true } });
    };

    console.log("dashboard check", profile, userRights)
    return (
        <>
            <div className="sp-dashboard-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('dashboard')}</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src="./assets/images/dashboard-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('dashboard')}</h2>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <div className="sp-shadow sp-radius-20 white-bg p-4">
                                    <img height="38" src="./assets/images/total-members-icon-sp.svg" className="icon-text mb-1" />
                                    {load1 ? <div style={{ height: '26px', width: '50px' }} className="shimmer rounded"></div> : <p className="font-size-26 light-blue-color fw-semibold mb-0">{data?.totalMembers}</p>}
                                    <p className="font-size-14 grey-color mb-0">{t('totalMembers')}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="sp-shadow sp-radius-20 white-bg p-4">
                                    <img height="38" src="./assets/images/monthly-revenue-icon-sp.svg" className="icon-text mb-1" />
                                    {load1 ? <div style={{ height: '26px', width: '100px' }} className="shimmer rounded"></div> : <p className="font-size-26 light-blue-color fw-semibold mb-0">{profile?.currency} {data?.totalRevenue}</p>}
                                    <p className="font-size-14 grey-color mb-0">{t('monthlyRevenue')}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="sp-shadow sp-radius-20 white-bg p-4">
                                    <img height="38" src="./assets/images/occupancy-icon-sp.svg" className="icon-text mb-1" />
                                    {load1 ? <div style={{ height: '26px', width: '50px' }} className="shimmer rounded"></div> : <p className="font-size-26 light-blue-color fw-semibold mb-0">{data?.totalOccupancy ? data?.totalOccupancy : 0}</p>}
                                    <p className="font-size-14 grey-color mb-0">{t('occupancy')}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="sp-shadow sp-radius-20 white-bg p-4">
                                    <img height="38" src="./assets/images/monthly-profit-icon-sp.svg" className="icon-text mb-1" />
                                    {load1 ? <div style={{ height: '26px', width: '100px' }} className="shimmer rounded"></div> : <p className="font-size-26 light-blue-color fw-semibold mb-0">{profile?.currency} {data?.totalProfit}</p>}
                                    <p className="font-size-14 grey-color mb-0">{t('monthlyProfit')}</p>
                                </div>
                            </div>
                        </div>
                        <DashboardAnalytics />
                        <div className="row">
                            <div className="col-md-3">
                                <div className="sp-shadow sp-radius-20 white-bg p-4 cursor-pointer">
                                    <img height="38" src="./assets/images/it-icon-sp.svg" className="icon-text mb-1" />
                                    {load1 ? <div style={{ height: '26px', width: '50px' }} className="shimmer rounded"></div> : <p className="font-size-26 light-blue-color fw-semibold mb-0">{data?.supportTicket}</p>}
                                    <p className="font-size-14 grey-color mb-0">{t('supportTickets')}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="sp-shadow sp-radius-20 white-bg p-4 cursor-pointer" onClick={() => handleClick()}>
                                    <img height="38" src="./assets/images/visiting-icon-sp.svg" className="icon-text mb-1" />
                                    {load1 ? <div style={{ height: '26px', width: '50px' }} className="shimmer rounded"></div> : <p className="font-size-26 light-blue-color fw-semibold mb-0">{data?.visitors}</p>}
                                    <p className="font-size-14 grey-color mb-0">{t('visitorsForToday')}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="sp-shadow sp-radius-20 white-bg p-4 cursor-pointer">
                                    <img height="38" src="./assets/images/print-scan-icon-sp.svg" className="icon-text mb-1" />
                                    {load1 ? <div style={{ height: '26px', width: '50px' }} className="shimmer rounded"></div> : <p className="font-size-26 light-blue-color fw-semibold mb-0">{data?.printAnScan}</p>}
                                    <p className="font-size-14 grey-color mb-0">{t('printScanResources')}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="sp-shadow sp-radius-20 white-bg p-4 cursor-pointer">
                                    <img height="38" src="./assets/images/mail-packages-icon-sp.svg" className="icon-text mb-1" />
                                    {load1 ? <div style={{ height: '26px', width: '50px' }} className="shimmer rounded"></div> : <p className="font-size-26 light-blue-color fw-semibold mb-0">{data?.mailAndPackage}</p>}
                                    <p className="font-size-14 grey-color mb-0">{t('packages')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="sp-shadow sp-radius-20 white-bg p-4 mb-3">
                            <div className="d-flex justify-content-between align-items-center mb-1">
                                <div className="d-flex gap-2 align-items-center">
                                    <img height="32" src="./assets/images/notebook-icon-sp.svg" className="icon-text" />
                                    <h2 className="font-size-24 titilium-font fw-semibold light-blue-color mb-0">{t('stickyNote')}</h2>
                                </div>
                                {/* <p className="font-size-12 light-blue-color mb-0"><u>View All</u></p> */}
                            </div>
                            <textarea style={{ boxShadow: "none", border: '0' }} name="" id="" defaultValue={profile?.stickyNote} onChange={(e) => setstickyNotes(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}
                                onBlur={() => addSticky()} className="form-control" rows={3} placeholder={t('typeHere')}></textarea>
                            {/* <button onClick={addSticky}>Add</button> */}
                        </div>
                        <div className="sp-shadow sp-radius-20 white-bg p-4 sp-todo-list mb-3">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div className="d-flex gap-2 align-items-center">
                                    <img height="32" src="./assets/images/to-do icon-sp.svg" className="icon-text" />
                                    <h2 className="font-size-24 titilium-font fw-semibold light-blue-color mb-0">{t('todoTasks')}</h2>
                                </div>
                                {/* <p className="font-size-12 light-blue-color mb-0"><u>View All</u></p> */}
                            </div>
                            <div className="sp-h-container">
                                <ul className='mb-0'>
                                    {load1 ? new Array(3).fill(null).map((_, index) => (
                                        <li>
                                            <div className="d-flex justify-content-between align-items-center mb-1">
                                                <div className="shimmer rounded" style={{ height: '21px', width: '36px' }}></div>
                                                <div className="shimmer rounded" style={{ height: '21px', width: '162px' }}></div>
                                            </div>
                                            <div className="shimmer rounded mb-1" style={{ height: '21px', width: '50px' }}></div>
                                        </li>
                                    )) : data?.reminders?.map((data, i) => (
                                        <li>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <p className="font-size-14 black-color fw-semibold mb-0">{data?.user?.name}</p>
                                                <p className="font-size-14 light-blue-color mb-0"><u>Due on {moment(data.date).format("MMMM Do YYYY")}</u></p>
                                            </div>
                                            <p className="font-size-14 grey-color mb-0">{data?.type}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="sp-shadow sp-radius-20 white-bg p-4 sp-todo-list mb-3">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div className="d-flex gap-2 align-items-center">
                                    <img height="32" src="./assets/images/due-invoices-icon-sp.svg" className="icon-text" />
                                    <h2 className="font-size-24 titilium-font fw-semibold light-blue-color mb-0">{t('dueInvoices')}</h2>
                                </div>

                            </div>
                            <div className="sp-h-container">
                                {load1 ? new Array(3).fill(null).map((_, index) => (
                                    <div className="d-flex gap-2 align-items-center mb-2 cursor-pointer">
                                        <div className="shimmer rounded-circle sp-user-profile"></div>
                                        <div className="flex-shrink-1 w-100">
                                            <div className="d-flex justify-content-between align-items-center mb-1">
                                                <div className="shimmer rounded" style={{ height: '21px', width: '50px' }}></div>
                                                <div className="shimmer rounded" style={{ height: '17px', width: '67px', borderRadius: '7px', padding: '1px 5px' }}></div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <div className="shimmer rounded" style={{ height: '18px', width: '130px' }}></div>
                                                <div className="shimmer rounded" style={{ height: '18px', width: '50px' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                )) : data?.invoices?.map((data, i) => (
                                    <div className="d-flex gap-2 align-items-center mb-2 cursor-pointer" onClick={() => navigate("/invoice-detail", { state: data })}>
                                        <div><img src={`${process.env.REACT_APP_IMGURL}/${data?.user?.imageName}`} alt="" className="rounded-circle sp-user-profile" /></div>
                                        <div className="flex-shrink-1 w-100">
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <p className="font-size-14 black-color fw-bold mb-0">{data?.user?.name}</p>
                                                <p className="font-size-10 light-blue-color mb-0"><span style={{ backgroundColor: 'rgb(69 132 232 / 20%)', borderRadius: '7px', padding: '1px 5px' }}>{data?.invoiceNumber}</span></p>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <p className="font-size-12 grey-color mb-0"><u>Due on {moment(data.dueDate).format("MMMM Do YYYY")}</u></p>
                                                <p className="font-size-12 light-blue-color mb-0">{profile?.currency} {data?.totalAmount}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="sp-shadow sp-radius-20 white-bg p-4 sp-todo-list">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div className="d-flex gap-2 align-items-center">
                                    <img height="28" src="./assets/images/message-icon-sp.svg" className="icon-text" />
                                    <h2 className="font-size-24 titilium-font fw-semibold light-blue-color mb-0">{t('messages')}</h2>
                                </div>

                            </div>
                            <div className="sp-h-container">
                                {load ? new Array(3).fill(null).map((_, index) => (
                                    <div class="sp-message-user-list d-flex align-items-center gap-2 cursor-pointer sp-chat-enqiries sp-chat-enqiries-dashboard mb-1" key={index}>
                                        <div className="shimmer rounded-circle sp-user-profile"></div>
                                        <div className="flex-shrink-1 w-100">
                                            <div className="d-flex justify-content-between align-items-center mb-1">
                                                <div className="shimmer rounded" style={{ height: '14px', width: '150px' }}></div>
                                                <div className="shimmer rounded" style={{ height: '12px', width: '80px' }}></div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <div className="shimmer rounded" style={{ height: '14px', width: '250px' }}></div>
                                                <div className="shimmer sp-message-count rounded-circle"></div>
                                            </div>
                                        </div>
                                    </div>)) : filterExistingChats?.map((data, index) => (
                                        <div className="d-flex gap-2 align-items-center mb-2 cursor-pointer" onClick={() => navigate("/chat", { state: data })} key={index}>
                                            <div>
                                                {profile?._id == data?.sender_id?._id ? < img src={`${process.env.REACT_APP_IMGURL}/${data?.receiver_id?.imageName}`} class="rounded-circle sp-user-profile" /> : < img src={`${process.env.REACT_APP_IMGURL}/${data?.sender_id?.imageName}`} class="rounded-circle sp-user-profile" />}
                                            </div>
                                            <div className="flex-shrink-1 w-100">
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <p className="font-size-14 black-color fw-bold mb-0">{profile._id == data.sender_id._id ? data?.receiver_id?.name : data?.sender_id?.name}</p>
                                                    <p className="font-size-10 grey-color mb-0">{data.time}</p>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <p className="font-size-12 grey-color mb-0">{data.message ? (data?.message?.length > 10 ? `${data?.message?.substring(0, 44)}...` : data?.message) : <ImageDown />}</p>
                                                    <p className="font-size-12 light-blue-color mb-0"><span style={{ backgroundColor: 'rgb(69 132 232 / 20%)', borderRadius: '50%', padding: '1px 5px' }}>{data?.noOfUnread}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}
