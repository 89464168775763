import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBusiness, getBusinessType, getCountry } from "../../store/slices/userSlice";
import { getProfile } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";





const AddBusiness = () => {


    const dispatch = useDispatch();
    const profile = useSelector(getProfile);
    const navigate = useNavigate();
    const [listOfBusiness, setlistOfBusiness] = useState(null);
    const [listOfCountry, setlistOfCountry] = useState(null);
    const [country, setcountry] = useState("");

    const [type, setType] = useState("");
    const [roleList, setroleList] = useState(null);
    const [role, setrole] = useState("");
    const [name, setName] = useState("");
    const [load, setload] = useState(false);

    const allBusiness = async () => {
        try {
            setload(true);
            const response = await dispatch(getBusinessType()).unwrap();
            setlistOfBusiness(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    
    const allCountry = async () => {
        try {

            const response = await dispatch(getCountry()).unwrap();
            setlistOfCountry(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    useEffect(() => {
        let roles = [];
        if (type === 'Coworking Office') {
            roles = ['Founder', 'Owner'];
        } else if (type === 'Gym / Fitness Centers') {
            roles = ['Receptionist', 'Front Desk'];
        } else if (type === 'Healthcare Clinics') {
            roles = ['Operations Manager'];
        } else if (type === 'Training Rooms') {
            roles = ['Marketing'];
        } else if (type === 'Library / Learning Center') {
            roles = ['Accountant'];
        } else if (type === 'Other') {
            roles = ['Team Member'];
        }
        setroleList(roles);
    }, [type]);



    const handleBusinessTypeSelect = (businessType) => {
        setType(businessType);
    };

    const handleClick = async (e) => {

        e.preventDefault();

        try {

            let payload = {
                name,
                type,
                country: country?.name,
                currency: country?.currency?.code,
                role,
                userId: profile?._id

            }
 
            const response = await dispatch(addBusiness(payload)).unwrap();

            navigate("/dashboard")


        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
 
    const handleRoleChange = (role) => {
        setrole(role)
    }

    useEffect(() => {
        let mount = true;
        if (mount) {
            allBusiness();
            allCountry()
        }
        return () => {
            mount = false;
        };
    }, []);

    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        setHighlightedIndex(-1);
        if (value.length > 0) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };
    const handleOptionClick = (data) => {
        setSearchValue(data?.name);
        setcountry(data);
        setShowDropdown(false);
    };

    const filteredCountries = listOfCountry?.filter((data) =>
    data.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
          // Move to the next item in the list
          setHighlightedIndex((prevIndex) =>
            prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
          );
        } else if (e.key === 'ArrowUp') {
          // Move to the previous item in the list
          setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (e.key === 'Enter') {
          // Select the highlighted item when Enter key is pressed
          if (highlightedIndex !== -1) {
            handleOptionClick(filteredCountries[highlightedIndex]);
          }
        }
      };

    return (
        <>
            <section class="authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-2-strong border-0 rounded" >
                                <div class="card-body p-5">
                                    <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-4 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}assets/images/logo-new.svg`} />
                                    <h3 className="font-size-24 titilium-font fw-bold light-blue-color mb-2">Lets Create Your Business</h3>
                                    <div class="form-floating mb-3 sp-input">
                                        <input type="text" class="form-control" id="floatingInput" placeholder="Event name"
                                            autocomplete="buisness name" required onChange={(e) => setName(e.target.value)} />
                                        <label for="floatingInput">Buisness Name</label>
                                    </div>
                                    <div class="form-floating sp-input">
                                        <input type="text" class="form-control" id="country" maxLength={120} placeholder="country" onKeyDown={handleKeyDown} onChange={(e) => handleInputChange(e)} value={searchValue} />
                                        <label for="country">Country</label>
                                    </div>
                                    {showDropdown && (
                                        <div className="dropdown sp-select-dropdown">
                                            <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                {listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                                                    <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                                                ))}
                                                {listOfCountry?.length > 0 && listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                                                    <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                )}
                                            </ul>
                                        </div>
                                    )}

                                    <div class="mb-3 mt-3">
                                        <label for="inputEmail3" class="col-form-label align-self-center light-blue-color fw-semibold">Business Type</label>
                                        <div className="d-flex gap-3 flex-wrap">
                                            {load ? new Array(6).fill(null).map((_, index) => (<div style={{ height: '116px', width: '132px' }} className="shimmer rounded"></div>)) : listOfBusiness?.map((business, i) => (
                                                <>
                                                    <input class="btn-check" type="radio" name="type" id={`type-${business?._id}`} onChange={() => handleBusinessTypeSelect(business.name)} key={i} />
                                                    <label style={{ width: '132px' }} class="btn sp-check-select d-flex flex-column gap-3 justify-content-center align-items-center" for={`type-${business?._id}`}>
                                                        <img src={`../assets/images/white-${business.name.replace(/[\/\s]+/g, '-').toLowerCase()}-icon.svg`} alt={business.name} className="sp-white-icon" />
                                                        <img src={`../assets/images/${business.name.replace(/[\/\s]+/g, '-').toLowerCase()}-icon.svg`} alt={business.name} className="sp-color-icon" />
                                                        {business.name}
                                                    </label>
                                                </>
                                            ))}
                                        </div>
                                    </div>

                                    {roleList?.length > 0 &&
                                        <div class="mb-3">
                                            <label for="inputEmail3" class="col-form-label align-self-center light-blue-color fw-semibold">Business Role</label>
                                            <div className="d-flex gap-3 flex-wrap">
                                                {roleList.map((role, i) => (
                                                    <>
                                                        <input class="btn-check" type="radio" name="role" id={`role-${role}`} onChange={() => handleRoleChange(role)} key={i} />
                                                        <label style={{ width: '120px' }} class="btn sp-check-select d-flex flex-column gap-3 justify-content-center align-items-center" for={`role-${role}`}>
                                                            <img src={`../assets/images/white-${role.replace(/[\/\s]+/g, '-').toLowerCase()}-icon.svg`} alt={role} className="sp-white-icon" />
                                                            <img src={`../assets/images/${role.replace(/[\/\s]+/g, '-').toLowerCase()}-icon.svg`} alt={role} className="sp-color-icon" />
                                                            {role}
                                                        </label>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    }

                                    <button class="btn sp-button sp-blue-button w-100" onClick={(e) => handleClick(e)}>Next</button>

                                </div>
                                <div class="card-footer sp-auth-card-footer">
                                    <div className="text-center">
                                        <a className="" onClick={() => navigate("/dashboard")}>
                                            <b className="light-blue-color"><u>Skip to Dashboard</u></b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default AddBusiness;