import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { contactList, getBusiness, getByBusinessData, getProfile, getUserRight } from '../../store/slices/userSlice';
import moment from 'moment/moment';
import { Eye, Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Contacts = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const userRights = useSelector(getUserRight)
    const [listOfUser, setlistOfUser] = useState([]);
    const [load, setload] = useState(false);
    const [toggle, settoggle] = useState(false);



    const [nameFilter, setNameFilter] = useState('');
    const [companyNameFilter, setCompanyNameFilter] = useState('');
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [emailFilter, setEmailFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [searchValueEmail, setSearchValueEmail] = useState('');
    const [searchValueCompany, setSearchValueCompany] = useState('');


    const applyFilter = () => {
        const filteredResult = listOfUser?.filter(item => {
            const userName = item?.users?.name.toLowerCase();
            const userCompany = item?.company ? item?.company?.name.toLowerCase() : ''; // Check for existence of company
            const userStatus = item?.status?.toLowerCase();
            const userEmail = item.users.email.toLowerCase();


            return (
                (nameFilter === '' || userName.includes(nameFilter.toLowerCase())) &&
                (emailFilter === '' || userEmail.includes(emailFilter.toLowerCase())) &&
                (companyNameFilter === '' || (userCompany && userCompany.includes(companyNameFilter.toLowerCase()))) &&
                (selectedStatuses.length === 0 || selectedStatuses.includes(userStatus))
            );
        });

        setFilteredData(filteredResult);
        settoggle(false);
    };

    const handleStatusChange = (status) => {
        if (selectedStatuses.includes(status)) {
            setSelectedStatuses(prevStatuses => prevStatuses.filter(s => s !== status));
        } else {
            setSelectedStatuses(prevStatuses => [...prevStatuses, status]);
        }
    };

    const resetFilter = () => {
        setNameFilter('');
        setCompanyNameFilter('');
        setEmailFilter('');
        setSelectedStatuses([]);
        setFilteredData(listOfUser)
        settoggle(false);
        setSearchValue('');
        setSearchValueEmail('');
        setSearchValueCompany('');
    };


    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const getUserList = async () => {
        try {
            setload(true);
            const response = await dispatch(contactList(business ? business?._id : profile?.businessId)).unwrap();
            setlistOfUser(response?.data)
            setFilteredData(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            getMyBusiness()
        }
        return () => {
            mount = false;
        };
    }, [])
    useEffect(() => {
        let mount = true;
        if (mount) {

            getUserList()

        }
        return () => {
            mount = false;
        };
    }, [business])

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value.length > 0) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };
    const handleOptionClick = (data) => {
        setSearchValue(data?.users?.name);
        setNameFilter(data?.users?.name);
        setShowDropdown(false);
    };

    const handleInputChange1 = (e) => {
        const value = e.target.value;
        setSearchValueEmail(value);
        if (value.length > 0) {
            setShowDropdown1(true);
        } else {
            setShowDropdown1(false);
        }
    };
    const handleOptionClick1 = (data) => {
        setSearchValueEmail(data?.users?.email);
        setEmailFilter(data?.users?.email);
        setShowDropdown1(false);
    };


    const handleInputChange2 = (e) => {
        const value = e.target.value;
        setSearchValueCompany(value);
        if (value.length > 0) {
            setShowDropdown2(true);
        } else {
            setShowDropdown2(false);
        }
    };
    const handleOptionClick2 = (data) => {
        setSearchValueCompany(data?.company?.name);
        setCompanyNameFilter(data?.company?.name);
        setShowDropdown2(false);
    };

    return (
        <>
            <div className="sp-leads-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/customers")}>{t('customers')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('contacts')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                    <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                        <img height="32" src="./assets/images/customers-icon-sp.svg" className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('contacts')}</h2>
                    </div>
                    <div className="col-md-6 order-md-2 order-3">
                        <div class="dropdown sp-search-dropdown">
                            <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
                                <span>{t('filterAndSearch')}</span>
                                <span>
                                    {toggle ? <X /> : <Search />}
                                </span>
                            </a>

                            <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputname" class="col-sm-2 col-form-label">{t('name')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={(e) => handleInputChange(e)} value={searchValue} placeholder="Filter by Name" class="form-control" id="inputname" />
                                        {showDropdown && (
                                            <div className="dropdown">
                                                <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                    {listOfUser?.filter((data) => data?.users?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                                                        <li key={index} onClick={() => handleOptionClick(data)}><a class="dropdown-item" >{data?.users?.name}</a></li>
                                                    ))}
                                                    {listOfUser?.length > 0 && listOfUser?.filter((data) => data?.users?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                                                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputemail" class="col-sm-2 col-form-label">{t('email')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={(e) => handleInputChange1(e)} value={searchValueEmail} placeholder="Filter by Email" class="form-control" id="inputemail" />
                                        {showDropdown1 && (
                                            <div className="dropdown">
                                                <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                    {listOfUser?.filter((data) => data?.users?.email?.toLowerCase()?.includes(searchValueEmail?.toLowerCase()))?.map((data, index) => (
                                                        <li key={index} onClick={() => handleOptionClick1(data)}><a class="dropdown-item" >{data?.users?.email}</a></li>
                                                    ))}
                                                    {listOfUser?.length > 0 && listOfUser?.filter((data) => data?.users?.email?.toLowerCase()?.includes(searchValueEmail?.toLowerCase()))?.length === 0 && (
                                                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div class="row mb-3 sp-input-filter">
                                    <label for="inputname" class="col-sm-2 col-form-label">{t('company')}</label>
                                    <div class="col-sm-10">
                                        <input type="text" onChange={(e) => handleInputChange2(e)} value={searchValueCompany} placeholder="Filter by Company Name" class="form-control" id="inputname" />
                                        {showDropdown2 && (
                                            <div className="dropdown">
                                                <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                                    {listOfUser?.filter((data) => data?.company?.name?.toLowerCase()?.includes(searchValueCompany?.toLowerCase()))?.map((data, index) => (
                                                        <li key={index} onClick={() => handleOptionClick2(data)}><a class="dropdown-item" >{data?.company?.name}</a></li>
                                                    ))}
                                                    {listOfUser?.length > 0 && listOfUser?.filter((data) => data?.company?.name?.toLowerCase()?.includes(searchValueCompany?.toLowerCase()))?.length === 0 && (
                                                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex gap-2 justify-content-end">
                                    <button class="btn sp-button sp-blue-button" onClick={applyFilter}>{t('search')}</button>
                                    <button class="btn sp-button sp-blue-button" onClick={resetFilter}>{t('reset')}</button>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">

                    </div>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg">
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: "80px" }} scope="col">{t('name')}</th>
                                    <th scope="col">{t('email')}</th>
                                    <th scope="col">{t('phone')}</th>
                                    <th scope="col">{t('company')}</th>
                                    <th scope="col">{t('activity')}</th>
                                    <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '60px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((data, i) => (
                                    <tr>
                                        <td>
                                            <div style={{ paddingLeft: '10px' }} className="d-flex flex-row align-items-center gap-3">
                                                {data?.users?.imageName ? <img class="rounded-circle sp-user-profile" alt="avatar" src={`${process.env.REACT_APP_IMGURL}/${data?.users?.imageName}`} /> : <img class="rounded-circle sp-user-profile" src="./assets/images/dummy.png" />}
                                                <span className="font-size-14 cursor-pointer"><u onClick={() => navigate("/customers/customer-detail", { state: data })}>{data?.users?.name}</u></span>
                                            </div>
                                        </td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.users?.email}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.users?.phone}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.company?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{moment(data.users?.createdAt).format("MMMM Do YYYY HH:mm")}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div onClick={() => navigate("/customers/customer-detail", { state: data })} className={`sp-action-icons`}><Eye width="18" /></div>
                                                {userRights?.Customers?.edit == 1 || userRights == null ? <div onClick={() => navigate("/customers/edit-customer", { state: data?.users?._id })} className={`sp-action-icons`}><Pencil width="18" /></div> : <></>}                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Contacts