import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../store/slices/userSlice";


const VerifiedSuccess = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profile = useSelector(getProfile);


    return (
        <>
            <section class="authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-2-strong border-0 rounded" >
                                <div class="card-body p-5 text-center">
                                <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}assets/images/logo-new.svg`} />
                                    <img className="mb-4" width="150" src={"../assets/images/verified-success-sp.svg"} />
                                    <p className="font-size-24 titilium-font fw-bold light-blue-color mb-0">Verified Successfully</p>
                                    <p className="font-size-14 color-black">Thank you</p>
                                </div>
                                <div class="card-footer sp-auth-card-footer">
                                    <div className="text-center">
                                        <a
                                            className=""
                                            // onClick={() => navigate(profile?.is_invited ? "/dashboard" : "/signupform")}
                                            onClick={() => navigate("/add-business")}
                                        >
                                            <b className="light-blue-color"><u>Lets Create your Business</u></b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VerifiedSuccess;