import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';


import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, roomDetail } from '../../store/slices/userSlice';
import { useTranslation } from 'react-i18next';

const RoomDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    const [room, setroom] = useState()
    const [transformedData, settransformedData] = useState(null)
    const [setType, setsetType] = useState("Monthly")

    const getDeskDetail = async () => {
        try {
            const response = await dispatch(roomDetail({ id: location?.state?._id, type: setType })).unwrap();
            console.log(response)
            setroom(response?.data[0])
            settransformedData(response?.transformedData)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {

            getDeskDetail();
        }
        return () => {
            mount = false;
        };
    }, [setType])

    const RoomUtilizationData = [
        {
            data: transformedData ? transformedData : transformedData
        }
    ];


    const options = {
        chart: {
            height: 350,
            type: 'rangeBar'
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                dataLabels: {
                    hideOverflowingLabels: false
                },
                borderRadius: 10,
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (val, opts) => {
                const label = opts.w.globals.labels[opts.dataPointIndex];
                const a = moment(val[0]);
                const b = moment(val[1]);
                const diff = b.diff(a, 'days');
                return diff + (diff > 1 ? ' days' : ' day');
            },
        },
        xaxis: {
            type: "datetime",
        },
        yaxis: {
            show: true, // Show the y-axis
            labels: {
                show: true,
                style: {
                    colors: '#151549',
                    fontWeight: '700' // Change y-axis label color to red
                },
            },
        },
    };

    console.log(room, "room");

    return (
        <>
        <div className="sp-customer-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('spaces')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/rooms")}>{t('rooms')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('room')} {t('detail')}</li>
                    </ol>
                </nav>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <h2 className="font-size-24 titilium-font fw-bold color-black mb-0">{room?.name}</h2>
                        </div>
                        <div className="col-md-6 d-flex flex-row align-items-center gap-3 justify-content-end">
                            <button class="btn sp-button sp-blue-button" onClick={() => navigate("/rooms/edit-room" ,{state: room})}>{t('edit')} {t('room')}</button>
                        </div>
                    </div>
                    <div className="row mb-4 gy-4">
                        <div className="col-md-3 sp-details-slider">
                            <Swiper
                                modules={[Navigation, Pagination, A11y]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                            >
                                {room?.image?.map((imageUrl, index) => (
                                    <SwiperSlide key={index}>
                                        <img src={`${process.env.REACT_APP_IMGURL}/${imageUrl}`} className="sp-details-image-slider" alt={`Space Image ${index + 1}`} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div className="col-md-4 align-self-center">
                        <div className="d-flex gap-2 mb-3">
                            <img width={32} src="../assets/images/occupied-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('capacity')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{room?.capacity}</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <img width={32} src="../assets/images/size-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('size')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{room?.roomSize} sq. ft.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-5 align-self-center">
                        <div className="d-flex gap-2 mb-3">
                            <img width={32} src="../assets/images/dr-type-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('type')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{room?.type}</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <img width={32} src="../assets/images/address-iconsp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('location')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{room?.location}</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-12">
                        <div className="d-flex gap-2 mb-3 align-items-start">
                            <img width={32} src="../assets/images/to-do icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('description')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{room?.description}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    {room?.planDetail?.map((data, i) => (<>
                    <h3 className="font-size-18 titilium-font fw-bold light-blue-color mb-0">Plan # {i +1}</h3>
                    <div className="row mb-3">
                        <div className="col-md-6 row gy-3 mt-0">
                        <div className="col-md-4">
                                <div className="sp-shadow sp-radius-20 l-blue-bg p-4">
                                    <img height="38" src="../assets/images/name-icon-sp.svg" className="icon-text mb-1" />
                                    <p className="font-size-18 color-black fw-semibold mb-0">{t('name')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{data?.name}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="sp-shadow sp-radius-20 white-bg p-4">
                                    <img height="38" src="../assets/images/deposit-icon-sp.svg" className="icon-text mb-1" />
                                    <p className="font-size-18 color-black fw-semibold mb-0">{t('deposit')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{data?.deposit}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="sp-shadow sp-radius-20 white-bg p-4">
                                    <img height="38" src="../assets/images/size-icon-sp.svg" className="icon-text mb-1" />
                                    <p className="font-size-18 color-black fw-semibold mb-0">{t('multipleQuantity')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{data?.name}</p>
                                </div>
                            </div>
                            {data?.pricing.map((option, index) => (
                            <div className="col-md-4">
                                <div className="sp-shadow sp-radius-20 white-bg p-4">
                                    <img height="34" src="../assets/images/price-icon-sp.svg" className="icon-text mb-1" />
                                    <p className="font-size-18 color-black fw-semibold mb-0"> {t(option?.type?.toLowerCase())} {t('price')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{option?.price}</p>
                                </div>
                            </div>
                            ))}
                        </div>
                        <div className="col-md-6">
                        <div className="sp-shadow sp-radius-20 white-bg p-4 h-100">
                        <p className="font-size-18 color-black fw-semibold mb-0">{t('description')}</p>
                        <p className="font-size-16 light-blue-color mb-0">{data?.description}</p>
                        </div>
                        </div>
                    </div>
                    </>))}

                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h3 className="font-size-18 titilium-font fw-bold light-blue-color mb-0">{t('RoomsUtilization')}</h3>
                        <select value={setType} onChange={(e) => setsetType(e.target.value)} style={{border: '0', outline: '0', color: 'var(--color2)'}}>
                        <option value="" disabled>Select</option>
                        <option value="All">All</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Last 30 Days">Last 30 days</option>
                        <option value="24 Months">24 Months</option>
                    </select>
                    </div>
                    <div className="rooms-utilization-char" id="chart">
                        {transformedData && (<ReactApexChart options={options} series={RoomUtilizationData} type="rangeBar" height={350} />)}
                    </div>
            </div>
        </>
    )
}

export default RoomDetails