import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import i18n from '../i18n';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();

const changeLanguage = async (language) => {
    await i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
    window.location.reload();
};

useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    i18n.changeLanguage(savedLanguage);
  }, []);

    const getCurrentLanguage = () => i18n.language;

  useEffect(() => {
    // Dynamically add or remove the Bootstrap RTL stylesheet based on the language
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.href =
      getCurrentLanguage() === 'ar'
        ? 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.rtl.min.css'
        : '';
    linkElement.integrity =
      'sha384-nU14brUcp6StFntEOOEBvcJm4huWjB0OcIeQ3fltAfSmuZFrkAif0T+UtNGlKKQv';
    linkElement.crossOrigin = 'anonymous';

    // Append or remove the link element from the head
    if (getCurrentLanguage() === 'ar') {
      document.head.appendChild(linkElement);
    } else {
      const existingLink = document.head.querySelector('link[href*="bootstrap.rtl.min.css"]');
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    }

    // Clean up on component unmount
    return () => {
      const existingLink = document.head.querySelector('link[href*="bootstrap.rtl.min.css"]');
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [getCurrentLanguage()]);

  return (
    <div>
      {/* <h1>{t('greeting')}</h1>
      <p>{t('selectLanguage')}</p> */}
      {getCurrentLanguage() === 'en' && (
        <p className="font-size-20 fw-bold blue-color mb-0 cursor-pointer" onClick={() => changeLanguage('ar')}><u>Arabic</u></p>
      )}

      {getCurrentLanguage() === 'ar' && (
        <p className="font-size-20 fw-bold blue-color mb-0 cursor-pointer" onClick={() => changeLanguage('en')}><u>English</u></p>
      )}
    </div>
  ); 
};

export default LanguageSwitcher;
