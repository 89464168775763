import React from 'react'

const Spinner = () => {
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", width: "100%", backgroundColor: "rgba(15, 15, 15,0.5)", position: "fixed", zIndex: 1500, top: 0, left:0 }}>
            <div className="fa-3x">
                <img src={"../assets/images/loader.gif"} width="50" height="50" />
            </div>
        </div>
    )
}

export default Spinner