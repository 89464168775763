import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getUserStatus, getUserToken } from "../../store/slices/userSlice"
import { context } from '../../context/context';
import Login from '../../pages/Auth/Login';
import SignUp from '../../pages/Auth/SignUp';
import ForgetPassword from '../../pages/Auth/ForgetPassword';
import VerifiedSuccess from '../../pages/Auth/VerifiedSuccess';
import AddBusiness from '../../pages/Auth/AddBusiness';
import VerifyAccount from '../../pages/Auth/VerifyAccount';
import Spinner from '../Spinner';
import { Dashboard } from '../../pages/Core/Dashboard';
import ChangePassword from '../../pages/Auth/ChangePassword';
import AddSpace from '../../pages/Core/AddSpace';
import AddRoom from '../../pages/Core/AddRoom';
import AddDesk from '../../pages/Core/AddDesk';
import AddLead from '../../pages/Core/AddLead';
import Navbar from './Navbar';
import Sidebar from './SideBar';
import AddBooking from '../../pages/Core/AddBooking';
import UserDetails from '../../pages/Core/UserDetail';
import UserList from '../../pages/Core/UserList';
import Leads from '../../pages/Core/Leads';
import Desks from '../../pages/Core/Desks';
import Rooms from '../../pages/Core/Rooms';
import Spaces from '../../pages/Core/Spaces';
import InvoiceDetail from '../../pages/Core/InvoiceDetail';
import Bookings from '../../pages/Core/Bookings';
import BookingDetails from '../../pages/Core/BookingDetails';
import Users from '../../pages/Core/Users';
import UserRoles from '../../pages/Core/UserRole';
import AddUser from '../../pages/Core/AddUser';
import SetUpPassword from '../../pages/Auth/SetupPassword';
import Members from '../../pages/Core/Members';
import Contacts from '../../pages/Core/Contacts';
import SpaceDetail from '../../pages/Core/SpaceDetail';
import DeskDetails from '../../pages/Core/DeskDetail';
import RoomDetails from '../../pages/Core/RoomDetail';
import EditLead from '../../pages/Core/EditLead';
import EditDesk from '../../pages/Core/EditDesk';
import EditRoom from '../../pages/Core/EditRoom';
import EditSpace from '../../pages/Core/EditSpace';
import VirtualOffices from '../../pages/Core/VirtualOffices';
import AddVirtualOffice from '../../pages/Core/AddVirtualOffice';
import Chat from '../../pages/Core/Chat';
import TechnicalSupport from '../../pages/Core/TechnicalSupport';
import AddTicket from '../../pages/Core/AddTicket';
import SupportCategory from '../../pages/Core/SupportCategory';
import TicketDetail from '../../pages/Core/TicketDetail';
import AddTransaction from '../../pages/Core/AddTransaction';
import Transactions from '../../pages/Core/Transactions';
import Invoices from '../../pages/Core/Invoices';
import AddEvent from '../../pages/Core/AddEvent';
import Events from '../../pages/Core/Events';
import News from '../../pages/Core/News';
import AddNews from '../../pages/Core/AddNews';
import Map from '../../pages/Core/Map';
import EditNews from '../../pages/Core/EditNews';
import EditEvent from '../../pages/Core/EditEvent';
import MailAndPackages from '../../pages/Core/MailAndPackages';
import AddMailAndPackage from '../../pages/Core/AddMailAndPackage';
import EditMailPackage from '../../pages/Core/EditMailPackage';
import PrintAndScan from '../../pages/Core/PrintAndScan';
import EditPrintAndScan from '../../pages/Core/EditPrintAndScan';
import Visitings from '../../pages/Core/Visitings';
import VisitsDetail from '../../pages/Core/VisitsDetail';
import AddVisiting from '../../pages/Core/AddVisiting';
import { useTranslation } from 'react-i18next';
import AddPrintAndScan from '../../pages/Core/AddPrintAndScan';
import EditVisiting from '../../pages/Core/EditVisiting';
import EditTransaction from '../../pages/Core/EditTransaction';
import EditUser from '../../pages/Core/EditUser';
import Notification from './Notification';
import FinanceCategory from '../../pages/Core/FinanceCategory';
import TicketLog from '../../pages/Core/TIcketLog';
import Settings from '../../pages/Core/Settings';

const Layout = () => {


  const { isLoading } = useContext(context);
  const status = useSelector(getUserStatus)
  const authToken = useSelector(getUserToken)

  const { i18n } = useTranslation();

  useEffect(() => {
    // Update lang and dir attributes based on the selected language
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    if (i18n.language === 'ar') {
      document.body.classList.add('rtl-arabic');
    } else {
      document.body.classList.remove('rtl-arabic');
    }
  }, [i18n.language]);



  return (
    <>
      {/* {status === "loading" || isLoading ? <Spinner /> : <></>} */}
      <BrowserRouter>
        <Content authtoken={authToken} />
      </BrowserRouter>

    </>
  )
}

const Content = ({ authtoken }) => {
  var dash = "";
  const location = useLocation();
  const p = location.pathname.split('/');
  const p1 = p[p.length - 1];
  for (var i = p1.length - 1; i >= 0; i--) {
    if (p1[i] == '_') {
      console.log("trueeeeeeeee");
      dash = i;
      break;
    }
  }
  const t = decodeURIComponent(p1.replace(/-/g, ' '));
  function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
    document.title = location.pathname == "/" ? "Spaces Manager" : dash !== "" ? capitalizeEachWord(t.substring(0, dash)) : capitalizeEachWord(t);

  }, [dash, t]);


  return (
    <>
      {authtoken ? <>
          {location.pathname == "/add-business" || location.pathname == "/verify-sucessfully" ? <></> : <Sidebar />}
          {location.pathname == "/add-business" || location.pathname == "/verify-sucessfully" ? <></> : <Navbar />}
          <section className={location.pathname == "/add-business" || location.pathname == "/verify-sucessfully" ? "" : "content-section"}>
            <Routes>
              <Route path="*" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" exact element={<Dashboard />} />
              <Route path="/notification" exact element={<Notification />} />
              <Route path="/verify-sucessfully" exact element={<VerifiedSuccess />} />
              <Route path="/locations" exact element={<Spaces />} />
              <Route path="/rooms" exact element={<Rooms />} />
              <Route path="/desks" exact element={<Desks />} />
              <Route path="/virtual-office" exact element={<VirtualOffices />} />
              <Route path="/add-business" exact element={<AddBusiness />} />
              <Route path="/locations/add-location" exact element={<AddSpace />} />
              <Route path="/rooms/add-room" exact element={<AddRoom />} />
              <Route path="/virtual-office/add-virtual-office" exact element={<AddVirtualOffice />} />
              <Route path="/desks/add-desk" exact element={<AddDesk />} />
              <Route path="/customers/add-customer" exact element={<AddLead />} />
              <Route path="/bookings/add-booking" exact element={<AddBooking />} />
              <Route path="/invoices/invoice-detail" exact element={<InvoiceDetail />} />
              <Route path="/customers" exact element={<UserList />} />
              <Route path="/customers/customer-detail" exact element={<UserDetails />} />
              <Route path="/leads" exact element={<Leads />} />
              <Route path="/members" exact element={<Members />} />
              <Route path="/contacts" exact element={<Contacts />} />
              <Route path="/bookings" exact element={<Bookings />} />
              <Route path="/bookings/booking-detail" exact element={<BookingDetails />} />
              <Route path="/locations/location-detail" exact element={<SpaceDetail />} />
              <Route path="/desks/desk-detail" exact element={<DeskDetails />} />
              <Route path="/rooms/room-detail" exact element={<RoomDetails />} />

              <Route path="/teams" exact element={<Users />} />
              <Route path="/team-role" exact element={<UserRoles />} />
              <Route path="/teams/add-team" exact element={<AddUser />} />
              <Route path="/teams/edit-team" exact element={<EditUser />} />
              <Route path="/chat" exact element={<Chat />} />


              <Route path="/customers/edit-customer" exact element={<EditLead />} />
              <Route path="/desks/edit-desk" exact element={<EditDesk />} />
              <Route path="/rooms/edit-room" exact element={<EditRoom />} />
              <Route path="/locations/edit-location" exact element={<EditSpace />} />

              <Route path="/support-tickets" exact element={<TechnicalSupport />} />
              <Route path="/support-tickets/add-ticket" exact element={<AddTicket />} />
              <Route path="/support-category" exact element={<SupportCategory />} />
              <Route path="/support-tickets/ticket-detail" exact element={<TicketDetail />} />

              {/* <Route path="/finance" exact element={<Finance />} /> */}
              <Route path="/transactions" exact element={<Transactions />} />
              <Route path="/transactions/add-transaction" exact element={<AddTransaction />} />
              <Route path="/transactions/edit-transaction" exact element={<EditTransaction />} />
              <Route path="/transactions/transaction-detail" exact element={<EditTransaction />} />
              <Route path="/invoices" exact element={<Invoices />} />
              <Route path="/finance-category" exact element={<FinanceCategory />} />

              <Route path="/events" exact element={<Events />} />
              <Route path="/events/add-event" exact element={<AddEvent />} />
              <Route path="/news" exact element={<News />} />
              <Route path="/news/add-news" exact element={<AddNews />} />
              <Route path="/news/edit-news" exact element={<EditNews />} />
              <Route path="/news/news-detail" exact element={<EditNews />} />
              <Route path="/events/edit-event" exact element={<EditEvent />} />
              <Route path="/events/event-detail" exact element={<EditEvent />} />


              <Route path="/mail-and-packages" exact element={<MailAndPackages />} />
              <Route path="/mail-and-packages/add-mail-and-package" exact element={<AddMailAndPackage />} />
              <Route path="/mail-and-packages/edit-mail-package" exact element={<EditMailPackage />} />
              <Route path="/mail-and-packages/mail-package-detail" exact element={<EditMailPackage />} />


              <Route path="/print-and-scan/add-print-and-scan" exact element={<AddPrintAndScan />} />
              <Route path="/print-and-scan" exact element={<PrintAndScan />} />
              <Route path="/print-and-scan/edit-print-and-scan" exact element={<EditPrintAndScan />} />
              <Route path="/print-and-scan/print-and-scan-detail" exact element={<EditPrintAndScan />} />

              <Route path="/visitings" exact element={<Visitings />} />
              <Route path="/visitings/visitor-detail" exact element={<VisitsDetail />} />
              <Route path="/visitings/add-visitor" exact element={<AddVisiting />} />
              <Route path="/visitings/edit-visitor" exact element={<EditVisiting />} />

              <Route path="/map-example" exact element={<Map />} />
              <Route path="/ticket-log" exact element={<TicketLog />} />
              <Route path="/settings" exact element={<Settings />} />

            </Routes>
          </section>
        </> :
          <>
            <Routes>
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" exact element={<Login />} />
              <Route path="/sign-up" exact element={<SignUp />} />
              <Route path="/forget-password" exact element={<ForgetPassword />} />
              <Route path="/change-password" exact element={<ChangePassword />} />
              <Route path="/verify-account" exact element={<VerifyAccount />} />
              <Route path="/setup-password/:userId/:resetLink" exact element={<SetUpPassword />} />
            </Routes>
          </>
      }
    </>
  )

};


export default Layout