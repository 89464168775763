import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRoom, getBusiness, getByBusinessData, getSpacesForDropDownPrivate, getProfile, addVirtualOffice } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Camera } from "lucide-react";
import toast from "react-hot-toast";

const AddVirtualOffice = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)


    const [images, setImages] = useState([]);
    const [prev_images, setprev_images] = useState([]);
    const [getAllSpaces, setgetAllSpaces] = useState([]);
    const [name, setname] = useState("");
    const [description, setdescription] = useState("");
    const [capacity, setcapacity] = useState(null);
    const [roomSize, setroomSize] = useState(null);
    const [type, settype] = useState(null);
    const [spaceId, setspaceId] = useState("");
    const [noOfHours, setnoOfHours] = useState(null);
    const {t} = useTranslation();
    const [load, setload] = useState(false);



    const [allPlans, setallPlans] = useState([
        {
            name: '',
            description: '',
            autorenewal: 0,

            multiplequantity: 0,
            pricing: [
                // { type: 'Monthly', price: 0 },
                // { type: 'Quaterly', price: 0 },
                // { type: 'Bi Anually', price: 0 },
                // { type: 'Yearly', price: 0 },


                { type: 'Weekly', price: 0 },
                { type: 'Monthly', price: 0 },
                { type: 'Yearly', price: 0 },
            ],
        },
    ]);


    const handleInputChange = (index, key, value) => {
        const newPlans = [...allPlans];
        newPlans[index][key] = value;
        setallPlans(newPlans);
    };

    const handlePricingInputChange = (index, pricingIndex, key, value) => {
        const newAllPlans = [...allPlans];
        newAllPlans[index].pricing[pricingIndex][key] = parseFloat(value); // Convert price to a number
        setallPlans(newAllPlans);
    };

    const handleCheckboxChange = (index, key, checked) => {
        const newAllPlans = [...allPlans];
        newAllPlans[index][key] = checked ? 1 : 0;
        setallPlans(newAllPlans);
    };


    const handleAddPlan = () => {


        setallPlans(
            [
                ...allPlans,
                {
                    name: '',
                    description: '',
                    autorenewal: 0,
                    multiplequantity: 0,
                    pricing: [
                        { type: 'Weekly', price: 0 },
                        { type: 'Monthly', price: 0 },
                        { type: 'Yearly', price: 0 },

                    ],
                },
            ]
        )

    };


    const handleRemovePlan = (index) => {
      if (allPlans.length > 1) {
        const newAllPlans = [...allPlans];
        newAllPlans.splice(index, 1);
        setallPlans(newAllPlans);
      }
    };


    const getSpaces = async () => {
        try {
            let payload = {
                businessId: business ? business?._id : null,
                assignSpace: profile ? profile?.assignedSpaces : [],
                isAllSpaces: profile ? profile?.isAllSpaces : null,
                businessId2: profile ? profile?.businessId : null,
            }

            const response = await dispatch(getSpacesForDropDownPrivate(payload)).unwrap();
            setgetAllSpaces(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const getMyBusiness = async () => {
        try {
            await dispatch(getByBusinessData()).unwrap();
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getSpaces();
            getMyBusiness()
        }
        return () => {
            mount = false;
        };
    }, [])




    useEffect(() => {
        let mount = true;
        if (mount) {

            getSpaces();
        }
        return () => {
            mount = false;
        };
    }, [business, profile])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          setload(true);
          if (!name) {
            toast.error('Name is required');
            setload(false);
            return;
          }
          if (!spaceId) {
            toast.error('Location is required');
            setload(false);
            return;
          }
          if (!images.length == 1) {
            toast.error('Images are required');
            setload(false);
            return;
          }

            const form = new FormData();
            form.append("name", name);
            form.append("description", description);
            form.append("businessId", profile?.businessId ? profile?.businessId : business._id);
            form.append("spaceId", spaceId);
            form.append("allPlans", JSON.stringify(allPlans));
            images.forEach((item) => {
                form.append("virtualOffice", item);
            });

            await dispatch(addVirtualOffice(form)).unwrap();
            setdescription("")
            setImages([])
            setspaceId("")
            setname("")
            setload(false);
            navigate("/virtualoffice")
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const handleImageUpload = (event) => {

        const selectedImages = event.target.files;
        const imagesArray = [];

        for (let i = 0; i < selectedImages.length; i++) {
            imagesArray.push(selectedImages[i]);
        }
        setImages(images.concat(imagesArray));
    };

    const handleImageRemovePrev = (e, index) => {
        e.preventDefault();
        const updatedImages = [...prev_images];
        updatedImages.splice(index, 1);
        setprev_images(updatedImages);
    };

    const handleImageRemove = (e, index) => {
        e.preventDefault();
        const updatedImages = [...images];
        updatedImages.splice(index, 1);

        setImages(updatedImages);
    };



    return (
        <>
        <div className="sp-add-location-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb sp-breadcrumb">
                  <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                  <li class="breadcrumb-item"><a>{t('spaces')}</a></li>
                  <li class="breadcrumb-item"><a onClick={() => navigate("/virtual-office")}>{t('virtualoffice')}</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{t('add')} {t('virtualoffice')}</li>
                </ol>
            </nav>
            <div className="d-flex align-items-center gap-2 mb-4">
            <img height="32" src={`${process.env.REACT_APP_FRONTURL}assets/images/spaces-icon-sp.svg`} className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('add')} {t('virtualoffice')}</h2>
          </div>
          <div className="mb-4">
            <div className="row gy-3 mb-4">
            <div className="col-md-6">
            <div class="form-floating sp-input mb-3">
              <input type="text" class="form-control" id="name" maxLength={40} placeholder="Name" value={name} onChange={(e) => setname(e.target.value)}/>
              <label for="name">{t('name')}*</label>
            </div>
            <div class="form-floating sp-textarea mb-3">
              <textarea class="form-control" placeholder="Leave a comment here" maxLength={300} id="Description" style={{height: '100px'}} value={description} onChange={(e) => setdescription(e.target.value)}></textarea>
              <label for="Description">{t('description')}</label>
            </div>
            </div>
            <div className="col-md-6">
            <div class="mb-3">
              <label for="inputEmail3" class="col-form-label align-self-center blue-color fw-semibold">{t('location')}</label>
              <div className="d-flex gap-3 flex-wrap">
                  {getAllSpaces?.map((data, i) => (
                    <>
                      <input class="btn-check" type="checkbox" value={spaceId} id={data?._id} key={i} checked={spaceId.includes(data._id)} onClick={() => setspaceId(data?._id)} />
                      <label class="btn sp-check-select" for={data?._id}>{data.name}</label>
                    </>
                    ))}
              </div>
              </div>

            </div>
            </div>
            <div className="upload__img-wrap mb-4">
                {prev_images?.map((image, index) => (
                  <div className="upload__img-box" key={index}>
                    <div className="img-bg">
                      <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt={`Store Image`} className="img-bg-size" />
                      <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                    </div>
                  </div>
                ))}

                {images?.map((image, index) => (
                  <div className="upload__img-box" key={index}>
                    <div className="img-bg">
                      <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                      <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                    </div>
                  </div>
                ))}
                <input type="file" class="btn-check" id="btn-check" multiple data-max_length="10" onChange={handleImageUpload} accept=".png, .jpg, .jpeg" />
                <label class="upload__btn" for="btn-check"><Camera strokeWidth={1} /> {t('addphotos')}</label>
              </div>
            <p className="font-size-24 titilium-font fw-bold blue-color mb-2">{t('add')} {t('plan')}</p>
            {allPlans?.map((plan, index) => (
                <div key={index}>
                <div className="d-flex justify-content-between">
                <p className="font-size-16 titilium-font fw-bold blue-color mb-2">{t('plan')} # {index + 1}</p>
                <p className="font-size-16 titilium-font fw-bold blue-color mb-2 cursor-pointer" onClick={()=> handleRemovePlan()}>{t('remove')}</p>
                </div>
                  <div className="row gy-3">
                    <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="text" class="form-control" id="name" maxLength={40}  placeholder="Name" value={plan.name} onChange={(e) => handleInputChange(index, 'name', e.target.value)}/>
                      <label for="name">{t('name')}*</label>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div class="form-floating sp-input">
                      <input type="number" class="form-control" id="Deposit"  placeholder="Deposit" value={plan.deposit} onChange={(e) => {
                        const inputValue = parseInt(e.target.value, 10);
                        if (!isNaN(inputValue) && inputValue >= 0) {
                          handleInputChange(index, 'deposit', inputValue);
                        }
                        else {
                          handleInputChange(index, 'deposit', null);
                        }
                      }} />
                      <label for="Deposit">{t('deposit')}</label>
                    </div>
                    </div>
                    <div className="col-md-12">
                    <div class="form-floating sp-textarea ">
              <textarea class="form-control" placeholder="Leave a comment here" maxLength={300} id="Description" style={{height: '100px'}} value={plan.description} onChange={(e) => handleInputChange(index, 'description', e.target.value)}></textarea>
              <label for="Description">{t('description')}</label>
            </div>
                    </div>
                    <div className="col-md-12 d-flex flex-wrap gap-3">
                      <input type="checkbox" class="btn-check" checked={plan.autorenewal === 1} onChange={(e) => handleCheckboxChange(index, 'autorenewal', e.target.checked)} id="Auto-Renewal"/>
                      <label className="btn sp-check-select" for="Auto-Renewal">{t('autoRenewal')}</label>

                      <input type="checkbox" class="btn-check" checked={plan.multiplequantity === 1} onChange={(e) => handleCheckboxChange(index, 'multiplequantity', e.target.checked)} id="Multiple-Quantity"/>
                      <label className="btn sp-check-select" for="Multiple-Quantity">{t('multipleQuantity')}</label>
                    </div>
                    {plan.pricing.map((pricingItem, pricingIndex) => (
                    <div key={pricingIndex} className="col-md-4">
                    <div class="form-floating sp-input mb-3">
                      <input type="number" class="form-control" id={pricingItem.type}  placeholder={pricingItem.type} value={pricingItem.price.toString()} onChange={(e) => {
                        const inputValue = parseInt(e.target.value, 10);
                        if (!isNaN(inputValue) && inputValue >= 0) {
                          handlePricingInputChange(index, pricingIndex, 'price', inputValue);
                        }
                        else {
                          handlePricingInputChange(index, pricingIndex, 'price', 0)
                        }
                      }}/>
                      <label for={pricingItem.type}>{pricingItem.type}</label>
                    </div>
                    </div>
                    ))}
                  </div>
                </div>
              ))}
              <button class="btn sp-button sp-blue-button py-2" onClick={handleAddPlan}>{t('addMore')}</button>
      

          </div>   

          <div className="text-center">
            <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>{t('save')}</button>
          </div>
        </div>
        </>
    )
}

export default AddVirtualOffice